import React from 'react'
import {InfoBoxTable} from '../../../charts/InfoBoxTable/InfoBoxTable'
import {Title} from './../../../Title';
import {TextBox} from '../../../TextBox';
import { useRouteMatch } from "react-router-dom";

export const Intro = ({textData}) => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
        <div className="panel-regular">
            <Title title='Key points' />
            <InfoBoxTable 
                numberOfActionsTitle='total number of recorded istances'
                numberOfActionsNumber='4,319'
                recordedSumTitle='total recorded value of donations'
                recordedSumNumber='50,984,095.5 €'
                averageDonationTitle='average donation per individual'
                averageDonationNumber='13 €'
                increaseGivingTitle='increased level of giving compared to 2019'
                increaseGivingNumber='2.7x'
            />
            <TextBox textData={textData} />
            <div id="area-of-giving"></div>
        </div>
        )
    } else {
    return (
        <div className="panel-regular">
            <Title title='Osnovni podaci' />
            <InfoBoxTable 
                numberOfActionsTitle='zabeleženih dobrotvornih akcija'
                numberOfActionsNumber='4.319'
                recordedSumTitle='zabeležena darovana suma'
                recordedSumNumber='50.984.095,5 €'
                averageDonationTitle='procenjena prosečna donacija po građaninu'
                averageDonationNumber='13 €'
                increaseGivingTitle='povećana davanja u odnosu na 2019.'
                increaseGivingNumber='2,7x'
            />
            <TextBox textData={textData} />
            <div id="area-of-giving"></div>
        </div>
    )
}}

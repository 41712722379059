import React from 'react'

export const menuLabels = {
    rs: [
      ['Ukupna', 'davanja'], 
      ['Davanja usmerena na', 'posledice pandemije'], 
      ['Davanja koja nisu', 'povezana sa pandemijom']
    ],
    en: [
      ['Total – primary' ,'overview'],
      ['Donations intended for tackling' ,'the effects of the pandemic'],
      ['Donations unrelated' ,'to the pandemic']
    ]
  }
import React from 'react';
import {AppTitle} from '../../AppTitle';
import {Intro} from './pannels/Intro';
import {useTextBoxData} from '../../useTextBoxData';
import {AreasOfGiving} from './pannels/AreasOfGiving';
import {Donors} from './pannels/Donors';
import {EndUsers} from './pannels/EndUsers';
import {Recipients} from './pannels/Recipients';
import {Examples} from './pannels/Examples';
import {InMedias} from './pannels/InMedias';
import {Footnote} from './pannels/Footnote';
import { Navbar } from './Navbar';
import { NavbarEN } from './NavbarEN';
import { useRouteMatch } from "react-router-dom";
import { Error404 } from '../Error404';

export const QuickFacts = () => {
    const { params } = useRouteMatch();
    //const textUrl = 'https://gist.githubusercontent.com/kragovic/9ada7933a199e0beb2e26292c7469601/raw/b4640beb5bdbf31b43083b1802de714387cdda93/quickFactsText.csv'
    const textUrl = 'https://gist.githubusercontent.com/kragovic/9ada7933a199e0beb2e26292c7469601/raw/54a00561c5357fbd5dd0901ad06aefc0a828659a/quickFactsText.csv';
    const textData = useTextBoxData(textUrl);

    const textUrlEn = 'https://gist.githubusercontent.com/kragovic/cd787c03982c36e4c889ccf13b2dae71/raw/25e72de752b1f131979be48422ec7012436d6d30/quickFactsTextEng.csv';
    const textDataEn = useTextBoxData(textUrlEn);

    if (!textData || !textDataEn ) {
        return <pre>Loading...</pre>;
      }
      if (params.lang == 'en') {
        return (
            <>
                <NavbarEN />
                <AppTitle appTitle={['Giving Serbia 2020', '/Report on the state of philantrophy', '/Quick facts']} />
                <Footnote textData={textDataEn[7]} />
                <Intro textData={textDataEn[0]} />
                <AreasOfGiving textData={textDataEn[1]} />
                <Donors textData={textDataEn[2]} />
                <Recipients textData={textDataEn[3]} />
                <EndUsers textData={textDataEn[4]} />
                <InMedias textData={textDataEn[5]} />
                <Examples textData={textDataEn[6]}  />
            </>
        )

      } else if (params.lang == 'rs') {
            return (
                <>
                <Navbar />
                <AppTitle appTitle={['Srbija daruje 2020', '/Izveštaj o dobročinstvu', '/Glavni nalazi']} />
                <Footnote textData={textData[7]} />
                <Intro textData={textData[0]} />
                <AreasOfGiving textData={textData[1]} />
                <Donors textData={textData[2]} />
                <Recipients textData={textData[3]} />
                <EndUsers textData={textData[4]} />
                <InMedias textData={textData[5]} />
                <Examples textData={textData[6]}  />
                </>
            )
        } else {
            return <Error404 />
        }

}

import React, { useState, useEffect } from 'react';
import { csv } from 'd3';

export const useData = (csvUrl) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const row = (d) => {
      d.TotalActions = +d.TotalActions;
      d.TotalActionsCovid = +d.TotalActionsCovid;
      d.TotalActionsOther = +d.TotalActionsOther;
      d.CovidOnlyActions = +d.CovidOnlyActions;
      d.OtherOnlyActions = +d.OtherOnlyActions;
      d.Zero= 0;
      d.Hundred= 100;
      return d;
    };
    csv(csvUrl, row).then((data) =>
      setData(data)
    );
  }, []);
  return data;
};
import React from 'react'
import { HashLink, NavHashLink } from 'react-router-hash-link';

export const ContentMenu = ({SidebarData}) => {
    return (
        <div className="content-submenu">
            {
                SidebarData.map((item, index) => (
                    <li key={index} className={item.cName}>
                    <HashLink to={item.path}>
                        <span className="content-menu-title">{item.title}</span>
                    </HashLink>    
                </li>
                ))
            }
        </div>
    )}

 

    

       

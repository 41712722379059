import React from 'react';
import {Title} from './../../../Title';
import {PieWithTable} from '../../../charts/PieWithTable/PieWithTable'
import {TextBox} from '../../../TextBox';
import { useRouteMatch } from "react-router-dom";



export const AreasOfGiving = ({textData}) => {
    const { params } = useRouteMatch();
    const csvUrl2 = 'https://gist.githubusercontent.com/kragovic/8561ae65c561f0fd83cb24df65f93c0a/raw/8ccb20d384c59b2b8d5cb7ca2ef9dab8ae4d965c/Key_themes.csv';
    const csvUrl2ENG = 'https://gist.githubusercontent.com/kragovic/dd47ae9517da5a2d388fdc941160b6aa/raw/09eb9db06796dbb9569affeb88f56bc7eec08b01/Key_themes_eng.csv';
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <Title title='Giving by theme' />
                <PieWithTable csvUrl={csvUrl2ENG} lang={params.lang} />
                <TextBox textData={textData} />
                <div id="donors"></div>
            </div>
        )
    } else {
    return (
        <div className="panel-regular">
            <Title title='Oblasti davanja' />
            <PieWithTable csvUrl={csvUrl2} lang={params.lang} />
            <TextBox textData={textData} />
            <div id="donors"></div>
        </div>
    )
}}

import React from 'react';
import {Title} from './../../../Title';
import {TextBox} from '../../../TextBox';
import { useRouteMatch } from "react-router-dom";

export const Examples = ({textData}) => {
    const { params } = useRouteMatch();
    console.log(params.lang)
    if (params.lang == 'en') { 
        return (
            <div className="panel-regular">
                <Title title='Examples of philanthropy'></Title>
                <TextBox textData={textData} />
            </div>
        )      
    } else {
    return (
        <div className="panel-regular">
            <Title title='Primeri dobročinstva' />
            {/*<TextBox textData={textData} />*/}
<p>Kriza koja je 2020. godine zadesila čitav svet nije uticala samo na to za šta se donira, već je promenila i način doniranja i društvenog angažovanja za mnoge kompanije i građane. Borba protiv Kovida-19 je svakako bila glavna oblast darivanja prošle godine, ali smo videli i brojne inovativne primere davanja za druge oblasti.</p>

<p>Najveće akcije prikupljanja sredstava za borbu protiv posledica pandemije u protekloj godini organizovali su <strong>Unicef</strong> sa jedne, i <strong>Srpski filantropski forum</strong> i <strong>Fond B92</strong> sa druge strane. Ove tri neprofitne organizacije su praktično koordinisale odgovor dobročinstva na Kovid-19 i imale ključnu ulogu u pravovremenom informisanju darodavaca o potrebama i tokovima donacija, čime su obezbedile veliku podršku kompanija zdravstvenom sistemu Srbije.</p>

<p>Tokom ove akcije <strong>Srpski filantropski forum</strong> je, u saradnji sa <strong>Fondom B92</strong>, prikupio više od 1,6 miliona evra u novcu i preko 85 hiljada evra u robi. U saradnji sa <strong>Unicefom</strong> i kompanijom <strong>Nordeus</strong> kreirali su i svakodnevno ažurirali tabelu potreba i vremenskih rokova isporuke. Podaci su redovno proveravani sa Ministarstvom zdravlja kako bi se pomoć raspodelila na najefikasniji način. Ovakav način pravovremenog informisanja i transparentnog rada nije bio značajan samo za efikasnost procesa, već je podsticao i uključivanje novih donatora, kao i veća davanja.</p>

<p>Pored ove akcije, tri najveće direktne donacije u borbi protiv virusa korona, koje su pojedinačno iznosile po više od milion dolara, dali su <strong>HP-Petrohemija Pančevo</strong>, <strong>Fondacija Novak Đoković</strong> i <strong>United grupa</strong>.</p>

<p>Uz njih, značajan doprinos je dala i <strong>MK grupa</strong>. Ukupna davanja ove kompanije u 2020. godine prelaze milion evra, od čega je najveći deo povezan sa posledicama pandemije, i to prvenstveno sa nabavkom neophodne medicinske opreme za 12 zdravstvenih institucija. Takođe, MK grupa je obezbedila let privatnim avionom za lečenje bolesne dece u SAD, kao i odmor za 450 medicinskih radnika u hotelima u svom vlasništvu u Srbiji, Sloveniji i Hrvatskoj. Kompanija je takođe podelila humanitarne prehrambene pakete socijalno ugroženim građanima u vrednosti od 150.000 evra. MK grupa je zbog navedenih akcija nominovana za jednu od nagrada VIRTUS za filantropiju.</p>

<p>U delu brige o marginalizovanim grupama, a opet u vezi sa pandemijom Kovida-19, kompanija <strong>IKEA Srbija</strong> je tokom 2020. godine realizovala kampanju protiv nasilja u porodici „Siguran dom je bolji dom“. U saradnji sa <strong>Fondacijom Ana i Vlade Divac</strong>, IKEA je uputila novčanu donaciju u više od 700 porodica i za 1.500 pojedinaca i pojedinki. Povodom 16 dana aktivizma protiv rodno zasnovanog nasilja, u robnoj kući ovog prodajnog lanca nameštaja istaknuti su brojevi SOS telefona namenjeni žrtvama nasilja. Kompanija IKEA je nominovana za jednu od nagrada VIRTUS za filantropiju.</p>

<p>Rekordan iznos zabeležili smo u akciji usmerenoj na ekonomski razvoj. Kompanija <strong>Philip Moris International</strong> dala je <strong>NALED</strong>-u krajem 2020. godine preko 4 miliona evra za tehnološki razvoj u Srbiji. Radi se, zapravo, o početku trogodišnjeg projekta „StarTech“, namenjenog digitalnoj transformaciji malih i srednjih preduzeća. U projektu su planirana tri javna poziva i dodela do 100 grantova u vrednosti od 15.000 do 100.000 dolara, kao i vaučera za 1.000 sati ekspertske podrške za ulaganje u digitalnu transformaciju svog biznisa, istraživanje i uvođenje inovativnih proizvoda i usluga. Ovo je prvi put da se ovako velika darovana suma usmeri na ekonomski razvoj.</p>

<p>Pored direktnih donacija kompanija, u novim okolnostima postalo je važno animirati kupce i angažovati zaposlene u strategijama korporativne filantropije.</p>

<p>Tako je domaća platforma za onlajn naručivanje i dostavu hrane <strong>Donesi (Plotun doo)</strong> sprovela akciju „Donesi još jedan vrtić u Srbiju“ u partnerstvu sa <strong>Fondacijom Novak Đoković</strong>. „Donesi“ je svojim korisnicima omogućio da karticom doniraju novac za izgradnju vrtića u selu Vajska kod Bača, direktno preko njihove aplikacije. Zahvaljujući ovoj akciji, 80 mališana će dobiti svoj vrtić sa celodnevnim boravkom, opremljen modernim nameštajem, didaktičkim materijalom i igračkama.</p>

<p>Drugi domaći brend <strong>Extreme Intimo (Jasmil doo)</strong> pokrenuo je akciju #StvarnoŽelim i tako angažovao, kako svoje kupce, tako i pratioce na društvenim mrežama, da podrže porodilišta širom Srbije. Svaki put kada bi neko na Instagramu podelio video ove kompanije, Extreme Intimo bi izdvojio 1 evro za porodilišta. Tako je za samo 14 časova video imao preko 27 hiljada podela, zbog čega je kompanija donirala 27.100 evra. Pored toga, Extreme Intimo je donirao i 2% od vrednosti prodaje svoje novogodišnje kolekcije u periodu od 24. novembra do 31. decembra. Na kraju je kompanija pozvala svoje kupce da glasanjem odluče koja porodilišta će primiti donaciju.</p>

<p>Nužnost rada od kuće stvorila je potrebu za većom komunikacijom i angažovanošću zaposlenih, što je značilo i nove mogućnosti za uključivanje zaposlenih u društveno odgovorne projekte poslodavaca.</p>

<p>Kompanija <strong>Quantox Technology</strong> iz Čačka je tokom vanrednog stanja organizovala humanitarni gejming turnir za zaposlene u IT industriji. Sa 70 prijavljenih timova prikupljeno je ukupno 27.580 evra, od čega je 13.790 evra namenjeno Kliničkom centru Niš, 8.270 Republičkom fondu za zdravstveno osiguranje, a 5.520 za nabavku medicinske i zaštitne opreme za zdravstvene ustanove u Srbiji, u okviru <strong>Unicefove</strong> inicijative. Nakon toga, Quantox je osnovao i svoju korporativnu fondaciju.</p>

<p>Dok su kompanije donirale prvenstveno državi za borbu protiv korona virusa, građani su, mahom putem SMS-a, donirali za lečenje dece, i to prvenstveno preko <strong>Humanitarne fondacije Budi human</strong>, koja je za ovu svrhu u 2020. godini prikupila 13,8 miliona evra. Ovo ujedno predstavlja 75 procenata svih donacija koje su otišle u neprofitni sektor, ne računajući donacije za Kovid-19.</p>

<p>Pored SMS-a, kao jedan od popularnijih vidova doniranja među građanima pokazale su se i licitacije putem fejsbuk grupe „Budi human – humanitarne licitacije“. Grupu od preko 300.000 članova osnovala je <strong>Branka Zarić</strong> kako bi omogućila građanima da stvari koje im više nisu potrebne stave na licitacije, pri čemu se sva sredstva usmeravaju za lečenje dece korisnika fondacija <strong>Budi human</strong>, <strong>Podrži život</strong> i <strong>Pokreni život</strong>. Branka je nominovana za jednu od nagrada VIRTUS.</p>

<p>Ipak, iako u manjoj meri, građani su donirali i za otklanjanje štetnih posledica virusa korona. Odmah nakon proglašenja epidemije u Srbiji na platformi Donacije.rs je pokrenuta posebna stranica za davanje usmereno na posledice pandemije. Tokom godine je na ovoj stranici kroz 24 kampanje prikupljeno više od 137.000 evra. Tako su, na primer, u prvim danima prikupljena sredstva za izradu maski za medicinsko osoblje na jugu Srbije, a nabavljeno je i preko 200 tablet računara za socijalno ugrožene učenike, kako bi im se omogućilo da neometano prate nastavu od kuće.</p>

<p>Upečatljiv primer građana koji su pomogli državi tokom krize izazvane Kovidom-19 jesu <strong>Selma Hasić Manić</strong>, modna dizajnerka iz Novog Pazara, i njena koleginica <strong>Nerma Zekić Hoćanin</strong>, koje su pokrenule urgentnu kampanju da bi pomogle Opštoj bolnici. Za nekoliko dana su, uz pomoć građana i dijaspore, prikupile oko 70.000 evra, od kojih je 45.000 evra usmereno na neophodnu opremu u bolnici, dok su ostala sredstva usmerena ka drugim zdravstvenim ustanovama u Sandžaku. Tokom godine su prikupile i donirale medicinsku i zaštitnu opremu za zdravstvene ustanove i apoteke u Sandžaku vrednu 100.000 evra. Nominovane su za jednu od nagrada VIRTUS za filantropiju.</p>

<p><strong>Aleksandar Alek Kavčić</strong>, pronalazač i profesor na Univerzitetu Karnegi Melon u SAD, nedavno je dobio deo odštete od 750 miliona dolara zato što je njegov patent – čitač magnetskih memorija – nezakonito koristila jedna kompanija. Početkom 2020. godine on je osnovao <strong>Fondaciju Alek Kavčić</strong> i uložio oko milion evra za kupovinu godišnjeg tiraža udžbenika za osnovnu školu, koje je objavio na sajtu, omogućivši besplatno preuzimanje. Tokom 2020. godine Kavčić je Matematičkoj gimnaziji dodatno poklonio 20 računara najnovije generacije, a 20 računara koji su poklonjeni tokom 2019. godine i koji su stari svega godinu dana redistribuirani su u pet osnovnih škola u Srbiji. Alek Kavčić je zbog navedenih dela nominovan za jednu od nagrada VIRTUS za filantropiju.</p>

<p>Ovo su bili samo neki od najvažnijih primera dobročinstva iz 2020. godine. Građani Srbije i naše dijaspore, kao i društveno odgovorne kompanije, učinili su zajedno još mnogo dobrih dela tokom prethodne, krizne godini. Za više informacija i primera pročitajte celokupan izveštaj „Srbija daruje“ na http://izvestaj.srbijadaruje.org/.</p>

        </div>
    )
}}

import React, {
    useState,
    useCallback,
    useEffect,
  } from 'react';
  import ReactDOM from 'react-dom';
  import {
    csv,
    scaleLinear,
    scaleTime,
    max,
    timeFormat,
    extent,
  } from 'd3';
  import { useData } from './useData';
  import { AxisBottom } from './AxisBottom';
  import { Marks } from './Marks';
  import { PlotMenu } from './PlotMenu';
  import { ColorLegend } from './ColorLegend';
  
  import { menuLabels } from '../menuLabels';
  import { menuItemsWidth } from '../menuItemsWidth';

  const width = 960;
  const height = 500;
  const margin = {
    top: 20,
    right: 40,
    bottom: 160,
    left: 55,
  };
  const xAxisLabelOffset = 50;
  const yAxisLabelOffset = 40;
  //const menuLabels = ['Ukupno', 'COVID-19', 'Ostale svrhe'];
 /*  const menuLabels = [
    ['Ukupna', 'davanja'], 
    ['Davanja usmerena na', 'posledice pandemije'], 
    ['Davanja koja nisu', 'povezana sa pandemijom']
  ]; */
  const gID = ['Total', 'Covid', 'Other']; // xValue, xFirstRectValue, xSecondRectValue
  
  export const AreaChart = ({csvUrl, lang, strippedClass, patternStripe, maskStripe}) => {
    const data = useData(csvUrl);
  
    const initialYAttribute = 'Total';
    const [yAttribute, setYAttribute] = useState(
      initialYAttribute
    );
    const yValue = (d) => d[yAttribute];
  
    const initialY2Attribute = 'CovidTotal';
    const [y2Attribute, setY2Attribute] = useState(
      initialY2Attribute
    );
    const y2Value = (d) => d[y2Attribute];
  
    if (!data) {
      return <pre>Loading...</pre>;
    }
  
    const innerWidth = width - margin.right - margin.left;
    const innerHeight = height - margin.top - margin.bottom;
  
    const xValue = (d) => d.Month;
    const xAxisLabel = 'Time';
  
    const yAxisLabel = 'Donations';
  
    //const xAxisTickFormat = timeFormat('%b');
    const xAxisTickFormat = (num, lang) => {
      if (lang == 'SR') {
          return timeFormat('%b')(num)
          .replace('Aug', 'Avg')
          .replace('May', 'Maj')
            .replace('Oct', 'Okt')
      } else {
          return timeFormat('%b')(num)
      }
    };
    
    const xScale = scaleTime()
      .domain(extent(data, xValue))
      .range([0, innerWidth])
      .nice();
  
    const fixedXScaleYValue = (d) => d.Total;
    const yScale = scaleLinear()
      .domain([0, max(data, fixedXScaleYValue)])
      .range([0, innerHeight].reverse())
      .nice();
  
    const y2Scale = scaleLinear()
      .domain(extent(data, yValue))
      .range([0, innerHeight].reverse())
      .nice();
  
    return (
      <svg viewBox="0 0 960 542">
        <g
          transform={`translate(${14}, ${margin.top})`}
        >
          <AxisBottom
            xScale={xScale}
            innerHeight={innerHeight}
            tickFormat={xAxisTickFormat}
            tickOffset={5}
          />
          <Marks
            xScale={xScale}
            yAttribute={yAttribute}
            yScale={yScale}
            y2Scale={y2Scale}
            data={data}
            xValue={xValue}
            yValue={yValue}
            y2Value={y2Value}
            y2Attribute={y2Attribute}
            tooltipFormat={xAxisTickFormat}
            innerHeight={innerHeight}
            strippedClass={strippedClass} 
            patternStripe={patternStripe}
            maskStripe={maskStripe}
          />
          <g
            textAnchor="middle"
            transform={`translate(${182} ${
              height - 50
            })`}
          >
            <PlotMenu
              updateYValue={setYAttribute}
              lang={lang}
              updateY2Value={setY2Attribute}
              menuLabels={menuLabels}
              menuItemsWidth={menuItemsWidth}
              yAttribute={yAttribute}
              menuTextClass={gID}
            />
          </g>
          <g className="color-legend" transform={`translate(${innerWidth / 2 - 100}, ${height - 135})`}>
            <ColorLegend 
              yAttribute={yAttribute}
              lang={lang}
            />
          </g>
        </g>
      </svg>
    );
  };
import React from 'react'
import {Title} from './../../../Title';
import {TextBox} from '../../../TextBox';
import { useRouteMatch } from "react-router-dom";

export const InMedias = ({textData}) => {
    const { params } = useRouteMatch();
    let panelTitle = params.lang == 'en' ? 'Philanthropy in the media' : 'Dobročinstvo u medijima';
    return (
        <div className="panel-regular">
            <Title title={panelTitle} />
            <TextBox textData={textData} />
            <div id="examples"></div>
        </div>
    )
}

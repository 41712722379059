import React from 'react'
import {Accordion} from '../../../Accordion';
import {SimplerJIBarWithTable} from '../../../charts/SimplerJIBarWithTable/SimplerJIBarWithTable';
import { useRouteMatch } from "react-router-dom";

export const EndUsers = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">6</span>Who are the final beneficiaries of donations?</h2>
                <p>In the pandemic year, the four key groups of beneficiaries in terms of percentage of instances remained the same: local communities, people with disabilities, people with health issues, and people at risk of poverty.</p>
                <p>This was also the first time that the elderly is found among the top five beneficiaries, with a 6.5% share in the total number of instances. This represents a huge leap compared to 2019 when the elderly received only a 1.8% share.</p>
                <p>The second important phenomenon, already seen in the analysis of individual donations through mass giving, is that people with disabilities were particularly represented in instances related to the pandemic.</p>
                <h3 className="panel-subtitle">Final beneficiaries</h3>
                <SimplerJIBarWithTable 
          //csvUrl='https://gist.githubusercontent.com/kragovic/4f95c6e28ced8a5054b7bfe5fbe804df/raw/533a443703213c7342255979a5a0b01837a20c09/endUsers.csv' 
          //const csvUrl = 'https://gist.githubusercontent.com/kragovic/4f95c6e28ced8a5054b7bfe5fbe804df/raw/fa6754cf405961b12ddcf376863e8668782fb991/endUsers.csv'
          const csvUrl='https://gist.githubusercontent.com/kragovic/12b4b88954a6929528fbd1a6c1b65917/raw/305545485c4c2aa1423baac474f16c34c90a1657/endUsersENG.csv'
          lang={params.lang}
          strippedRectClass='stripped-bar-w-table'
          patternStripe='pattern-stripe-bar-w-table'
          maskStripe='mask-stripe-bar-w-table'
          />
                <p>While in terms of instances of giving donors principally focused on addressing the effects of the pandemic, we find it important to draw attention to the contributions many local communities received for activities unrelated to the pandemic.</p>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/635f6a45c3d0fe202f89ae1536b072f1/raw/d73473ceee41d656f26b0dbb0287ad4d0fa8fddd/endUsersExaplesENG.csv'
                />    
                <div id="use"></div>        
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">6</span>Ko su krajnji korisnici donacija?</h2>
                <p>I u godini pandemije četiri ključne grupe korisnika kojima je namenjen najveći procenat akcija darivanja su: populacija iz lokalnih zajednica, osobe sa invaliditetom, osobe sa zdravstvenim problemima i osobe u riziku od siromaštva.</p>
                <p>Po prvi put među pet najzastupljenijih krajnjih korisnika beležimo starije osobe, sa učešćem od 6,5% ukupnih akcija davanja, što je značajan skok u poređenju sa 2019. godinom, kada je na njih bilo usmereno 1,8% akcija. U ukupnoj raspodeli akcija usmerenih na posledice pandemije, čak 9,2% je bilo posvećeno starijoj populaciji.</p>
                <p>Drugi važan fenomen, koji smo već videli u analizi davanja građana kroz masovne akcije, jeste to da su osobe sa invaliditetom bile naročito zastupljene u akcijama davanja koje nisu usmerene na posledice pandemije.</p>
                <h3 className="panel-subtitle">Krajnji korisnici</h3>
                <SimplerJIBarWithTable 
          //csvUrl='https://gist.githubusercontent.com/kragovic/4f95c6e28ced8a5054b7bfe5fbe804df/raw/533a443703213c7342255979a5a0b01837a20c09/endUsers.csv' 
          //const csvUrl = 'https://gist.githubusercontent.com/kragovic/4f95c6e28ced8a5054b7bfe5fbe804df/raw/fa6754cf405961b12ddcf376863e8668782fb991/endUsers.csv'
          const csvUrl='https://gist.githubusercontent.com/kragovic/4f95c6e28ced8a5054b7bfe5fbe804df/raw/08543d9f9a735327b52909aba082b9f51d082a07/endUsers.csv'
          lang={params.lang}
          strippedRectClass='stripped-bar-w-table'
          patternStripe='pattern-stripe-bar-w-table'
          maskStripe='mask-stripe-bar-w-table'
          />
                <p>Iako su pažnju javnosti uglavnom okupirale akcije davanja za posledice pandemije, važno nam je da istaknemo doprinose date lokalnim zajednicama uprkos pandemiji.</p>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/c6451c96b9f30d702ba6f2dd4c8d86d0/raw/18c53444de5cfd9c677b5978e6f65b6b6deeeacf/endUsersExaples.csv'
                />    
                <div id="use"></div>        
            </div>
        )
    } 
}

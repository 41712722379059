import * as React from 'react';
import { pie, arc } from 'd3';

export const Slice = ({
  pie,
  outerRadius,
  innerRadius,
  colorScale
}) => {
  let arcCustom = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

  return pie.map((slice, index) => {
    let sliceColor = colorScale(slice.theme);
    return (
      <g key={index}>
        <path d={arcCustom(slice)} fill={sliceColor}  />
      </g>
    );
  });
};
import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import {Hexagon} from "./Hexagon";

const passCountry = country => {
    let langObj = {};
    switch(country) {
        case 'Serbia':
            langObj.countryName = 'Serbian';
            langObj.lang = 'rs';
            break;
        case 'Bosnia':
            langObj.countryName = 'Bosnian';
            langObj.lang = 'bs';
            break;
        case 'Croatia':
            langObj.countryName = 'Croatian';
            langObj.lang = 'cro';
            break;
        case 'Albania':
            langObj.countryName = 'Albanian';
            langObj.lang = 'al';
            break;
        case 'Kosovo':
            langObj.countryName = 'Albanian';
            langObj.lang = 'al';
            break;
        case 'Montenegro':
            langObj.countryName = 'Montenegrian';
            langObj.lang = 'mn';
            break;
        case 'NorthMacedonia':
            langObj.countryName = 'Macedonian';
            langObj.lang = 'mk';
            break;
        default:
            langObj.countryName = 'Serbian';
            langObj.lang = 'rs';
    }

    return langObj;
}

export const Lang = () => {
    const { url, path, params } = useRouteMatch();
    let langInfo = passCountry(params.name)
    return (
        <div>
            <div className="lang-container">
                <h1>Annual Report on the</h1>
                <h1>State of Philanthropy</h1>
                <h1 className={`${"hex" + params.name}`}>/{params.name}</h1>
                <div className="lang-hex-container">
                    <Hexagon hexText={[`${langInfo.countryName}`]} hexClass={`${"hex" + params.name}`} pageUrl={`${url}/${langInfo.lang}`} />
                    {/* <Hexagon hexText={["English"]} hexClass="hexEnglish" pageUrl={`${url}/en`} /> */}
                    <Hexagon hexText={["English"]} hexClass="hexEnglish" pageUrl={`${url}/en`} />
                </div>
            </div>
            {/* <h1>Language</h1>
            <nav className="navbar navbar-light">
                <ul className="nav navbar-nav">
                    <li>
                        <Link to={`${url}/${langInfo.lang}`}>{`${langInfo.countryName}`}</Link>
                    </li>
                    <li>
                        <Link to={`${url}/en`}>English</Link>
                    </li>
                </ul>
            </nav> */}
        </div>
    )
}
import React from 'react'
import {InfoBoxTable} from '../../../charts/InfoBoxTable/InfoBoxTable'
import {PieChart} from '../../../charts/PieChart/PieChart'
import { useRouteMatch } from "react-router-dom";

export const Summary = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular" id="summary-panel">
                <h2 className="panel-title">Summary – philanthropy in 2020</h2>
                <p>The COVID-19 pandemic of 2020 had a significant impact on philanthropy in Serbia in multiple respects. The most notable was that there were 2.7 times more donations than in the previous year. Additionally, donations unrelated to the COVID-19 pandemic, which we were concerned might be neglected given the circumstances, exceeded the total value of donations in 2019 by over EUR 10 million.</p>
                <p>We recorded a total of EUR 50,984,095 in donations and 4,319 instances of giving. Consequently, the average donation per individual grew from EUR 4.9 in 2019 to EUR 13 in 2020.</p>
                <p>By extrapolation, we estimate that slightly over EUR 90 million was donated in 2020, though this cannot be seen as an exact sum.</p>
                <p>The total value of donations to address effects of the pandemic considerably exceeded donations for any other individual theme, comprising 42.1% of the total recorded value of donations in 2020.</p>
                <p>The pandemic also brought a change in terms of donors. After several years in which individuals were more active donors than the corporate sector, the situation changed in the 2020 pandemic year, with the corporate sector providing <u>slightly more than half of the total sum donated and 38.1% of instances of giving</u>. Individuals, who for several years had been the most active and most generous donors, were in second place in 2020, providing extensive support unrelated to the pandemic.</p>
                <p>As expected, the largest number of donations related to the pandemic was directed to healthcare. When we combine donations to address effects of the pandemic with additional donations to healthcare, we estimate that at least 50% of total donations in Serbia were directed to health.</p>
                <p>The year was also marked by significantly larger donations to state authorities. The increased powers of the state during the State of Emergency, declared for the purpose of implementing epidemiological measures, resulted in 43.4% of the total sum being directed to the state (local and national governments and their institutions). Given the fact that many non-profit organizations directed what they received to the state and its institutions, this percentage is probably even higher.</p>
                <p>Many donations were made to the non-profit sector, but closer examination of the available data shows that only a few large organizations received significant amounts, while the majority of non-profit organizations were supported to a lesser extent than in previous years.</p>
                <p>The pandemic helped certain beneficiary groups that are not often supported to gain greater visibility and importance. The elderly population was given particular attention as a high-risk group and received considerable donor support for the first time in 2020.</p>
                <p>The pandemic brought about two additional shifts. For the first time since we began monitoring philanthropy, we recorded 691 instances (of which 606 directly related to COVID-19) in a single month (April). The second shift was the centralization of philanthropy. More than 40% of the total instances of giving were provided to the City of Belgrade. Change in giving patterns was particularly visible in Vojvodina, which experienced a decrease from 21.6% of the total share of donations in 2019 to approximately 15% in 2020 (specifically,15.9% given for purposes unrelated to the pandemic and 13.4% to address effects of the pandemic). Closer insight into the available data indicates that one of the reasons for this drop was a decreased level of activity on the part of non-profit organizations in the region.</p>
                <p>In 2020, there were also 1.5 times more philanthropic instances reported than in 2019 (an increase from 8,807 to 13,109).</p>
                <h3 className="panel-subtitle">Total giving</h3>
                <InfoBoxTable 
                    numberOfActionsTitle='Estimated value of donations'
                    numberOfActionsNumber='90,295,369 €'
                    recordedSumTitle='Recorded value of donations'
                    recordedSumNumber='50,984,095 €'
                    averageDonationTitle='Average donation per individual'
                    averageDonationNumber='13 €'
                    increaseGivingTitle='Number of instances'
                    increaseGivingNumber='4,319'
                />
                <h3 className="panel-subtitle">Recorded value of donations</h3>
                <PieChart
                    summary="amount" 
                    csvUrl='https://gist.githubusercontent.com/kragovic/5932069a87e56374df6e499bec8b4e75/raw/325ffaeb285baa7dc68ee29e07ce178e1cfc0cda/donatedAmoutENG.csv' 
                />
                <h3 className="panel-subtitle">Number of instances</h3>
                <PieChart
                    summary="actions" 
                    csvUrl='https://gist.githubusercontent.com/kragovic/bd3f48eaae03376879e729f43e6997b9/raw/849580540ed48cf0b29999edf0d2744a4a629bd7/totalActionSrbENG.csv' 
                />
                <div id="philanthropy2020"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular" id="summary-panel">
                <h2 className="panel-title">Rezime – dobročinstvo u 2020. godini</h2>
                <p>Ovu godinu je obeležila pandemija Kovida-19 i u dosta aspekata znatno uticala na dobročinstvo u Srbiji. Pre svega, darivano je mnogo više – 2,7 puta više nego u prethodnoj godini. Posebno je važno da istaknemo da čak i davanja koja nisu povezana sa pandemijom Kovida-19, za koja smo brinuli da će biti zapostavljena, premašuju za više od 10 miliona evra ukupna davanja u 2019. godini.</p>
                <p>Zabeležili smo ukupno 50.984.095,5 evra donacija, prikupljenih kroz 4.319 akcija. Zato je prosečna donacija po pojedincu sa 4,9 evra u 2019. godini porasla na 13 evra u ovoj godini.</p>
                <p>Koristeći ekstrapolaciju procenili smo da je u 2020. godini zapravo darovano nešto preko 90 miliona evra, mada svakako treba imati u vidu da je to samo procena, a ne tačna suma.</p>
                <p>Ukupna novčana vrednost filantropskih akcija za posledice pandemije višestruko nadilazi davanja za svaku drugu pojedinačnu temu i čini 42,1% ukupne zabeležene darovane sume u 2020. godini.</p>
                <p>Pandemija je uslovila i promenjenu sliku darodavaca. Nakon nekoliko godina u kojima su davanja građana bila veća od davanja poslovnog sektora, ove godine je on kroz 38,1% akcija darovao nešto više od polovine ukupne zabeležene darovane sume. Građani, koji su nekoliko godina bili najaktivniji i najveći darodavci, ove su godine na drugom mestu, a pružali su podršku onima kojima je ona bila potrebna nevezano za pandemiju.</p>
                <p>Kao što je i očekivano, najveći broj davanja vezanih za pandemiju bio je usmeren na zdravstvenu zaštitu. Kombinujući davanje za posledice pandemije sa specifikovanim, odvojenim davanjem za zdravstvo, možemo da procenimo da je najmanje 50% ukupnog darivanja u Srbiji bilo usmereno na zdravlje.</p>
                <p>Godinu su obeležile i znatno veće donacije državnim organima. Povećane nadležnosti u vanrednom stanju čiji je cilj bio sprovođenje epidemioloških mera uzrokovale su da čak 43,4% ukupne zabeležene sume bude upućeno državi (lokalnim i nacionalnim vlastima i institucijama). Imajući u vidu da je pomoć državi bila upućivana i kroz neprofitne organizacije kao posrednike, ovaj procenat je verovatno i veći.</p>
                <p>Znatna količina novca i akcija bila je usmerena i na neprofitni sektor, ali nam dublji uvid u podatke pokazuje da je nekoliko velikih organizacija primilo najveću podršku, dok je veći deo sektora bio podržan u manjoj meri nego prethodnih godina.</p>
                <p>Pandemija je pomogla i da neke korisničke grupe, koje inače nisu često podržavane, dobiju na vidljivosti i važnosti, te je starija populacija, koja je bila u centru pažnje kao visokorizična grupa, po prvi put dobila značajniju podršku.</p>
                <p>Još dve promene su uzrokovane pandemijom. Po prvi put otkada pratimo davanja zabeležili smo 691 akciju (od toga 606 direktno povezanih sa bolešću Kovid-19) u jednom mesecu, i to u aprilu. Druga promena je dodatna centralizacija dobročinstva. Više od 40% ukupnih akcija davanja bilo je usmereno na Grad Beograd. Posebno je izražen pad davanja u Vojvodini sa 21,6% u 2019. godini na svega 13,4% za posledice pandemije i 15,9% za davanja koja nisu usmerena na pandemiju. Dublji uvid u podatke ukazuje na to da je jedan od razloga bila niža aktivnost neprofitnih organizacija.</p>
                <p>Sve ove akcije dobročinstva propratilo je skoro 1,5 puta više medijskih objava o akcijama davanja nego u 2019. godini (sa 8.807 na 13.109).</p>
                <h3 className="panel-subtitle">Ukupno davanje</h3>
                <InfoBoxTable 
                    numberOfActionsTitle='procenjena darovana suma'
                    numberOfActionsNumber='90.295.369,9 €'
                    recordedSumTitle='zabeležena darovana suma'
                    recordedSumNumber='50.984.095,5 €'
                    averageDonationTitle='procenjena prosečna donacija po građaninu'
                    averageDonationNumber='13 €'
                    increaseGivingTitle='zabeleženih dobrotvornih akcija'
                    increaseGivingNumber='4.319'
                />
                <h3 className="panel-subtitle">Zabeležena darovana suma</h3>
                <PieChart
                    summary="amount" 
                    csvUrl='https://gist.githubusercontent.com/kragovic/fbdce3512bd29b943f189bc5f4216c56/raw/6a86b2e3752a23c34c4ea2ddd107085df5584f54/donatedAmout.csv' 
                />
                <h3 className="panel-subtitle">Broj akcija</h3>
                <PieChart
                    summary="actions" 
                    csvUrl='https://gist.githubusercontent.com/kragovic/f2bef35f04dbe647d49d6c1b9380be41/raw/84291d1599db9d7fbbd0656418360612e8ea6424/totalActionSrb.csv' 
                />
                <div id="philanthropy2020"></div>
            </div>
        )
    }
}

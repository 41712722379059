import React from 'react';
import {PieChart} from '../../../charts/PieChart/PieChart';
import { useRouteMatch } from "react-router-dom";

export const Media = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">8</span>Media coverage</h2>
                <p>In this information and media-driven age, reports on donations play a decisive role in the development of philanthropic culture. It is thus particularly important that in 2020 there were almost 1.5 times more (from 8,807 to 13,109) media reports on instances of giving than in 2019.</p>
                <p>National media reported by far the most instances, with a 5.8% increase in its share of coverage compared to 2019.</p>
                <p>Online media were the most represented media type, publishing 75.1% of the total reports, while the remaining reports were found in the print and electronic media.</p>
                
                <h3 className="panel-subtitle">Coverage</h3>
                <PieChart 
                    csvUrl='https://gist.githubusercontent.com/kragovic/250c8fc581cc8d39bc463de69ed0d955/raw/71bb0dbc563c571940c4d5ee89e3ca536887bc28/mediaCoverageENG.csv' 
                />
                
                <h3 className="panel-subtitle">Type of media</h3>
                <PieChart 
                    csvUrl='https://gist.githubusercontent.com/kragovic/ec958a855b79337eaa028e4ecb483cec/raw/b1277d50900fd152d691a5cbdf7d13f314081c4a/mediaTypeENG.csv' 
                />
                <p>It is important to add that 13% of the instances reported in the print media appeared on the first pages and that approximately 10% of electronic media reports were broadcast during prime time.</p>
                <div id="end"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">8</span>Medijska pokrivenost</h2>
                <p>U vremenu informacione, odnosno medijske dominacije, objave o davanjima imaju presudnu ulogu u razvoju kulture dobročinstva. Zato je naročito važno naglasiti da je u 2020. godini bilo skoro 1,5 puta više medijskih objava o akcijama davanja nego u 2019. godini (sa 8.807 na 13.109).</p>
                <p>Ubedljivo najviše su izveštavali nacionalni mediji, sa 5,8% više u raspodeli prema pokrivenosti nego u 2019. godini.</p>
                <p>Onlajn mediji su, s obzirom na tip medija, imali najveću zastupljenost, pa su u odnosu na štampane i elektronske prednjačili sa 75,1% ukupnih objava.</p>
                
                <h3 className="panel-subtitle">Pokrivenost</h3>
                <PieChart 
                    csvUrl='https://gist.githubusercontent.com/kragovic/a77e3422e1d810d06600e4964933f4ca/raw/c59929738f4d512d2474f25821d19545bac00b57/mediaCoverage.csv' 
                />
                
                <h3 className="panel-subtitle">Vrsta medija</h3>
                <PieChart 
                    csvUrl='https://gist.githubusercontent.com/kragovic/438fb34c5370e2206c500d6488471694/raw/385ab1a26865a9cf756795cee8b3184a60244a4c/mediaType.csv' 
                />
                <p>Važno je istaći da je u štampanim medijima 13% objava bilo na prvim stranama. Oko 10% objava u elektronskim medijima bilo je u terminu najveće gledanosti, odnosno slušanosti – nakon 19h.</p>
                <div id="end"></div>
            </div>
        )
    } 
}

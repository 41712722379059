import React from 'react';
import { useRouteMatch } from "react-router-dom";

export const Geography = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">2</span>Geographic overview of giving</h2>
                <iframe src="https://app.givingbalkans.org/Reports/Serbia2021.html" width="100%" height="650px" frameBorder="0"/>
                <p>The pandemic stopped us from conducting our usual comparison of the geographic distribution of donations in 2020 and the previous year as many donations were directed to health centers, either through direct donations to healthcare institutions or through local or national governments (which then distributed the donations to healthcare institutions in different locations).</p>
                <p>However, it is important to highlight several larger changes that we believe can be attributed in part, though perhaps not entirely, to the pandemic.</p>
                <p>We recorded a noticeable drop in giving in Vojvodina. The decline in its share in the total number of instances in Serbia had previously been small but constant – a drop of one or two percent each year. However, in 2020 it dropped from 21.6% in 2019 to only 15.9% for instances unrelated to the pandemic and 13.4% to address the effects of the pandemic, indicating a significant change in the region. One of the main causes for this was most likely the fact that significantly fewer instances of giving to non-profit organizations were recorded: a drop from 138 instances directed to 82 organizations in 2019 to only 95 instances to 59 non-profit organizations in 2020.</p>
                <p>Donations aimed at beneficiaries in Belgrade grew, a phenomenon that can be explained in part by the concentration of regional and national health institutions in the capitol city (at the beginning of the pandemic, a significant portion of the population was treated in Belgrade). Therefore, instead of receiving the one third of the total instances typical of previous years, in 2020 close to 40% of instances were directed to Belgrade. At the same time, Novi Sad and Niš together received less than 7% of the total instances, a drop from 2019, when 10.5% of the total charitable instances were directed to these two cities.</p>
                <p>Giving aimed at supporting beneficiaries in multiple regions of Serbia increased in 2020. The increase is particularly found in the giving to address effects of the COVID-19 pandemic, mostly owing to donations for regional healthcare centers.</p>
                <p>Giving to beneficiaries outside of Serbia declined, in accordance with the response to the pandemic globally to withdraw into one’s own community. As might be expected, the share of giving outside Serbia that sought to address effects of the pandemic was larger than the share of donations provided outside Serbia that were unrelated to the pandemic. In both cases, the bulk of donations went to Bosnia and Herzegovina, followed by Kosovo and Montenegro.</p>
                <div id="donors"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">2</span>Geografski pregled davanja</h2>
                <iframe src="https://app.givingbalkans.org/Reports/Serbia2021.html" width="100%" height="650px" frameBorder="0"/>
                <p>Pandemija nam je onemogućila uobičajeno poređenje geografske raspodele davanja u 2020. godini i u prethodnim godinama, jer je veliki deo donacija upućen centrima zdravstvene zaštite, bilo da su donacije date direktno zdravstvenim institucijama, bilo da su sredstva upućena lokalnim ili nacionalnim organima vlasti, a zatim raspoređena ka institucijama.</p>
                <p>Ipak, važno je da naglasimo nekoliko većih promena za koje procenjujemo da su donekle, ali ne u potpunosti, uzrokovane pandemijom.</p>
                <p>Zabeležili smo vidljiv pad davanja u Vojvodini. Opadanje zastupljenosti u ukupnom broju akcija u Srbiji je prethodnih godina bilo malo, mada konstantno – po jedan ili dva procenta. Nagli pad sa 21,6% u 2019. godini na svega 13,4% za posledice pandemije i 15,9% za davanja koja nisu usmerena na pandemiju govori o većoj promeni u regionu. Verovatno je jedan od većih uzroka značajno manji broj akcija ka neprofitnim organizacijama, pa je sa 138 akcija za 82 organizacije u 2019. godini davanje neprofitnom sektoru palo na 95 akcija za 59 organizacija.</p>
                <p>Primetno je da su donacije ka korisnicima u Beogradu porasle, što se donekle može objasniti koncentracijom regionalnih i nacionalnih zdravstvenih ustanova (dobar deo stanovništva je na početku lečen u Beogradu). Tako je, umesto trećine ukupnih akcija prethodnih godina, u 2020. godini Beogradu usmereno blizu 40%. Istovremeno, Novi Sad i Niš zajedno dobijaju manje od 7% ukupnih akcija dobročinstva usmerenih na njihovo stanovništvo (u 2019. na ova dva grada bilo je usmereno 10,5% akcija).</p>
                <p>Davanje usmereno na korisnike u više regiona je tokom 2020. godine poraslo, posebno kod davanja za posledice pandemije Kovida-19, i to uglavnom zbog donacija za regionalne zdravstvene centre.</p>
                <p>Smanjeno je davanje van Srbije, u skladu sa globalnim trendom zatvaranja u svoje zajednice. Kao što je očekivano, učešće davanja van Srbije vezanih za pandemiju veće je nego učešće davanja koja nisu usmerena na posledice pandemije. U oba slučaja najviše donacija je išlo ka Bosni i Hercegovini, zatim Kosovu i, na trećem mestu, Crnoj Gori.</p>
                <div id="donors"></div>
            </div>
        )
    }
    
}

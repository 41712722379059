export const keyThemes = {
    rs: [
        'Osobe sa zdravstvenim problemima',
        'Populacija lokalnih zajednica',
        'Osobe u riziku od siromaštva',
        'Osobe sa invaliditetom',
        'Starija populacija'
    ],
    en: [
        'People with health issues',
        'Local communities',
        'People in economic need',
        'People with disabilities',
        'Elderly population'
    ]
}
    
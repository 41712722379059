import React from 'react'
import {Title} from '../../../Title';
import {TextBox} from '../../../TextBox';
import {SimplerJIBarWithTable} from '../../../charts/SimplerJIBarWithTable/SimplerJIBarWithTable';
import { useRouteMatch } from "react-router-dom";

export const EndUsers = ({textData}) => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <div className="media-qf">
                <Title title='Final beneficiaries' />
                <TextBox textData={textData} />
                <SimplerJIBarWithTable 
                const csvUrl='https://gist.githubusercontent.com/kragovic/485d9f986175cd574305b4a6efb04eb3/raw/ec0d468e0a2e9e8a2842b5d3d89951d547bdbf9c/endUsersENG.csv'
                lang={params.lang}
                strippedRectClass='stripped-bar-w-table'
                patternStripe='pattern-stripe-bar-w-table'
                maskStripe='mask-stripe-bar-w-table'
                />
                </div>
                <div id="media"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <div className="media-qf">
                <Title title='Krajnji korisnici donacija' />
                <TextBox textData={textData} />
                <SimplerJIBarWithTable 
                    const csvUrl='https://gist.githubusercontent.com/kragovic/4f95c6e28ced8a5054b7bfe5fbe804df/raw/08543d9f9a735327b52909aba082b9f51d082a07/endUsers.csv'
                    lang={params.lang}
                    strippedRectClass='stripped-bar-w-table'
                    patternStripe='pattern-stripe-bar-w-table'
                    maskStripe='mask-stripe-bar-w-table'
                    />
                </div>
                <div id="media"></div>
            </div>
        )
    }
    
}

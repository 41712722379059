export const SidebarDataEN = [
    {
        title: 'Solidarity during the pandemic',
        path: '#root',
        cName: 'nav-text'
    },
    
    {
        title: 'Methodology',
        path: '#methodology',
        cName: 'nav-text'
    },

    {
        title: 'To better understand the report',
        path: '#to-understand-report',
        cName: 'nav-text'
        
    },  

    {
        title: 'Summary – philanthropy in 2020',
        path: '#summary',
        cName: 'nav-text'
    },

    {
        title: '1 Philanthropy throughout the year',
        path: '#philanthropy2020',
        cName: 'nav-text'
    },
 
   {
        title: '2 Geographic overview of giving',
        path: '#geography',
        cName: 'nav-text'
    },

    {
        title: '3 Who are the donors?',
        path: '#donors',
        cName: 'nav-text'
    },

    {
        title: '4 What is Serbia giving to?',
        path: '#themes',
        cName: 'nav-text'
    },

    {
        title: '5 Who do Serbian citizens support?',
        path: '#recipients',
        cName: 'nav-text'
    },

    {
        title: '6 Who are the final beneficiaries?',
        path: '#users',
        cName: 'nav-text'
    },

    {
        title: '7 Types of donations in Serbia',
        path: '#use',
        cName: 'nav-text'
    },

    {
        title: '8 Media coverage',
        path: '#medias',
        cName: 'nav-text'
    },

    {
        title: '9 Annexes',
        path: '#end',
        cName: 'nav-text'
    }
];
import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import {QuickFacts} from '../sections/QuickFacts/QuickFacts';
import {FullReport} from '../sections/FullReport/FullReport'

export const Serbia = () => {
    const { url, path, params } = useRouteMatch();

    if (params.type == 'full') {
            return <FullReport />     
    } else {
            return <QuickFacts />
    }
    
}


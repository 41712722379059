//import React from 'react'
import up from '../../images/up.svg';
import down from '../../images/down.svg'
import similar from '../../images/similar.svg';
import same from '../../images/same.svg';

import React, {
    useState,
    useEffect,
  } from 'react';
  import { csv } from 'd3';
  
 const useData = (csvUrl) => {
    const [data, setData] = useState(null);
  
    const row = (d) => {
      return d;
    };
  
    useEffect(() => {
      csv(csvUrl, row).then(setData);
    }, []);
    return data;
  };



export const TrendTable = ({csvUrl, category}) => {

    let data = useData(csvUrl)
    if (!data) {
        return <pre>Loading...</pre>;
      }
    
    const getKeys = () => Object.keys(data[0]);
    
        const myKeys = [`${category}`, '2018.', 'Trend', '2019.', 'Trend ', '2020.']
        

    
    const getHeader = () => {
        //const keys = getKeys();
        const keys = myKeys;
        return keys.map((key)=>
            <th key={key}>{key.toUpperCase()}</th>
        )
    }


    const getRowsData = function(){
        var items = data;
        //var keys = getKeys();
        let keys = myKeys;
        return items.map((row, index)=>{
        return <tr key={index}><RenderRow key={index} data={row} keys={keys}/></tr>
        })
        }

    return (
        <div>
            <table className="trend-table-dafault">
                <thead>
                  <tr>{getHeader()}</tr>
                </thead>
                <tbody>
                {getRowsData()}
                </tbody>
            </table>
        </div>
    )
}

const addTrendPic = change => {
    if (change == 'rast') {
        return  <img width="20" src={up} />
    } else if (change == 'pad') {
        return <img width="20" src={down} />
    } else if (change == 'slicno') {
        return <img width="20" src={similar} />
    } else if (change == 'isto') {
        return <img width="20" src={same} />
    }
     else {
        return change
    }
}

const RenderRow = ({keys, data}) =>{
    return keys.map((key)=>{
    return <td  key={Math.random()}>{addTrendPic(data[key]) }</td>
    })
   }
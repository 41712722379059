export const LegendCovid = ({
    tickSpacing,
    tickSize,
    tickTextOffset,
    xAttribute,
    labels,
    actions
  }) => (
    <g className={xAttribute}>
      <rect
        className="mark-2"
        width={tickSize}
        height={tickSize}
      />
      <text x={tickTextOffset} dy="1em">
      {actions ? labels[0] : labels[1]}
      </text>
    </g>
  );
  
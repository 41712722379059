import React from 'react';
import { useRouteMatch } from "react-router-dom";

export const ResearchMethodology = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">9.3</span>Research methodology</h2>
                <p>The methodology of this research is dependent on the available means of collecting data. Similar research across the world suggests that the only fully reliable source of information concerning charitable giving is the information collected by tax authorities. However, for multiple reasons this method cannot be used in any country of the Western Balkans.</p>
                <p>Catalyst Balkans has thus chosen alternative ways of collecting data, primarily using media and other available data resources. More specifically, the data utilized in producing this report was collected by analyzing electronic, print and online media reporting at the local, regional and national levels between 1 January and 31 December 2020.</p>
                <p>This methodology has three key limitations. First, it does not offer a comprehensive dataset because the media does not cover all charitable instances and donations. Second, media reports often do not provide all the data that is important in monitoring the development of giving for social good (e.g., information regarding the sums donated or money collected money are commonly not specified). The third limitation is the question of credibility of data reported in the media.</p>
                <p>The first limitation cannot be overcome at present. Catalyst seeks to address the second and third limitations by cross-referencing data from various media1, and through additional research, such as checking the reports of companies and non-profit organizations (if published) and, where possible, verifying information through direct communication with the donor and/or recipient. Despite these limitations, we believe our analyses to be both sound and offer insight into the state of giving in Serbia for the following reasons:</p>
                <ul className="research-methodology-list">
                    <li>The obtained numbers, although not comprehensive, provide the <strong>minimums</strong> for relevant indicators. For example, the number of charitable instances we identify and use is the minimum number of instances because there is a report that they occurred. The real number is certainly higher. The same holds true for the sums donated, actors involved (both donors and recipients), etc. Thus, this data can be used to indicate <strong>the minimum level of development of giving for social good</strong> in a country.</li>
                    <li>Ongoing monitoring provides information on the <strong>growth or decline in percentages and changes in data</strong> related to our indicators. In this sense, monitoring over the years identifies <strong>trends in the development of giving to social good</strong> as well as trends in media reporting.</li>
                </ul>
                <p>We have chosen not to the report on 2020 trends (as compared to previous years) because the number of donations addressing the consequences of the pandemic made 2020 an atypical year. We sought instead to distinguish recorded donations by their link to the immediate pandemic response and have focused this year’s report on the relation between donations to tackle the effects of the pandemic and donations unrelated to the pandemic. Nevertheless, to maintain a sense of the bigger picture, in Annex 9.1 we compare the 2020 donations unrelated to the pandemic with donations in 2018 and 2019.</p>
                <h3 className="panel-subtitle"><span className="subtitle-number">9.3.1</span>Factors and indicators showing the level of development of philanthropy</h3>
                <p>It is difficult to assess the general level of development of giving to social good in a context in which no precise data is generated by tax authorities. To form a picture of philanthropy, Catalyst identified and has monitored a series of key indicator groups.  These are: 1) instances (initiatives) of charitable giving; 2) methods of collecting funds; 3) purpose of donations; 4) donation recipients and final beneficiaries; 5) donors; 6) sums provided for charitable purposes; 7) actors1 and 8) media coverage. </p>
                <p>To permit comparative analyses (between countries and for any one country over multiple years), it was necessary to define quantitative and qualitative indicators for each of the indicator groups.</p>
                <table className="reasearch-meth-table">
                    <thead>
                        <tr>
                            <th>Indicator groups</th>
                            <th>Indicator (observed period – one year)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>instances of charitable giving</td>
                            <td>
                                <ul>
                                    <li>number of unique instances of charitable giving over one year</li>
                                    <li>geographic distribution (% of instances per region as compared to the total number of instances)</li>
                                    <li>% of instances of donations of money as compared to the total number of instances</li>
                                    <li>% of instances of donations of goods/services compared to the total number of instances</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>methods of collecting funds</td>
                            <td>
                                <ul>
                                    <li>types of methods of collecting funds</li>
                                    <li>representation of various methods (in %)</li>
                                    <li>emergence of new methods of collecting funds</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>purpose of donations</td>
                            <td>
                                <ul>
                                    <li>purposes of donations</li>
                                    <li>number (%) of instances per purpose</li>
                                    <li>emergence of new purposes</li>
                                    <li>use of donations per purpose</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>donation recipients and final beneficiaries</td>
                            <td>
                                <ul>
                                    <li>types of donation recipients </li>
                                    <li>number of instances in which recipients are from the public sector (% compared to the total number)</li>
                                    <li>number of instances in which recipients are from civil society (% compared to the total number)</li>
                                    <li>number of instances in which recipients are other groups (% compared to the total number)</li>
                                    <li>types of final beneficiaries</li>
                                    <li>number of instances per beneficiary group (% compared to the total number of instances)</li>
                                    <li>emergence and number of new types of final beneficiaries supported in a given year</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>donors</td>
                            <td>
                                <ul>
                                    <li>number of instances by donor type (% compared to the total number of instances)</li>
                                    <li>number of instances per recipient group and by donor type</li>
                                    <li>number of instances per purpose and by donor type</li>
                                    <li>number of instances per beneficiary group and by donor type</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>sums given to charitable purposes</td>
                            <td>
                                <ul>
                                    <li>total sum donated for charitable purposes</li>
                                    <li>% of instances with a recorded value of the donation (compared to the total number)</li>
                                    <li>% of donated sum by donor type</li>
                                    <li>% of donated sum by recipient group</li>
                                    <li>% of donated sum by purpose</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>actors</td>
                            <td>
                                <ul>
                                    <li>type and number of actors</li>
                                    <li>emergence of new actors</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>media</td>
                            <td>
                                <ul>
                                    <li>total number of media reports</li>
                                    <li>number (%) of media reports by type of media</li>
                                    <li>number (%) of reports by territory the media covers (national, subregional, local)</li>
                                    <li>number of reports treated as important by type of media (printed, electronic)</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">9.3</span>Metodologija istraživanja</h2>
                <p>Naša metodologija je neizbežno uslovljena mogućim načinima prikupljanja podataka. Iz dostupnih istraživanja u svetu pokazuje se da jedini potpuno pouzdani izvor informacija o davanju u dobrotvorne svrhe predstavlja poreska služba. Međutim, ovaj izvor, iz više razloga, nije dostupan ni u jednoj zemlji Zapadnog Balkana.</p>
                <p>Katalist se opredelio za alternativne načine prikupljanja podataka, pa koristi pre svega medije, a zatim i ostale dostupne, direktne izvore podataka. Konkretno, podaci u ovom izveštaju su prikupljeni kroz praćenje medija na lokalnom, regionalnom i nacionalnom nivou, a bili su uključeni elektronski, štampani i onlajn mediji u periodu od 1. januara do 31. decembra 2020. godine.</p>
                <p>Postoje tri ključna ograničenja u ovoj metodologiji. Prvo, na ovaj način se ne mogu dobiti sveobuhvatni podaci, jer mediji ne mogu propratiti sve dobrotvorne akcije i davanja. Drugo, izveštaji iz medija često ne navode sve podatke koji su važni za praćenje razvoja davanja za opšte dobro (najčešće se ne navodi suma novca koja je data i prikupljena). Treće potencijalno ograničenje je pitanje verodostojnosti podataka koji se navode u medijima.</p>
                <p>Prvo ograničenje za sada nije moguće prevazići. Što se tiče drugog i trećeg ograničenja, Katalist ih prevazilazi tako što ukršta podatke iz različitih medija (različiti mediji vrlo često izveštavaju o istim donacijama, te se upoređivanjem podataka iz više medijskih izveštaja dobijaju tačniji i potpuniji podaci), a zatim obavlja i dodatna istraživanja, odnosno proverava izveštaje kompanija i neprofitnih organizacija (ukoliko su oni objavljeni). Kada je moguće, odnosno kada darodavci i primaoci donacija žele da daju podatke, podatke iz medija verifikujemo iz ovih direktnih izvora. Bez obzira na ograničenja, kojih smo svesni, smatramo da postoje dva razloga koja opravdavaju naše analize:</p>
                <ul className="research-methodology-list">
                    <li>Iako nisu sveobuhvatne, dobijene brojke daju <strong>minimume</strong> relevantnih pokazatelja. Tako, ako govorimo o broju dobrotvornih akcija, možemo sa sigurnošću tvrditi da broj koji mi prikazujemo predstavlja minimalni broj akcija, jer su se one sigurno desile, kao i to da je pravi broj sigurno veći. Slično je i sa sumama novca, akterima i dr. Dakle, ovi podaci se mogu koristiti kao pokazatelji <strong>minimalnog stepena razvoja davanja za opšte dobro</strong> u određenoj zemlji.</li>
                    <li>Kontinuirano praćenje će ukazati na <strong>rast ili pad u procentima i promene podataka</strong> na koje se odnose pokazatelji koje smo definisali. U tom smislu, kontinuirano praćenje kroz godine ukazuje na <strong>trendove razvoja davanja za opšte dobro</strong>, kao i na trendove u medijskim prikazima.</li>
                </ul>
                <p>Smatramo da korišćena metodologija omogućava preliminaran uvid u stanje u oblasti davanja za opšte dobro u Srbiji.</p>
                <p>Kako je 2020. godinu izdvojilo davanje usmereno na posledice pandemije, praćenje trendova ove godine nije svrsishodno jer bi puko poređenje sa prethodnim godinama dalo krivu sliku. Mi smo posebno beležili davanja u odnosu na pandemiju i ovogodišnji izveštaj fokusirali na prikaz odnosa davanja povezanih i nepovezanih sa posledicama pandemije. No, zarad praćenja šire slike, davanja koja nisu povezana sa posledicama pandemije smo, u prilogu 9.1, prikazali uporedo sa davanjima iz 2018. i 2019. godine.</p>
                <h3 className="panel-subtitle"><span className="subtitle-number">9.3.1</span>Faktori i pokazatelji koji ukazuju na stepen razvijenosti filantropije</h3>
                <p>Kada u državi ne postoje kontinuirano praćenje i precizni podaci o dobročinstvu, teško je procenjivati opšti stepen razvijenosti davanja za opšte dobro. Da bi se stekla slika o dobročinstvu, Fondacija Katalist je morala da uspostavi osnove sistema za praćenje, pre svega kroz identifikovanje ključnih faktora koje treba pratiti. To su: 1) akcije/inicijative za dobrotvorna davanja; 2) metode prikupljanja novca; 3) namene davanja; 4) primaoci donacija i krajnji korisnici; 5) darodavci; 6) sume date u dobrotvorne svrhe; 7) <abbr title="Pod akterima podrazumevamo ne samo darodavce, već i one koji pozivaju na davanje pomoći, kao i one koji se na bilo koji način uključuju u temu davanja za opšte dobro. Iskustvo govori da povećanje broja aktera doprinosi tome da se u javnosti razvija svest o važnosti i ulozi dobrotvornog davanja u društvu">akteri</abbr> i 8) medijska pokrivenost.</p>
                <p>Da bi prikupljeni podaci omogućili komparativne analize (kako među zemljama, tako i u jednoj zemlji tokom više godina) na osnovu identifikovanih faktora, bilo je neophodno definisati kvantitativne i kvalitativne pokazatelje za svaki od tih faktora.</p>
                <table className="reasearch-meth-table">
                    <thead>
                        <tr>
                            <th>Faktor</th>
                            <th>Pokazatelj (posmatrani vremenski period – godinu dana)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>akcije/inicijative za dobrotvorna davanja</td>
                            <td>
                                <ul>
                                    <li>broj različitih akcija za dobrotvorna davanja tokom jedne godine</li>
                                    <li>geografska raspodela (% akcija po regionima u odnosu na ukupan broj akcija)</li>
                                    <li>% akcija u kojima se davao novac u odnosu na ukupan broj akcija</li>
                                    <li>% akcija u kojima su se davali roba/usluge u odnosu na ukupan broj akcija</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>metode prikupljanja novca</td>
                            <td>
                                <ul>
                                    <li>različite grupe (tipovi) metoda za prikupljanje novca</li>
                                    <li>% zastupljenosti različitih metoda</li>
                                    <li>pojava novih metoda za prikupljanje novca</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>namene akcija za dobrotvorno davanje</td>
                            <td>
                                <ul>
                                    <li>namene za koje se podrška prikuplja</li>
                                    <li>broj (%) akcija po svakoj od namena</li>
                                    <li>pojava novih namena</li>
                                    <li>upotreba donacija po nameni</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>primaoci donacija i krajnji korisnici</td>
                            <td>
                                <ul>
                                    <li>tipovi primalaca donacija</li>
                                    <li>broj akcija u kojima su primaoci iz državnog sektora (% u odnosu na ukupan broj)</li>
                                    <li>broj akcija u kojima su primaoci iz civilnog sektora (% u odnosu na ukupan broj)</li>
                                    <li>broj akcija u kojima su primaoci iz drugih grupa (% u odnosu na ukupan broj)</li>
                                    <li>tipovi krajnjih korisnika</li>
                                    <li>broj akcija usmerenih na različite grupe krajnjih korisnika (% u odnosu na ukupan broj akcija)</li>
                                    <li>pojava i broj novih grupa krajnjih korisnika</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>darodavci</td>
                            <td>
                                <ul>
                                    <li>broj akcija prema tipu darodavca (% u odnosu na ukupan broj akcija)</li>
                                    <li>broj akcija po različitim primaocima prema tipu darodavca</li>
                                    <li>broj akcija po nameni prema tipu darodavca</li>
                                    <li>broj akcija po korisničkim grupama prema tipu darodavca</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>sume date u dobrotvorne svrhe</td>
                            <td>
                                <ul>
                                    <li>ukupna suma data u dobrotvorne svrhe</li>
                                    <li>% akcija u kojima je poznata donirana suma (u odnosu na ukupan broj)</li>
                                    <li>% darovane sume prema tipu darodavaca</li>
                                    <li>% darovane sume prema tipu primaoca</li>
                                    <li>% darovane sume prema nameni</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>akteri</td>
                            <td>
                                <ul>
                                    <li>tip i broj različitih aktera</li>
                                    <li>pojava novih aktera</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>mediji</td>
                            <td>
                                <ul>
                                    <li>ukupan broj medijskih izveštaja</li>
                                    <li>broj (%) medijskih izveštaja prema tipu medija</li>
                                    <li>broj (%) izveštaja prema teritoriji pokrivanja (nacionalni, subregionalni, lokalni)</li>
                                    <li>broj izveštaja koji su tretirani kao važni prema tipu medija (štampani, elektronski)</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

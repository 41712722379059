import React from 'react';
import { useRouteMatch } from "react-router-dom";
import {Hexagon} from "./Hexagon";

const translateReportType = lang => {
    let langObj = {};
    switch(lang) {
        case 'rs':
        case 'bs':
        case 'mn':
        case 'cro':
            langObj.full = 'IZVEŠTAJ 2020.';
            langObj.quickfacts = 'GLAVNI NALAZI 2020.';
            langObj.infographic = 'INFOGRAFIK 2020.'
            langObj.headingFirstLine = 'Godišnji izveštaj o';
            langObj.headingSecondLine = 'stanju dobročinstva';
            break;
        case 'mk':
            langObj.full = 'ИЗВЕШТАЈОТ 2020.';
            langObj.quickfacts = 'ГЛАВНИ НАОДИ 2020.';
            langObj.infographic = 'ИНФОГРАФСКИ 2020.'
            langObj.headingFirstLine = 'Годишен извештај за состојбата';
            langObj.headingSecondLine = 'на добротворни цели';
            break;
        case 'al':
        case 'ks':
            langObj.full = 'RAPORTIN 2020.';
            langObj.quickfacts = 'GLAVNI NALAZI 2020.';
            langObj.infographic = 'INFOGRAFIK 2020.'
            langObj.headingFirstLine = 'Raporti Vjetor mbi';
            langObj.headingSecondLine = 'Gjendjen e Filantropisë';
            break;
        case 'en':
            langObj.full = 'Full Report';
            langObj.quickfacts = 'Quick Facts';
            langObj.infographic = 'INFOGRAPHIC 2020.';
            langObj.headingFirstLine = 'Annual Report on the';
            langObj.headingSecondLine = 'State of Philanthropy';
            break;
        default:
            langObj.full = 'IZVEŠTAJ 2020.';
            langObj.quickfacts = 'Glavni nalazi';
            langObj.headingFirstLine = 'Godišnji izveštaj o';
            langObj.headingSecondLine = 'stanju filantropije';
    }

    return langObj;
}

export const ChooseReport = () => {
    const { url, params } = useRouteMatch();
    
    let reportName = translateReportType(params.lang);

    const langText = (choosenCountry, choosenLang) => {
        let headingRow = {};
        switch (choosenCountry) {
            case 'Serbia':
                if (choosenLang == 'rs') {
                    headingRow.countryName = 'Srbija';
                    headingRow.lang = 'Srpski';
                } else {
                    headingRow.countryName = 'Serbia';
                    headingRow.lang = 'English';
                }
                break;
            case 'Croatia':
                if (choosenLang == 'cro') {
                    headingRow.countryName = 'Hrvatska';
                    headingRow.lang = 'Hrvatski';
                } else {
                    headingRow.countryName = 'Croatia';
                    headingRow.lang = 'English';
                }
                break;
            case 'Bosnia':
                if (choosenLang == 'bs') {
                    headingRow.countryName = 'Bosna i Hercegovina';
                    headingRow.lang = 'Bosanski';
                } else {
                    headingRow.countryName = 'Bosnia and Herzegovina';
                    headingRow.lang = 'English';
                }
                break;
            case 'Montenegro':
                if (choosenLang == 'mn') {
                    headingRow.countryName = 'Crna Gora';
                    headingRow.lang = 'Crnogorski';
                } else {
                    headingRow.countryName = 'Montenegro';
                    headingRow.lang = 'English';
                }
                break;
            case 'Albania':
                if (choosenLang == 'al') {
                    headingRow.countryName = 'Shqiperia';
                    headingRow.lang = 'Gjuha shqipe';
                } else {
                    headingRow.countryName = 'Albania';
                    headingRow.lang = 'English';
                }
                break;
            case 'Kosovo':
                if (choosenLang == 'al') {
                    headingRow.countryName = 'Kosovë';
                    headingRow.lang = 'Gjuha shqipe';
                } else {
                    headingRow.countryName = 'Kosovo';
                    headingRow.lang = 'English';
                }
                break;
            case 'NorthMacedonia':
                if (choosenLang == 'mk') {
                    headingRow.countryName = 'Северна Македонија';
                    headingRow.lang = 'Македонски';
                } else {
                    headingRow.countryName = 'North Macedonia';
                    headingRow.lang = 'English';
                }
                break;
        
            //default:
                
        }

        return headingRow;
    }

    let headingChoosen = langText(params.name, params.lang);

    if (params.name == 'Serbia') {
        let infograohicUrl = params.lang == 'en' ?  
        'https://givingbalkans.org/sites/default/files/SrbijaDaruje2020InfographicENG.pdf' :
        'https://givingbalkans.org/sites/default/files/SrbijaDaruje2020InfographicSRB.pdf';
        return (
            <div>
                <div className="choose-report-container">
                    <h1>{reportName.headingFirstLine}</h1>
                    <h1>{reportName.headingSecondLine}</h1>
                    <h1 className={`${"hex" + params.name}`}>{headingChoosen.countryName}/{headingChoosen.lang}</h1>
                    <div className="lang-hex-container">
                        <Hexagon hexText={reportName.full.split(" ")} hexClass={`${"hex" + params.name}`} pageUrl={`${url}/full`} />
                        <Hexagon hexText={reportName.quickfacts.split(" ")} hexClass="hexQuickFacts" pageUrl={`${url}/quickfacts`} />
                        <Hexagon infographic="yes" hexText={reportName.infographic.split(" ")} hexClass="hexInfographics" pageUrl={infograohicUrl} />
                    </div>
                </div>
               
            </div>
        )
    } else {
        return (
            <div>
                <div className="lang-container">
                    <h1>{reportName.headingFirstLine}</h1>
                    <h1>{reportName.headingSecondLine}</h1>
                    <h1 className={`${"hex" + params.name}`}>{headingChoosen.countryName}/{headingChoosen.lang}</h1>
                    <div className="lang-hex-container">
                        <Hexagon hexText={reportName.full.split(" ")} hexClass={`${"hex" + params.name}`} pageUrl={`${url}/full`} />
                    </div>
                </div>
                
            </div>
        )
    }
    
}

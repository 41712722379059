import React from 'react';
import { useRouteMatch } from "react-router-dom";

export const Enclosure92 = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">9.2</span>Work to improve the legal and fiscal framework for philanthropy</h2>
                <p>In the challenging year of the COVID-19 pandemic, the Coalition for Giving, part of the Philanthropy Development Council of the Serbian Prime Minister’s Office, continued to propose improvements to the legal framework for philanthropy. This included numerous proposals presented during the pandemic and the State of Emergency period (March 15 to May 6, 2020).</p>
                <p>On March 30, 2020 and in response to the pandemic, the Coalition for Giving proposed a set of measures to the Government of the Republic of Serbia and the Philanthropy Development Council which aimed to encourage donations and more efficient provision of assistance to the most vulnerable groups of citizens.</p>
                <p>The proposed measures included establishment of a working body within the Philanthropy Development Council to coordinate cooperation with civil society, establishment of a Compensation Fund for VAT return for non-profit organizations and temporary suspension of VAT on food donations. The Coalition also recommended the suspension of bank commissions on the relevant accounts of non-profit organizations and the issuing of movement permits to non-profit organization employees so that they could continue providing assistance in the field. In addition, the Coalition proposed that permission be granted for PayPal-based donations to facilitate donations to the Republic of Serbia and domestic non-profit organizations from both Serbian and foreign nationals. With regards to taxation, the Coalition stressed the importance of promoting g Guidelines on the Tax Relief Eligibility of Donors issued by the Tax Administration (in response to an earlier recommendation of the Coalition and the Philanthropy Development Council), which encourage donations by providing information on how companies can become eligible for partial income tax relief.</p>
                <p>Among these various recommendations, the Government chose to adopt, as part of its economic measures to fight the pandemic, the measure allowing exemption from VAT for donations. Adopted on April 10, 2020, the measure unfortunately applied only to those donors falling within the VAT system that provided donations to the Ministry of Health, the National Health Insurance Fund and state-owned healthcare institutions during the State of Emergency. Coalition members of the Philanthropy Development Council submitted a further recommendation to the Government that the scope of the measure be expanded to include all donors donating to non-profit organizations, associations, foundations and endowments in the VAT exemption measure during the State of Emergency. This recommendation was not accepted by the Government.</p>
                <p>The Government responded positively to another Coalition proposal, namely that its program of economic measures provide support to associations that operate as forms of social entrepreneurship because they employ groups that face particular employment challenges. This proposal was included in the Government’s pandemic-related measures.</p>
                <p>The Coalition for Giving also submitted several proposals based on the findings of two working groups of the Philanthropy Development Council: a working group focusing on improving the framework for donations of excess food and a working group to improve the legal and fiscal framework for donations from legal and natural persons.</p>
                <p>One of the key proposals involved suspending VAT on food donations. To inform decision makers and working groups of the Philanthropy Development Council, the Coalition for Giving and the Responsible Business Forum conducted a <strong>cost-benefit analysis of the suspension of VAT on food donations</strong>. The analysis showed that the value of food donations would increase by RSD 157.67 million, while the budget revenue from VAT would be reduced by RSD 18.37 million. The net social benefit was estimated at RSD 146.002.771 per annum. The analysis also investigated the legal framework in EU member states, which showed that VAT on excess food donations is not imposed in 20 of the 27 member states. In November, the Coalition for Giving sent a proposal for the suspension of VAT on food donations to the Ministry of Finance as part of consultations on the draft Law on Amendments to the Law on VAT, but it was not approved by the Ministry.</p>
                <p>The Coalition for Giving’s proposal to <strong>establish a compensation fund for VAT return for non-profit organizations</strong> involved the suggestion to establish a limited budget fund to return any VAT paid for goods and services that non-profit organizations donate for social good. Organizations could then use the reimbursed VAT to make further donations for social good.</p>
                <p>Lastly, to improve the conditions for all types of giving by individuals, the Coalition for Giving and the Association of Financial Institutions of the Serbian Chamber of Commerce and Industry, in consultation with commercial banks and civil society organizations, developed a proposal to form a register of dedicated accounts for donations which, despite their current operational policies, would allow them to waive commissions on donation payments.</p>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">9.2</span>Rad na unapređenju pravno-fiskalnog okvira za dobročinstvo</h2>
                <p>U izazovnoj godini koju je obeležila pandemija virusa korona, Koalicija za dobročinstvo, kao deo Saveta za filantropiju predsednice Vlade, nastavila je rad na predlozima za unapređenje zakonskog okvira, uključujući i brojne predloge tokom pandemije i vanrednog stanja, koje je u Srbiji trajalo od 15. marta do 6. maja 2020. godine.</p>
                <p>Kao odgovor na pandemiju, Koalicija za dobročinstvo uputila je 30. marta 2020. godine predlog mera Vladi Republike Srbije i Savetu za filantropiju u cilju podsticanja većeg broja donacija i omogućavanja efikasnijeg pružanja pomoći najugroženijem stanovništvu.</p>
                <p>Predložene mere su predviđale formiranje radnog tela pri Savetu za filantropiju za koordinaciju saradnje sa civilnim društvom, uspostavljanje Kompenzacionog fonda za povraćaj PDV-a neprofitnim organizacijama, kao i privremeno ukidanje PDV-a na doniranje hrane. Koalicija je preporučila ukidanje bankarskih provizija za namenske račune neprofitnih organizacija, kao i obezbeđivanje dozvola za kretanje zaposlenima u neprofitnim organizacijama kako bi mogli da neometano nastave sa pružanjem pomoći na terenu. Pored toga, predloženo je uvođenje PayPal donacija, odnosno omogućavanje i našim i stranim državljanima da putem ove platforme brzo i jednostavno doniraju Republici Srbiji i domaćim neprofitnim organizacijama. Koalicija je istakla i da je neophodno promovisati Uputstvo za ostvarivanje poreskih olakšica za donatore, koje je izradila Poreska uprava na preporuku Koalicije i Saveta za filantropiju, a koje kompanijama pruža informacije o tome kako mogu da ostvare delimično umanjenje poreza na dobit, čime se podstiču donacije.</p>
                <p>Od predloženih mera, Vlada Republike Srbije usvojila je meru za oslobađanje od plaćanja PDV-a na donacije, koju je predstavila u sklopu paketa ekonomskih mera. Ova mera je usvojena 10. aprila 2020, ali se nažalost odnosila samo na davaoce koji su u sistemu PDV-a i koji donaciju upućuju Ministarstvu zdravlja, RFZO i zdravstvenim ustanovama u državnoj svojini tokom trajanja vanrednog stanja. Članovi Saveta za filantropiju iz Koalicije za dobročinstvo uputili su preporuku Vladi Republike Srbije da se mera proširi i da se svi donatori koji doniraju neprofitnim organizacijama, udruženjima, fondacijama i zadužbinama oslobode plaćanja PDV-a tokom perioda vanrednog stanja, što Vlada nije prihvatila.</p>
                <p>Sa druge strane, Vlada je pozitivno reagovala na predlog Koalicije za dobročinstvo da program ekonomskih mera Vlade Republike Srbije treba da pruži podršku udruženjima koja organizuju privrednu delatnost po principu socijalnog preduzetništva, jer ona zapošljavaju teško zapošljive grupe, te je ovaj predlog i obuhvaćen merama Vlade.</p>
                <p>I pored izazova prethodne godine, Koalicija za dobročinstvo uputila je više predloga na osnovu nalaza radnih grupa Saveta za filantropiju (radne grupe koja je radila na na unapređenju okvira za doniranje viškova hrane i radne grupe koja je radila na unapređenju okvira za davanje pravnih i fizičkih lica).</p>
                <p>Među najvažnijima je predlog za ukidanje PDV-a na donacije hrane. Koalicija za dobročinstvo i Forum za odgovorno poslovanje su, radi informisanja donosilaca odluka i radne grupe Saveta za filantropij,u sproveli <strong>analizu troškova i koristi od ukidanja PDV-a na donacije hrane</strong> u periodu maj – jul 2020. godine. Analiza je pokazala da bi se vrednost donacija hrane povećala za 157,67 miliona dinara, dok bi se prihodi budžeta smanjili za 18,37 miliona dinara. Neto društvena korist procenjena je na 146,002,771 RSD godišnje. Analiziran je i zakonodavni okvir u državama članicama EU, koji je pokazao da se PDV na donacije viškova hrane ne naplaćuje u 20 od 27 država članica. Koalicija za dobročinstvo je Ministarstvu finansija uputila predlog za ukidanje PDV-a na donacije hrane 23. novembra, u okviru konsultacija povodom nacrta Zakona o izmenama i dopunama Zakona o PDV-u, ali predlog nije usvojen.</p>
                <p>Takođe, Koalicija za dobročinstvo preporučila je <strong>formiranje kompenzacionog fonda za povraćaj PDV-a neprofitnim organizacijama</strong>. Predlog je da se iz budžetskog fonda, koji je ograničen, vraća PDV koji je plaćen na robe i usluge koje su neprofitne organizacije donirale za opšte dobro. Ovaj vraćeni PDV organizacije bi ponovo koristile za davanje za opšte dobro.</p>
                <p>Dodatno, kako bi dugoročna unapredila uslove za donacije građana, Koalicija za dobročinstvo je zajedno sa Udruženjem finansijskih institucija Privredne komore Srbije, u konsultaciji sa komercijalnim bankama i organizacijama civilnog društva, izradila predlog sa ciljem formiranja registra namenskih računa za donacije, u koje bi banke imale uvid i ka kojima, u skladu sa poslovnim politikama, ne bi naplaćivale provizije za uplatu donacija.</p>
            </div>
        )
    }
}

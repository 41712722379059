import React, {useState, useEffect} from 'react';
import { csv } from 'd3';

export const useTextBoxData = (csvUrl) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const row = (d) => {  
      d.text = d.text.split(";");
      return d;
    };
    csv(csvUrl, row).then((data) => {
      setData(data);
    });
  }, []);
  return data;
};
import { NumberOfActionsBox } from './NumberOfActionsBox';
import { RecordedSumBox } from './RecordedSumBox';
import { AverageDonationBox } from './AverageDonationBox';
import { IncreaseGivingBox } from './IncreaseGivingBox';

const height = 350;
const width = 960;
const margin = { top: 20, right: 20, bottom: 20, left: 20 };
const innerWidth = width - margin.right - margin.left;

const rectHeight = 90;
const boxTitleVerticallOffset = 85;
const boxNumberVerticalOffset = 15; // in function of font size

const infoBoxRightPadding = 20;
const infoBoxWidth =
  innerWidth / 2 - infoBoxRightPadding / 2;
const secondRowTopMargin = 100;

export const InfoBoxTable = ({
  numberOfActionsTitle,
  numberOfActionsNumber,
  recordedSumTitle,
  recordedSumNumber,
  averageDonationTitle,
  averageDonationNumber,
  increaseGivingTitle,
  increaseGivingNumber
}) => (
  <svg className="info-box" viewBox={`0 0 ${width} ${height}`}>
    <g transform={`translate(${margin.left}, 0)`}>
      <g>
        <NumberOfActionsBox
          infoBoxWidth={infoBoxWidth}
          number={numberOfActionsNumber}
          title={numberOfActionsTitle}
          rectHeight={rectHeight}
          boxNumberVerticalOffset={boxNumberVerticalOffset}
          boxTitleVerticallOffset={boxTitleVerticallOffset}
        />
      </g>
      <g
        transform={`translate(${
          infoBoxWidth + infoBoxRightPadding
        }, 0)`}
      >
        <RecordedSumBox
          infoBoxWidth={infoBoxWidth}
          number={recordedSumNumber}
          title={recordedSumTitle}
          rectHeight={rectHeight}
          boxNumberVerticalOffset={boxNumberVerticalOffset}
          boxTitleVerticallOffset={boxTitleVerticallOffset}       
        />
      </g>
    </g>

    <g
      transform={`translate(${margin.left}, ${secondRowTopMargin})`}
    >
      <g>
        <AverageDonationBox
          infoBoxWidth={infoBoxWidth}
          number={averageDonationNumber}
          title={averageDonationTitle}
          secondRowMargin={secondRowTopMargin}
          rectHeight={rectHeight}
          boxNumberVerticalOffset={boxNumberVerticalOffset}
          boxTitleVerticallOffset={boxTitleVerticallOffset}
        />
      </g>
      <g
        transform={`translate(${
          infoBoxWidth + infoBoxRightPadding
        }, 0)`}
      >
        <IncreaseGivingBox
          infoBoxWidth={infoBoxWidth}
          number={increaseGivingNumber}
          title={increaseGivingTitle}
          secondRowMargin={secondRowTopMargin}
          rectHeight={rectHeight}
          boxNumberVerticalOffset={boxNumberVerticalOffset}
          boxTitleVerticallOffset={boxTitleVerticallOffset}      
        />
      </g>
    </g>
  </svg>
);
export const legendLabels = {
    rs: [
      'broj akcija u %',
      'poznata darovana suma u %',
      'od čega za COVID-19'
    ],
    en: [
      'number of instances in %',
      'recorded value of donations in %',
      'from which for COVID-19'
    ]
  }
export const NumberOfActionsBox = ({
    number,
    title,
    infoBoxWidth,
    rectHeight,
    boxNumberVerticalOffset,
    boxTitleVerticallOffset
  }) => (
    <>
      <rect
        y="0"
        width={infoBoxWidth}
        height={rectHeight}
        rx="15"
        fill="black"
        className="estimated-amount"
      />
      <text
        x={infoBoxWidth / 2}
        y={rectHeight / 2 + boxNumberVerticalOffset} // height / 2 + font-size / 2
        fill="#fff"
        textAnchor="middle"
        className="info-box-number"
      >
        {number}
      </text>
      <text
        x={infoBoxWidth / 2}
        y={rectHeight / 2 + boxTitleVerticallOffset}
        fill="#000"
        textAnchor="middle"
        className="info-box-title"
      >
        {title}
      </text>
    </>
  );  
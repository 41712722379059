import React from 'react'
import {Accordion} from '../../../Accordion';
import {SimplerJIBar} from '../../../charts/SimplerJIBar/SimplerJIBar';
import { useRouteMatch } from "react-router-dom";

export const HowIsGiven = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">7</span>Types of donations in Serbia</h2>
                <h3 className="panel-subtitle"><span className="subtitle-number">7.1</span>Effects of giving</h3>
                <p>In monitoring the development of philanthropy in Serbia, it seems important to keep an eye on the relation between strategic investments and one-off donations. Included in the category of long-term giving are capital investments, equipment, investments in services, scholarships, research and development, and contribution to public awareness-raising. In our data, one-off giving refers to humanitarian aid, seasonal giving, the medical treatment of individuals/families, and the provision of material and consumable goods. It is useful to add that it is not always possible to determine the purpose of donations from the information we gather.</p>
                <p>As the graph shows, slightly more than 30% of the total number of instances were directed to strategic purposes. The remaining instances included one-off donations and donations whose purpose could not be determined. Overall, the pandemic did not significantly alter the situation seen in previous years.</p>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/783a20afc8eb5eaa26966c1d1c7056d2/raw/358bc0e11e3ae9e00ef6c99a0b583ac951f710ae/donationsEffectsENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-9'
                    patternStripe='pattern-stripe-sr-9'
                    maskStripe='mask-stripe-sr-9' 
                    actions='true'
                 />
                <p>A specific development brought about by the pandemic was that the majority of strategic investments involved the purchase of equipment. Although a large percentage of funds had been strategically invested in equipment previously, in 2020 the most frequent and largest investments went to the purchase of equipment and vehicles for healthcare institutions.</p>
                <p>Investments in equipment also increased among long-term donations for other purposes. These mainly took the form of donations of desktop computers, laptops and other technical learning aids for educational facilities.</p>
                
                <div className="long-don-type">
                <h3 className="panel-subtitle">Type of long-term donation</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/dcfd1708c38e4340398c7c4ece637163/raw/db44d6266255ef32d16a85c6c2bb5235e3cf8af0/longDonTypeENG.csv'
                    strippedRectClass='mark-sji-bar-sr-10'
                    lang={params.lang}
                    patternStripe='pattern-stripe-sr-10'
                    maskStripe='mask-stripe-sr-10' 
                    actions='true'
                 />
                 </div>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/4f8dd8f3c23a4a5d952394b209feab82/raw/2d9317c2af9b09e4521ed34bbfe1974c41ca1166/effectsExamlesENG.csv'
                /> 
                <h3 className="panel-subtitle"><span className="subtitle-number">7.2</span>What is donated?</h3>
                <p>Over the years of monitoring philanthropy in Serbia, we have seen that most donors provide monetary donations. In 2020, direct donations of equipment (as well as other goods) slowed the otherwise increasing share of monetary donations in overall donations.</p>
                <p>A positive phenomenon in 2020 was the fact that volunteering claimed a larger share (4.2%) in the total number of instances, particularly due to volunteering related to the pandemic. Although this may not seem like enormous progress, it is important that in 2020 both donors and the media more frequently recognized volunteering as philanthropy, something which has not often been the case in previous years.</p>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/3364c0ca16a28af608ee6280ee753ee5/raw/d63a743ebaba16427ba648be22b5b84cc2370c5e/whatisGivenENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-11'
                    patternStripe='pattern-stripe-sr-11'
                    maskStripe='mask-stripe-sr-11' 
                    actions='true'
                 />
                 <p>We are convinced that innovative and creative ways of giving that do not require money can encourage and broaden philanthropy in Serbia. For this reason, we have chosen to share a few examples of particularly original donations of other types.</p>
                <h3 className="example-subtitle">Volunteering</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/a4c6ba1937d6c04ae2b23b008027602e/raw/694a7d572fdf89186a5aa786efb7c57d02b32ace/volunteeringExamplesENG.csv'
                />
                <h3 className="example-subtitle">Donating knowledge</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/0481cf3907b919acadff7d309d192847/raw/8a9bc8142d765b312b6b868de8e0d22ac6cee24d/knowledgeGivingExamples.csv'
                />
                <h3 className="example-subtitle">Professional services</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/c8d1bf38d48d9dcbc694cf70ed7712f5/raw/de93b087e7f8979858c4d22e35f88f2c95638485/profServicesExamplesENG.csv'
                />
                <h3 className="panel-subtitle"><span className="subtitle-number">7.3</span>Ways of giving</h3>
                <p>Direct donations have been the most common way of giving in Serbia ever since we began monitoring philanthropy in the country. The pandemic resulted in an increase in direct donations, as they made up 55.3% of total donations (of which 76.9% of the instances were given to address effects of the pandemic). This is the most important, and possibly most predictable, finding concerning the ways that donors give to emerge from our analysis of philanthropy in 2020.</p>
                <p>Events, which from 2018 onwards were the second most frequent way of giving and approximately one third of all instances, dropped to only 14.2%, due to the restrictions on movement and gathering put in place during the pandemic. While this drop could have been anticipated, it is noteworthy that, through a shift to online communication and online events, events remained in third place among the ways of giving. It is also interesting that almost all online events focused on causes unrelated to the pandemic.</p>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/f6cd0f69dff304d9927c62093dee9eeb/raw/94dee9954c8a043ec0e7621276c75dffeb649c6c/wayOfGivingActENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-12'
                    patternStripe='pattern-stripe-sr-12'
                    maskStripe='mask-stripe-sr-12' 
                    actions='true'
                 />
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/f09c1b48c06685617ab29859e7049aca/raw/86ba1030db6d56588582e8c51d3fa4e2bcc000dc/wayOfGivingENG.csv'
                />
                <h3 className="example-subtitle">Innovative ways of giving</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/3b36599e177d777c2479edfb0164394c/raw/57f91f95b2eb74ebfdd26ae866e859ab35035af2/inovativeGivingExamplesENG.csv'
                />
                <h3 className="example-subtitle">Giving through competitions</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/0baa2a50739c699bd4b1be5f2ece60a9/raw/75f4baec38a725de9aa79d1a8477ae4074f31ce4/konkursExamplesENG.csv'
                />
                <div id="medias"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">7</span>Kako se daruje u Srbiji?</h2>
                <h3 className="panel-subtitle"><span className="subtitle-number">7.1</span>Efekti davanja</h3>
                <p>Za razvoj dobročinstva u Srbiji, važno je pratiti odnos strateških ulaganja i jednokratnih darivanja koja privremeno rešavaju problem između jednokratne i dugoročne podrške. U dugoročna ulaganja ubrajamo: kapitalne investicije, opremu, ulaganje u usluge, stipendije (ulaganje u kadrove), istraživanje i razvoj, podizanje nivoa društvene svesti. U jednokratna davanja spadaju humanitarna pomoć, sezonska davanja, medicinski tretmani za pojedince/porodice, materijal i potrošna roba. Svrhu davanja nije uvek moguće odrediti.</p>
                <p>Kao što vidimo na grafikonu, od ukupno pobrojanih akcija dobročinstva, strateški je usmereno malo više od 30%. Ostalo su jednokratna davanja i davanja kojima nije bilo moguće odrediti efekat. U celini, pandemija nije značajno promenila ovu sliku u odnosu na prethodne godine.</p>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/a299e35fc14aa307ee067eb46a32e858/raw/af0043aefc356bbc7019461090281cfce5f64179/donationsEffects.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-9'
                    patternStripe='pattern-stripe-sr-9'
                    maskStripe='mask-stripe-sr-9' 
                    actions='true'
                 />
                <p>Specifičnost koju je donela pandemija jeste činjenica da je ubedljivo najviše strateških ulaganja bilo u opremu. I ranije je veliki procenat strateški opredeljenih sredstava ulagan u opremu, ali u 2020. godini su posebno oprema i vozila za zdravstvene ustanove bila najčešća i najvrednija ulaganja.</p>
                <p>Kod dugoročnih donacija za ostale svrhe je, takođe, primetan rast ulaganja u opremu, najviše za podršku obrazovanju: kompjuteri, laptopovi i druga tehnička pomoćna sredstva u nastavi.</p>
                
                <div className="long-don-type">
                <h3 className="panel-subtitle">Vrsta dugoročne donacije</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/79a2ee1132376360787caaef989abd70/raw/12fde2bf240d74384c5e26d04f315689c0ad9dc5/longDonType.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-10'
                    patternStripe='pattern-stripe-sr-10'
                    maskStripe='mask-stripe-sr-10' 
                    actions='true'
                 />
                 </div>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/f1da8f90986af03cc95593f2a82cc74a/raw/22e20173f30be13c932f632bb411d21303fbf94e/effectsExamles.csv'
                /> 
                <h3 className="panel-subtitle"><span className="subtitle-number">7.2</span>Šta se daruje?</h3>
                <p>Kao i prethodnih godina, zapravo od početka našeg praćenja dobročinstva u Srbiji, darodavci najviše daju novac. U 2020. godini je direktno davanje opreme blago spustilo trend rasta udela novca među donacijama, zbog medicinske opreme i druge robe koja je darivana zbog pandemije.</p>
                <p>Važan pozitivan fenomen jeste činjenica da je u dobrotvornim akcijama volontiranje bilo zastupljenije, naročito zbog udela volontiranja u akcijama vezanim za pandemiju sa 4,2%. Iako ne deluje kao veliki pomak, zapravo je važno da su tokom 2020. godine i darodavci i mediji češće prepoznavali volontiranje kao dobročinstvo, što prethodnih godina nije bio slučaj.</p>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/ff09b620696a36fc1d5178d0aa9660e6/raw/fc5c2f53a15916742235db166a78f7353a144610/whatisGiven.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-11'
                    patternStripe='pattern-stripe-sr-11'
                    maskStripe='mask-stripe-sr-11' 
                    actions='true'
                 />
                <h3 className="example-subtitle">Volontiranje</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/4bf1ef62112af640b4020554f1f435ac/raw/9867c933236e99c00d3b35fe2734797e571b5e6e/volunteeringExamples.csv'
                />
                <h3 className="example-subtitle">Poklanjanje znanja</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/1a930840c84eb8fd131cae1407acbe7e/raw/3c81655e9c099634598a65e84a39b3d452bc3b36/knowledgeGivingExamples.csv'
                />
                <h3 className="example-subtitle">Profesionalne usluge</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/76c12989053c7cc3f20a5a4d408b3f4b/raw/da555419acdf9235e89b06dd579e281757b51792/profServicesExamples.csv'
                />
                <h3 className="panel-subtitle"><span className="subtitle-number">7.3</span>Način davanja</h3>
                <p>Od početka praćenja dobročinstva u Srbiji direktne donacije preovlađuju kao način davanja. Pandemija je posebno naglasila ovaj način, i to nauštrb događaja, te je ove godine direktnih donacija bilo čak 55,3% (a od toga 76,9% akcija za posledice pandemije). To je i najvažniji, mada očekivan, fenomen u ovoj oblasti praćenja davanja u 2020. godini.</p>
                <p>Događaji, koji su od 2018. godine bili drugi način davanja, čineći oko trećine ukupnih akcija, zbog ograničenja kretanja i okupljanja tokom pandemije spustili su se na svega 14,2% u raspodeli. Sa druge strane, važno je da naglasimo da je bilo očekivano da će ovaj procenat biti manji, no prilagođavanje onlajn komunikaciji i organizovanje onlajn događaja zadržalo je ovaj način davanja na trećem mestu. Zanimljivo je i napomenuti da su skoro svi organizovani događaji bili usmereni na ostale svrhe, a ne na posledice pandemije.</p>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/e59afa897914cb8961f273e2f9cf1c6b/raw/b1b6994bad6534c334f3228f7e7f9b8e5df457ba/wayOfGivingAct.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-12'
                    patternStripe='pattern-stripe-sr-12'
                    maskStripe='mask-stripe-sr-12' 
                    actions='true'
                 />
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/ac6f9a728f9cf3f14662021ab187d7c1/raw/bf51a9ab36046520db663a8da558eedf56e4473a/wayOfGiving.csv'
                />
                <h3 className="example-subtitle">Inovativni načini davanja</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/0072abe52b2e3662ab5a3a650d14f106/raw/d72d127d8937973a9e442ef61e4027c210cf463a/inovativeGivingExamples.csv'
                />
                <h3 className="example-subtitle">Darivanje kroz konkurse</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/8fcc71b3912952bdaef0a25d518b10fc/raw/d85c0f7be01fea07ac96a1f0da5b095f6dafb4aa/konkursExamples.csv'
                />
                <div id="medias"></div>
            </div>
        )
    }
}

import * as React from 'react';
import { useData } from './useData';
import { keyThemes } from './keyThemes';
import {groups} from 'd3';

const smallerBinds = ['5-10 %', '1-3 %', '3-5 %' , '0-1 %'];

export const transformData = (data, lang, categoryValue, proportionAttribute) => {
  
  if (data) {
  data = data.filter(d => ! keyThemes[lang].includes(categoryValue(d)))
    data.map(theme => {
     	
      let proportion = theme[proportionAttribute];
      if (proportion < 0) {
        theme.proportion = 'delete'
      }
      else if (proportion < 1) {
      	theme.proportion = '0-1 %'
      } else if (proportion < 3) {
      	theme.proportion = '1-3 %'
      } else if (proportion < 5) {
      	theme.proportion = '3-5 %'
      } else if (proportion < 10) {
      	theme.proportion = '5-10 %'
      } else if(proportion >= 10) {
      	theme.proportion = '>10%'
      }
    })
    data = data.filter(d => d.proportion != 'delete')
    return reshapeData(data, smallerBinds, categoryValue);
  }
};

function compare( a, b ) {
  if ( a.interval < b.interval ){
    return -1;
  }
  if ( a.last_nom > b.last_nom ){
    return 1;
  }
  return 0;
}


const reshapeData = (data, smallerBinds, categoryValue) => {
    const themesGrouped = groups(data, d => d.proportion);

let categories = [];

// Transformisati ulazne podatke u oblik {pojedinacniInterval: vrednost, tipovi: 'sport, kultura, umetnost'}

themesGrouped.forEach(themeArray => {

    let intervalLabel = themeArray[0];
    let dataSingleInterval = themeArray[1];    
    let categoryList = [];

    dataSingleInterval.forEach(element => {
        categoryList.push(categoryValue(element))
    })

    categories.push({interval: intervalLabel, type: categoryList.join(', ') })
}) 

// Dodati eventualne nedostajuce intervale
const categoriesArray  = categories.map(a => a.interval);

for (let i = 0; i < smallerBinds.length; i++) {
    if (! categoriesArray.includes(smallerBinds[i])) {
        categories.splice(i, 0, {interval: smallerBinds[i], type: '-'})
    }
}

return categories.sort(compare).reverse()
}
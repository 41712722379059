import React from 'react'

export const AboutUs = () => (

        <div className="about-us">
            <div className="sites">
                <p><a href="https://givingbalkans.org/" target="_blank">givingbalkans.com</a><br/>
                <a href="https://catalystbalkans.org/" target="_blank">catalystbalkans.com</a></p>
            </div>
            <div className="report-team">
                <p><span>Fondacija Katalist (Catalyst Balkans)</span><br />Takovska 38, Beograd, Srbija</p>
                <p><span>Urednica/</span>Aleksandra Vesić Antić</p>
                <p><span>Autor/</span>Miloš Antić</p>
                <p><span>Kontrola kvaliteta podataka/</span>Jelena Bekčić</p>
                <p><span>Prikupljanje podataka/</span>Goran Bekić i<br/>Aleksandra Tomašević</p>
                <p><span>Dizajn/</span>Kata Jovančić</p>
                <p><span>Web Development/</span>Marko Kragović</p>
                <p><span>Lektura i korektura/</span>Jelena Budimirović</p>
                <p>Beograd, 2021</p>
            </div>
        </div>
)

import React from 'react';
import {Title} from './../../../Title';
import {SimplerJIBar} from '../../../charts/SimplerJIBar/SimplerJIBar'
import {TextBox} from '../../../TextBox';
import { useRouteMatch } from "react-router-dom";

export const Donors = ({textData}) => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <Title title='Donors' />
                <h3 className="panel-subtitle">Number of recorded instances</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/cc7988ac85a8981d7fb348fff9c3c2cb/raw/09b595803219b6b64a814e0f4f527dd912a0596f/donorsENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar' 
                    patternStripe='pattern-stripe-2'
                    maskStripe='mask-stripe-2'
                    actions='true'
                />
                <h3 className="panel-subtitle">Recorded value of donations</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/e75e06c74de453f17dfc4dea4e53bd50/raw/18a1d9232edbf0acc972742821af8421693a6eae/donorAmountENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar2' 
                    patternStripe='pattern-stripe-3'
                    maskStripe='mask-stripe-3'
                />
                
                <TextBox textData={textData} />
                <div id="recipients"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <Title title='Darodavci' />
                <h3 className="panel-subtitle">Broj zabeleženih akcija</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/1c1b6d3b2b8bff7896159a1df794cd38/raw/499047fac66ffbd4789e8d16fbbc208d6ca01daa/donors.csv'
                    lang={params.lang} 
                    strippedRectClass='mark-sji-bar' 
                    patternStripe='pattern-stripe-2'
                    maskStripe='mask-stripe-2'
                    actions='true'
                />
                <h3 className="panel-subtitle">Poznata darovana suma</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/3ceec9abff9c26e987c9c80e958e42fd/raw/84c11589e399c234dda56c2735a4857d5bc1e30a/donorAmount.csv'
                    lang={params.lang} 
                    strippedRectClass='mark-sji-bar2' 
                    patternStripe='pattern-stripe-3'
                    maskStripe='mask-stripe-3'
                />
                
                <TextBox textData={textData} />
                <div id="recipients"></div>
            </div>
        )
    }
}

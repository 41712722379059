export const otherTranslate = (language) => {
    let word = 'rs';
    
    switch (language) {
        case 'rs':
            word = 'Ostalo';
            break;
        case 'en':
            word = 'Other';
            break;
    
        default:
            word = 'Other';
            break;
    }

    return word;
}

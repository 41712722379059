import React from 'react'

export const AboutUsEN = () => (

        <div className="about-us">
            <div className="sites">
                <p><a href="https://givingbalkans.org/" target="_blank">givingbalkans.com</a><br/>
                <a href="https://catalystbalkans.org/" target="_blank">catalystbalkans.com</a></p>
            </div>
            <div className="report-team">
                <p><span>Catalyst Balkans</span><br />38 Takovska, Belgrade, Serbia</p>
                <p><span>Editor/</span>Aleksandra Vesić Antić</p>
                <p><span>Author/</span>Miloš Antić</p>
                <p><span>Data quality control/</span>Jelena Bekčić</p>
                <p><span>Data collection/</span>Goran Bekić and<br/> Aleksandra Tomašević</p>
                <p><span>Design/</span>Kata Jovančić</p>
                <p><span>Web Development/</span>Marko Kragović</p>
                <p><span>Translator/</span>Jovana Moc</p>
                <p>Belgrade, 2021</p>
            </div>
        </div>
)

import React from 'react'
import {Accordion} from '../../../Accordion';
import {SimplerJIBar} from '../../../charts/SimplerJIBar/SimplerJIBar';
import { useRouteMatch } from "react-router-dom";

export const WhoIsSupported = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">5</span>Who do Serbian citizens support?</h2>
                <p>Ever since we began monitoring philanthropy in the Balkans, we have been collecting data on donation recipients and final beneficiaries to better understand who are chosen as intermediaries in philanthropy by individuals engaging in mass giving.</p>
                <p>Among the recipients targeted by this donor group, individuals and families always receive the largest share of donations. In the pandemic year as well, the largest number of instances was directed to these two groups. However, in 2020, support for individuals saw a drop from 11.4% in 2019 to only 4.4%.</p>
                <p>The pandemic also impacted the ranking of recipient groups. A higher percentage of instances benefited the state (as opposed to non-profit organizations), perhaps as a natural result of the emergency situation.</p>
                <p>We recorded significant changes in the distribution of donations to the state and the non-profit sector. While over the previous years the level of donor trust in the state had been in continuous decline, in 2020 as much as 43.4% of the total sum donated was directed to institutions, local and national governments (by comparison the percentage was 15.2% in 2019).</p>
                <p>Alongside the large scale of giving to the state, the value of donations to the non-profit sector that we recorded remained high. Overall, it dropped only 5.4% as compared to the previous year.</p>
                <p>The documented value of donations to the non-profit sector (48.9%) was higher than the total value donated to governments and government institutions combined (43.4%). Although we cannot attest to people’s impressions of the non-profit sector as a whole (because a large portion of the sum was directed to only a few organizations), it is interesting to note that donors demonstrated less trust in the state as an intermediary between them and final beneficiaries. This is further supported by the fact that a portion of the funds donated to non-profit organizations was intended for use in government institutions.</p>
                <h3 className="panel-subtitle">Number of recorded instances</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/b8af23979c0cac2a2c040b3a80b550e5/raw/7cb1be763d57df17cbc90e5a02d14c5ab94a1106/recipientsActionsENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-3'
                    patternStripe='pattern-stripe-sr-3'
                    maskStripe='mask-stripe-sr-3' 
                    actions='true'
                 />
                <h3 className="panel-subtitle">Recorded value of donations</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/1cc5b036f6c4c9d8a3f07574e3162582/raw/14d6fb55caa58b0c2efbc7ee0cdc9d3df6e66cc6/recipientsMoney.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-4'
                    patternStripe='pattern-stripe-sr-4'
                    maskStripe='mask-stripe-sr-4' 
                 />
    
                <h3 className="panel-subtitle"><span className="subtitle-number">5.1</span>Support to non-profit organizations</h3>
                <p>In our monitoring of philanthropy, we pay particular attention to the roles of the non-profit sector and the state as intermediaries between donors and final beneficiaries.</p>
                <p>For the purposes of this report, non-profit organizations (or the non-profit sector, as we use these two terms synonymously) includes citizen associations, private foundations, citizen associations of the diaspora, and foreign organizations active in Serbia.</p>
                <p>Of the total value of donations in 2020, the non-profit sector received the largest amount. Moreover, and despite the pandemic, the non-profit sector also recorded an increased share of the number of instances of giving.</p>
                <p>As mentioned earlier, it is perhaps misleading to speak of the non-profit sector as a whole when discussing these results because the largest share of the funds was provided to only three large organizations: the ‘Budi Human – Aleksandar Šapić’ Humanitarian Foundation, NALED and UNICEF. The share provided to ‘Budi Human’ also grew in 2020 owing to the success of the ‘Budi Human – charity auctions’ Facebook group, created by Branka Zarić, through which over 300,000 people participated in auctions, paying amounts higher than market prices because all funds were being collected for children’s medical treatments. Branka has been nominated for one of the 2020 VIRTUS Awards.</p>
                <p>It is important to add that more than 80% of the funds directed to the non-profit sector was donated for purposes unrelated to the pandemic, i.e., for medical treatment of children and adults.</p>
                <p>The largest donors to the non-profit sector were individuals donating through mass giving, followed by the corporate sector and mixed donors. Despite the extraordinary circumstances of 2020, there were no significant changes in this regard. In the 2019 report, we mentioned the possibility that the share of corporate donations might decline in future due to companies redirecting funds into their own programs. However, the pandemic seems to have interrupted this trend as the corporate share in donations to the non-profit sector increased in 2020.</p>
                <p>Both the corporate sector and individuals through mass giving directed a considerable number of their instances that were not related to the pandemic to the non-profit sector.</p>
                <h3 className="panel-subtitle">Donors</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/c111a7167119c6d2870c904b063b3431/raw/8bb40942aa4fedd52b4b94c9b6c38596ec1c4991/supportNGODonorsENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-5'
                    patternStripe='pattern-stripe-sr-5'
                    maskStripe='mask-stripe-sr-5' 
                    actions='true'
                 />
    
                <p>Only 15.4% of the support for the non-profit sector was provided to address effects of the pandemic.</p>
                <p>A staggering 55.1% of all instances of giving to non-profit organization were for healthcare (principally the medical treatment of children and adults), while other themes were far less represented.</p>
                <p>The ranking of the most commonly supported final beneficiaries of donations to the non-profit sector was reversed in 2020 as compared to 2019. Specifically, 46% of all donations were provided to assist people with disabilities and only 20.5% for people with health issues. These changes were undoubtedly caused by the pressing needs of beneficiaries resulting from the pandemic.</p>
                <h3 className="panel-subtitle">Final beneficiaries</h3>
                 <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/9c705eef3720215ed93c66ea2b3921b0/raw/f78f146fa7c3b224f2f53576b3d1eb29c05b0a84/supportNGOendUsersENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-6'
                    patternStripe='pattern-stripe-sr-6'
                    maskStripe='mask-stripe-sr-6' 
                    actions='true'
                 />
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/27546ce91df6c50545d529b91729c854/raw/822c48875dca3a296b434f20ed4772c0a80e31d2/supportToNGOsExamplesENG.csv'
                />
                <h3 className="panel-subtitle"><span className="subtitle-number">5.2</span>Support to the state</h3>
                <p>In 2020, governments and institutions received 43.4% of the total value of donations, equivalent to 34.6% of all instances in Serbia. It is important to add that a portion of the instances directed to the non-profit sector were subsequently donated to government and its institutions. This is shown by the unusually high share (10.5%) of non-profit organizations among all donors to the state.</p>
                <p>We have emphasized in previous chapters that this is one of the most noteworthy changes attributable to the pandemic, although it is perhaps not entirely surprising as the state often benefits in emergency situations of this type. While giving to the state had been in decline for several years, the prolonged crisis and reduced scope for action resulted in a significant rebound in giving to the state.</p>
                <p>The ranking of top donors to the state did not change. As in previous years, the corporate sector remained the largest donor to the state, followed by individuals through mass giving (the latter with a significantly smaller share than in 2019).</p>
                <p>In 2020, individuals donated much more frequently to the state, placing them in third place in the ranking (instead of private foundations). They also placed third in their share of the total value of donations to the state. As much as 10.2% of the total instances of giving addressing effects of the COVID-19 pandemic originated from individuals.</p>
                
                <h3 className="panel-subtitle">Donors</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/4a985331c862498ff865e8709e1f95c7/raw/2c45e3ea9acc37999487c3e54ca3ce89817e1e88/SupportStateDonorsENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-7'
                    patternStripe='pattern-stripe-sr-7'
                    maskStripe='mask-stripe-sr-7' 
                    actions='true'
                 />
                <p>As regards the themes selected by donors, over 80% of the total sum donated to the state was given to combat effects of the COVID-19 pandemic, most commonly to healthcare institutions through government authorities or directly.</p>
                <p>The pandemic also altered the situation with regards to the beneficiaries of donations made to the state. This support was directed mainly to local communities, followed by people with health issues, while donations for people with disabilities decreased considerably (from 13.6% in 2019 to only 4.2% in 2020). Children without parental care stood out for the number of instances they received in 2020.</p>
                
                <h3 className="panel-subtitle">Final beneficiaries</h3>
                 <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/237027ed5f9ad9a48ba7edff80cff99c/raw/fc84ae057c5f292b227147bce4ebe985ccc973f4/SupportStateEndUsersENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-8'
                    patternStripe='pattern-stripe-sr-8'
                    maskStripe='mask-stripe-sr-8' 
                    actions='true'
                 />
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/2eb1f4af1a68aba7636cd2f309ab12d7/raw/5e92ed46002dcff5133a79484ffcd6ab9666357d/supportToStateENG.csv'
                />
                <div id="users"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">5</span>Koga podržavaju građani Srbije?</h2>
                <p>Od kada pratimo dobročinstvo na Balkanu, posebno prikupljamo podatke o primaocima donacija i o krajnjim korisnicima, kako bismo utvrdili čiju i kakvu vrstu posredništva u dobročinstvu građani biraju.</p>
                <p>U pogledu broja filantropskih akcija, najveći udeo među primaocima donacija uvek zauzimaju pojedinci i porodice. I u pandemijskoj, 2020. godini najveći broj akcija darivanja je išao direktno ka njima. Sa druge strane kada se radi o procentima darivanih sredstava, kod pojedinaca je taj procenat umanjen sa 11,4% u 2019. godini na svega 4,4% u 2020.</p>
                <p>Ipak, pandemija jeste promenila sliku primalaca jer je, za razliku od prošle godine, na državu bio usmeren veći procenat akcija nego na neprofitne organizacije, što je prirodna posledica vanredne situacije u kojoj smo se našli.</p>
                <p>Veće promene smo zabeležili u raspodeli donacija državi i neprofitnom sektoru. Dok je poslednjih godina nivo poverenja darodavaca u državu kontinuirano opadao, u 2020. godini je čak 43,4% ukupne zabeležene darovane sume usmereno na institucije, lokalne i nacionalne vlasti (poređenja radi, taj procenat je u 2019. bio 15,2%).</p>
                <p>Uprkos nužnosti davanja državi, zabeležena količina novca darovanog neprofitnom sektoru ostala je na visokom nivou – u ukupnoj raspodeli svega 5,4% manje nego prethodne godine.</p>
                <p>Zabeležena količina sredstava darovana neprofitnom sektoru (48,9%) viša je od zbira sredstava dodeljenih vlastima i institucijama zajedno (43,4%). Iako ne možemo da govorimo o odnosu građana prema neprofitnom sektoru u celini (jer je veliki deo sume bio usmeren na svega nekoliko organizacija), zanimljivo je da su darodavci, u ukupnoj sumi, manje poverenja poklonili državi kao posredniku ka krajnjim korisnicima (posebno kada se ima u vidu da je jedan deo sredstava koje su prikupile neprofitne organizacije zapravo bio namenjen institucijama).</p>
                <h3 className="panel-subtitle">Broj zabeleženih akcija</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/c1885597e4808fa3c646241e61e24af5/raw/949004a353eda83b1c50a76d8b29716ce86d00ef/recipientsActions.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-3'
                    patternStripe='pattern-stripe-sr-3'
                    maskStripe='mask-stripe-sr-3' 
                    actions='true'
                 />
                <h3 className="panel-subtitle">Poznata darovana suma</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/1cc5b036f6c4c9d8a3f07574e3162582/raw/14d6fb55caa58b0c2efbc7ee0cdc9d3df6e66cc6/recipientsMoney.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-4'
                    patternStripe='pattern-stripe-sr-4'
                    maskStripe='mask-stripe-sr-4' 
                 />
    
                <h3 className="panel-subtitle"><span className="subtitle-number">5.1</span>Podrška neprofitnim organizacijama</h3>
                <p>U praćenju stanja dobročinstva naročito nam je važno da posmatramo ulogu neprofitnog sektora i ulogu države, kao posrednikâ ka krajnjim korisnicima.</p>
                <p>Za potrebe ovog istraživanja u grupu neprofitnih organizacija, odnosno u neprofitni sektor (izraze koristimo kao sinonime), ubrajamo: udruženja građana, privatne fondacije, udruženja građana iz dijaspore i strane organizacije koje su aktivne u Srbiji.</p>
                <p>Videli smo da je u odnosu na ukupnu darovanu sumu u 2020. godini neprofitnom sektoru poklonjeno najviše. I u odnosu na broj akcija neprofitni sektor beleži rast udela, uprkos pandemiji.</p>
                <p>Kao što smo ranije isticali, ne možemo govoriti o neprofitnom sektoru u celini, jer je najveći deo sredstava usmeren ka svega tri velike organizacije: humanitarnu fondaciju „Budi human - Aleksandar Šapić”, NALED i UNICEF. Udeo organizacije „Budi human“ se ove godine povećao i zahvaljujući fejsbuk grupi „Budi human – Humanitarne licitacije“, koju je osnovala Branka Zarić, a preko koje preko 300.000 ljudi učestvuje u licitacijama kojima kupuje robu koja im nije neophodna, plaćajući iznose veće od tržišnih cena, jer se sva sredstva usmeravaju za lečenje dece. Branka je nominovana za jednu od nagrada VIRTUS.</p>
                <p>Takođe je važno napomenuti da je više od 80% sredstava dodeljenih neprofitnom sektoru darovano za akcije koje nisu povezane sa pandemijom, i usmereno na sredstva za lečenje dece i odraslih.</p>
                <p>Najveći darodavci neprofitnom sektoru su građani kroz masovna davanja, zatim poslovni sektor i mešoviti darodavci. Uz sve posebne okolnosti u ovoj godini, bitnih promena u raspodeli u ovom segmentu nije bilo. Prošle godine smo posebno napomenuli mogućnost da kompanije smanje svoj udeo zbog preusmeravanja sredstava u sopstvene programe, no izgleda da je pandemija omela taj trend, pa je učešće poslovnog sektora u davanju neprofitnom sektoru u 2020. godini čak i veće.</p>
                <p>I poslovni sektor i građani su ka neprofitnom sektoru uglavnom usmeravali akcije koje nisu povezane sa pandemijom.</p>
                <h3 className="panel-subtitle">Darodavci</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/b3e94a1adf379a2d1602bc1f73b3ed2b/raw/72d2cfafd720f54a330981c6cda543a09e3f8610/supportNGODonors.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-5'
                    patternStripe='pattern-stripe-sr-5'
                    maskStripe='mask-stripe-sr-5' 
                    actions='true'
                 />
    
                <p>Kada posmatramo teme za koje je neprofitni sektor bio podržan, svega 15,4% je bilo usmereno na posledice pandemije.</p>
                <p>Čak 55.1% svih akcija bilo je usmereno na zdravstvo (uglavnom lečenje dece i odraslih), a preostale teme su bile mnogo manje zastupljene.</p>
                <p>Najčešće podržani krajnji korisnici kojima su bile namenjene donacije u okviru neprofitnog sektora zamenili su mesta u raspodeli u odnosu na 2019. godinu. Naime, 46% svih akcija davanja je bilo, u krajnjem, usmereno ka osobama sa invaliditetom, a svega 20,5% na osobe sa zdravstvenim problemima. Očigledno je da su ove promene uslovljene akutnim potrebama korisnika koji su se našli u težim okolnostima zbog primene vanrednih i epidemioloških mera.</p>
                <h3 className="panel-subtitle">Krajnji korisnici</h3>
                 <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/ff18a91bda61db1f966311d8aa04f57b/raw/378ea08c529e6570983ddedea4591c8fe052db08/supportNGOendUsers.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-6'
                    patternStripe='pattern-stripe-sr-6'
                    maskStripe='mask-stripe-sr-6' 
                    actions='true'
                 />
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/903434267c1d1548ec20cfc3c03d7d9d/raw/676807fd3444a0355e4f708b040dc43cbe218a15/supportToNGOsExamples.csv'
                />
                <h3 className="panel-subtitle"><span className="subtitle-number">5.2</span>Podrška državi</h3>
                <p>U 2020. godini je ka vlastima i institucijama usmereno 43,4% ukupne darovane sume, odnosno 34,6% svih akcija davanja u Srbiji. Važno je dodati da je na sve ovo i deo akcija usmerenih ka neprofitnom sektoru završavao kod ustanova ili institucija kao krajnjih korisnika, što se vidi u neuobičajeno visokoj zastupljenosti neprofitnih organizacija (osocijacija, udruženja i privatnih fondacija) sa 10,5% među darodavcima državi.</p>
                <p>Već smo naglašavali u prethodnim poglavljima da je ovo jedna od najvidljivijih i očekivanih promena u pandemijskim okolnostima. Iako su davanja državi nekoliko godina unazad u konstantnom padu, prolongirana krizna situacija u kombinaciji sa suženim opsegom delovanja uslovila je značajno učešće države kao primaoca donacija.</p>
                <p>Raspodela ključnih darodavaca nije bitno varirala – poslovni sektor je i prethodnih godina bio najveći darodavac državi, zatim su tu građani sa masovnim davanjima, mada sa znatno manjim učešćem u raspodeli nego 2019. godine.</p>
                <p>Ove godine su pojedinci i pojedinke dali mnogo više donacija državi, pa su oni umesto privatnih fondacija na trećem mestu u raspodeli akcija i na istom mestu u učešću u ukupno darivanoj sumi. Čak 10,2% od ukupnih akcija davanja za posledice pandemije Kovida-19 bilo je od pojedinaca.</p>
                
                <h3 className="panel-subtitle">Darodavci</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/3d70d8544c0c0682bcebe9c71c7a4cfb/raw/66249cf3f31b195c1e3941379a06c120c7abe521/SupportStateDonors.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-7'
                    patternStripe='pattern-stripe-sr-7'
                    maskStripe='mask-stripe-sr-7' 
                    actions='true'
                 />
                <p>Kada je reč o temama, preko 80% ukupne sume darovane državi bilo je namenjeno saniranju posledica pandemije Kovida-19 i, preko organa vlasti ili direktno, upućeno najčešće zdravstvenim institucijama.</p>
                <p>Što se tiče korisnika, i tu je pandemija donekle izmenila sliku. Državi je najčešće upućivana podrška za populaciju lokalnih zajednica, zatim za osobe sa zdravstvenim problemima, dok su davanja za osobe sa invaliditetom znatno smanjena (na svega 4,2% u odnosu na 13,6% u 2019. godini). Od ostalih grupa koje su podržane kroz pomoć državi, izdvajaju se deca bez roditeljskog staranja.</p>
                
                <h3 className="panel-subtitle">Krajnji korisnici</h3>
                 <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/c134e582c7b3c0c37f4354ed709a5163/raw/782ae4df83951e672ee310d18ae9a8ccfecb5331/SupportStateEndUsers.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-8'
                    patternStripe='pattern-stripe-sr-8'
                    maskStripe='mask-stripe-sr-8' 
                    actions='true'
                 />
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/faf4b1cd38ffb71b0757db0d4589c36f/raw/0a6c5695542b35b00c38c7eb479dfb23dca244ed/supportToState.csv'
                />
                <div id="users"></div>
            </div>
        )
    }
}

import { area } from 'd3';
export const Marks = ({
  xScale,
  y2Scale,
  yScale,
  data,
  innerHeight,
  xValue,
  y2Value,
  yValue,
  tooltipFormat,
  yAttribute,
  y2Attribute,
  textYOffset = -10,
  patternStripe='pattern-stripe-area',
  strippedClass='stripped-area-chart',
  maskStripe='mask-stripe-area'
}) => {
  const areaGenerator1 = area()
    .x((d) => xScale(xValue(d)))
    .y1((d) => yScale(yValue(d)))
    .y0(innerHeight);

  const areaGenerator2 = area()
    .x((d) => xScale(xValue(d)))
    .y1((d) => yScale(y2Value(d)))
    .y0(innerHeight);

    const patternStripeURL = '#' + patternStripe;
   

  return (
    <g className="marks">
      <path
        className={yAttribute}
        d={areaGenerator1(data)}
      />
      {data.map((d, i) => (
        <text
          key={i}
          x={xScale(xValue(d))}
          y={yScale(yValue(d)) + textYOffset}
          textAnchor="middle"
          className="area-text"
        >
          {yValue(d)}
        </text>
      ))}
			<defs>
        <pattern id={patternStripe} 
          width="7" height="4" 
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)">
          <rect width="3.5" height="4" transform="translate(0,0)" fill="white"></rect>
        </pattern>
        <mask id={maskStripe}>
          <rect x="0" y="0" width="100%" height="100%" fill={`url(${patternStripeURL})`} />
        </mask>      
      </defs>
      <path
        className={y2Attribute}
        id={strippedClass}
        d={areaGenerator2(data)}
      />
    </g>
  );
};
import React from 'react'
import {AreaChart} from '../../../charts/AreaChart/AreaChart'
import { useRouteMatch } from "react-router-dom";

export const PhilanthropyTime = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">1</span>Philanthropy throughout the year</h2>
                <p>The COVID-19 pandemic affected the distribution of donations over the course of 2020. In previous year, the largest number of donations was typically recorded during holidays, such as Christmas, Easter and New Year, and was followed by a drop in the subsequent period. In addition, upward trends were common from Spring to the summer vacation period and during the Fall, including December, which is typically the month in which the most donations are recorded.</p>
                <p>In 2020, the month with the greatest number of instances was April, in other words, during the State of Emergency introduced due to the COVID-19 pandemic. The State of Emergency was declared on March 15, and, already in March, we recorded 277 instances focusing on effects of the pandemic. This number of instances is higher than the average monthly number of instances in 2019 and the average monthly number of instances for other purposes in 2020. The largest number of donations was provided in April, when we recorded 691 instances (of which 606 were related to COVID-19). This also represents the highest number recorded since we began monitoring philanthropy in Serbia.</p>
                <p>It is also important to note that the number of instances unrelated to the pandemic increased in the last five months of 2020 and larger sums were donated than in the previous years.</p>
                <AreaChart 
                    csvUrl='https://gist.githubusercontent.com/kragovic/0a214abeb09325158e50cf8ac4382ac0/raw/0af24631855aa2eae8191801ab09c15c77d8b693/number_of_instances.csv'
                    lang={params.lang}
                />
                <div id="geography"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">1</span>Dobročinstvo tokom godine</h2>
                <p>Pandemija Kovida-19 bitno je uticala na vremenski raspored darivanja u 2020. godini. Uobičajeno je da najviše davanja beležimo tokom praznika kao što su Božić, Uskrs i Nova godina, da postoje periodi zatišja nakon praznika, te da generalni uzlazni trendovi budu od proleća do perioda godišnjih odmora i tokom jeseni, zaključno sa decembrom, u kome se najviše daruje.</p>
                <p>Ove godine je mesec sa najviše akcija bio april – mesec vanrednog stanja zbog pandemije Kovida-19. Vanredno stanje je proglašeno 15. marta, pa već tokom marta beležimo 277 akcija usmerenih na posledice pandemije, što je više od prosečnog mesečnog broja akcija u 2019. ili prosečnog mesečnog broja akcija za ostale svrhe u 2020. godini. U aprilu smo zabeležili čak 691 akciju dobročinstva (od toga 606 direktno povezanih sa bolešću Kovid-19), što je najveći broj akcija davanja otkad pratimo stanje dobročinstva u Srbiji.</p>
                <p>Važno je istaći i da je broj akcija davanja koja nisu usmerena na posledice pandemije tokom poslednjih pet meseci bio veći i sa većom darivanom sumom nego prethodnih godina.</p>
                <AreaChart 
                    csvUrl='https://gist.githubusercontent.com/kragovic/0a214abeb09325158e50cf8ac4382ac0/raw/0af24631855aa2eae8191801ab09c15c77d8b693/number_of_instances.csv'
                    lang={params.lang}
                />
                <div id="geography"></div>
            </div>
        )
    }
}

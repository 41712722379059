import React from 'react'
import { Link, useRouteMatch } from "react-router-dom";

export const Hexagon = ({hexText, hexClass, pageUrl, infographic='not'}) => {

    let passedCountry = hexText;
    if (passedCountry.length == 1) {
        passedCountry.unshift('')
    }
    let cNameHex = passedCountry.map((wordLine, index ) => <tspan key={index} x="140" dy={index == 0 ? 0 : 45}>{wordLine}</tspan>);
    
    return (
    <>
    <svg className={hexClass} version="1.0"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="200px" height="270px" viewBox="0 0 308 347" >
    {/* <a href={pageUrl}> */}

    {/* <a href={pageUrl} target={infographic == 'yes' ? '_blank' : ''}> */}
    <Link to={{ pathname: pageUrl }}  target={infographic == 'yes' ? '_blank' : '' } >
    <path className="st0" d="M13.4,69.1L126,4.1c8.4-4.9,18.8-4.9,27.3,0l112.5,65c8.4,4.9,13.6,13.9,13.6,23.6v130
        c0,9.7-5.2,18.7-13.6,23.6l-112.5,65c-8.4,4.9-18.8,4.9-27.3,0l-112.5-65C5,241.4-0.2,232.4-0.2,222.7l0-130
        C-0.2,82.9,5,73.9,13.4,69.1z"/>
    <g id="XMLID_1_">
        <text x="140" y="130" className="small">{cNameHex}</text>
    </g>
    {hexClass != 'hexSerbia' && hexClass != 'hexEnglish' && hexClass != 'hexInfographics' && hexClass != 'hexQuickFacts' ? <title>REPORT IN PROGRESS</title> : <></>}
   {/*  </a> */}
    {/* </a> */}
    </Link>
    </svg>
    
    </>
    )
}

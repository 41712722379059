import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import {Hexagon} from "./Hexagon";


export const Countries = () => {
    const { url, path } = useRouteMatch();
    return (
        <div>
            <div className="contries-hex-container">
	            <div className="top-hex-container">
                    <Hexagon hexText={["Albania"]} hexClass="hexAlbania" pageUrl="" />
                    <Hexagon hexText={["Bosnia", "and", "Herzegovina"]} hexClass="hexBosnia" pageUrl="" />
                </div>
                <div className="middle-hex-container">
                    <Hexagon hexText={["Croatia"]} hexClass="hexCroatia" pageUrl="" />
                    <Hexagon hexText={["Kosovo"]} hexClass="hexKosovo" pageUrl="" />
                    <Hexagon hexText={["Montenegro"]} hexClass="hexMontenegro" pageUrl=""  />
                </div>
                <div className="bottom-hex-container">
                    <Hexagon hexText={["North", "Macedonia"]} hexClass="hexNorthMacedonia" pageUrl="" />
                    <Hexagon hexText={["Serbia"]} hexClass="hexSerbia" pageUrl={`${url}/Serbia`} />
                </div>
            </div>
            {/* <h1>Country</h1>
            <nav className="navbar navbar-light">
                <ul className="nav navbar-nav">
                    <li>
                        <Link to={`${url}/Serbia`}>Serbia</Link>
                    </li>
                    <li>
                        <Link to={`${url}/Croatia`}>Croatia</Link>
                    </li>
                </ul>
            </nav> */}
        </div>
    )
}
export const SidebarData = [
    {
        title: 'Osnovni podaci',
        path: '#intro',
        cName: 'nav-text'
    },
    {
        title: 'Oblast davanja',
        path: '#area-of-giving',
        cName: 'nav-text'
    },
    {
        title: 'Darodavci',
        path: '#donors',
        cName: 'nav-text'
    },
    { 
        title: 'Primaoci donacija',
        path: '#recipients',
        cName: 'nav-text'
    },
    { 
        title: 'Krajnji korisnici donacija',
        path: '#end-users',
        cName: 'nav-text'
    },
    { 
        title: 'Dobročinstvo u medijima',
        path: '#media',
        cName: 'nav-text'
    },
    { 
        title: 'Primeri dobročinstva',
        path: '#examples',
        cName: 'nav-text'
    }
];
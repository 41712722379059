import { legendLabels } from '../legendLabels';
const labels = [
    'ukupan broj akcija u %',
    'od čega za COVID-19',
  ];

  export const ColorLegend = ({
    tickSpacing = 25,
    tickSize = 20,
    tickTextOffset = 30,
    yAttribute,
    lang
  }) => {
    if (yAttribute == 'Total') {
    return (
      <>
        <g className="area-color-legend">
          <rect width={tickSize} height={tickSize} />
          <text x={tickTextOffset} dy="1em">
            {legendLabels[lang][0]}
          </text>
        </g>
        <g
          className="area-color-legend"
          transform={`translate(0, ${20})`}
        >
          <defs>
            <pattern
              id="pattern-stripe"
              width="7"
              height="4"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect
                width="3.5"
                height="4"
                transform="translate(0,0)"
                fill="white"
              ></rect>
            </pattern>
            <mask id="mask-stripe">
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="url(#pattern-stripe)"
              />
            </mask>
          </defs>
          <rect
            className="background-legend-rect"
            fill="orange"
            width={tickSize}
            height={tickSize}
          />
          <rect
            className="CovidTotal"
            width={tickSize}
            height={tickSize}
          />
          <text x={tickTextOffset} dy="1em">
            {legendLabels[lang][1]}
          </text>
        </g>
      </>
    )} else if (yAttribute == 'Covid') {
        return <g className={yAttribute}><rect
        
        width={tickSize}
        height={tickSize}
      />
      <text x={tickTextOffset} dy="1em">
        {legendLabels[lang][0]}
      </text></g>
    } else if (yAttribute == 'Other') {
      return <g className={yAttribute}><rect
        width={tickSize}
        height={tickSize}
      />
      <text x={tickTextOffset} dy="1em">
        {legendLabels[lang][0]}
      </text></g>
    } else {
      return <div>ERROR</div>
    }
  };  
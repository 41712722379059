import React, { useState, useEffect } from 'react';
import { csv } from 'd3';

const AccordionOne = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export const Accordion = ({csvUrl}) => {
    let accordionData = useTextBoxData(csvUrl)
    if(!accordionData) {
        return <pre>Loading...</pre>
    }
    return (
    <div className="accordion">
        {accordionData.map(({ title, text}) => (
          <AccordionOne key={title} title={title} content={text.map(d => <p>{d}</p>)} />
        ))}
      </div>
)}

//import React, {useState, useEffect} from 'react';


const useTextBoxData = (csvUrl) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const row = (d) => {  
      d.text = d.text.split(";");
      return d;
    };
    csv(csvUrl, row).then((data) => {
      setData(data);
    });
  }, []);
  return data;
};




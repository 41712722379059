import React, { useState } from 'react';
import { pie, arc, interpolateRgb, scaleOrdinal } from 'd3';
import { Slice } from './Slice';
import { useData } from './useData';
import { ColorLegend } from './ColorLegend';
import { formatNumber } from '../../formatNumber';

const height = 370;
const width = 960;

const innerRadius = 120;
const outerRadius = 180;



export const PieChart = ({csvUrl, summary="not"}) => {
  let data = useData(csvUrl);
  
  let plotVariable = ['rgba(255, 117, 0, 1)', 'rgba(255, 117, 0, 0.8)',  'rgba(255, 117, 0, 0.6)', 'rgba(255, 117, 0, 0.4)', '#74b4c7'];

  let amtFormated = null;
  if (summary == 'amount' || summary == 'actions') {
    plotVariable = ['#0096b2',  '#74b4c7'];
    amtFormated = d => d.AmtFormated;
  }

  const initialProportionAttribute =  'Proportion';
  const [proportionAttribute, setProportionAttribute] = useState(
    initialProportionAttribute
  );
  const proportionValue = (d) => d[proportionAttribute];
  
  if (!data) {
    return <pre>Loading...</pre>;
  }

  const categoryValue = d => d.Theme;
  
  let pieGnerator = pie().sort(null)

  let pieGen = pieGnerator(data.map(d => proportionValue(d)));
  


  pieGen.map((d, i) => {
      d.theme = data[i].Theme;
    })

  const colorScale = scaleOrdinal()
    .domain(data.map(categoryValue))
  	.range(plotVariable);
  
  return (
    <>
    <svg viewBox={`0 0 ${width} ${height}`}>
      <g
        transform={`translate(${width * 7.5 / 10},${outerRadius})`}
      >
        <Slice
          pie={pieGen}
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          colorScale={colorScale}
        />
      </g>
      <g transform={`translate(200,${80})`} className="pie-color-legend">
        	<ColorLegend 
            colorScale={colorScale} 
            tickSpacing={50} 
  					tickSize={50} 
  					tickTextOffset={10}
            data={data}
            xValue={proportionValue}
            categoryValue={categoryValue}
            proportionValue={proportionValue}
            formatNumber={formatNumber}
            summary={summary}
            amtFormated={amtFormated}
          />
      </g>
    </svg>
    </>
  );
};
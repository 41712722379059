import React from 'react';
import {Title} from './../../../Title';
import bullet from'../../../../images/bullet.svg';
import { useRouteMatch } from "react-router-dom";

export const Methodology = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-transparent">
                <Title title='Methodology' />
                <p>
                Catalyst Balkans has been studying and analyzing philanthropy in the Western Balkans for eight years. We began gathering detailed data and publishing these reports to compensate for a lack of official data on giving for social good. The only fully reliable source of information on charitable giving typically comes from the tax administration, but in none of the Western Balkan countries do tax authorities collect or publish such information.
                </p>
                <p>
                    Our research relies on alternative sources:
                </p>
                    <ul className="methodology-list">
                        <li>data from reports published by electronic, print or online media at the local, regional and national level; and</li>
                        <li>data obtained directly from the largest donors and donation recipients.</li>
                    </ul>
                
                <p>
                The data used in generating this report was gathered by monitoring electronic, print and online media at the local, regional and national level in the period 1 January – 31 December 2020.
                </p>
                <p id="to-understand-report">
                All data was processed and published on the Giving Balkans platform (available at givingbalkans.org and srbijadaruje.org). Giving Balkans provides the most reliable insight into charitable giving in the region.
                </p>
                <p>
                For more detailed information about the methodology, see chapter 9.3.
                </p>
                <h3 className="panel-subtitle">To better understand the report</h3>
                <p>Herein we present brief explanations of terms with meanings specific to the report.</p>
    
                <div>
                    <table className="methodology-table">
                        <tbody>
                            <tr>
                                <td>Instance</td>
                                <td>For the purposes of classification and comparative analysis, we use the term instance to describe an organized collection of donations. It is a set of planned activities for giving or collecting donations. For example, an instance may be a campaign in which a group of individuals gathers funds for someone’s medical treatment, or a donation by one donor to one recipient.</td>
                            </tr>
                            <tr>
                                <td>Donor</td>
                                <td>Individual or a legal person that donates money, time, services or goods. To follow the trends more effectively, they have been divided into types of donors.</td>
                            </tr>
                            <tr>
                                <td>Individuals through mass giving</td>
                                <td>Donors comprised of a large number of individuals that cannot be identified by name.</td>
                            </tr>
                            <tr>
                                <td>Donors – mixed</td>
                                <td>An instance of giving that involves several types of donors that cannot be individually identified.</td>
                            </tr>
                            <tr>
                                <td>Donors – individuals</td>
                                <td>Individuals who can be identified.</td>
                            </tr>
                            <tr>
                                <td>Donors – corporate sector</td>
                                <td>In addition to standard entities such as small and medium-sized enterprises (fewer than 50 employees) and companies (more than 50 employees), the corporate sector category includes corporate foundations. Though, by legal form and type of profitability management, they do not belong to the corporate sector, our analysis focuses on the origin of donated funds, which is why we classify them as part of the corporate sector.</td>
                            </tr>
                            <tr>
                                <td>Donors – private foundations</td>
                                <td>Foundations established by individuals or a combination of individuals and legal persons.</td>
                            </tr>
                            <tr>
                                <td>Donation</td>
                                <td>A unique donation of a certain amount of money, goods, services or time provided without expectation of compensation.</td>
                            </tr>
                            <tr>
                                <td>Extrapolation</td>
                                <td>A statistical method which, based on the percentage of known data, calculates the data outcome if 100% of data were known. Extrapolation produces probable rather than absolute values.
</td>
                            </tr>
                            <tr>
                                <td>Philanthropy</td>
                                <td>Giving to social good, i.e., the voluntary giving of money, goods, time or services so as to help someone or improve the situation in society.</td>
                            </tr>
                            <tr>
                                <td>Final beneficiaries</td>
                                <td>Those benefiting from donations. For example, if a school is a donation recipient, the final beneficiaries are the children who attend that school.</td>
                            </tr>
                            <tr>
                                <td>Themes of giving</td>
                                <td>Broad purposes for which donations are provided (such as healthcare, education, or tackling the effects of the COVID-19 pandemic).</td>
                            </tr>
                            <tr>
                                <td>Donation recipients</td>
                                <td>Individuals or legal persons that receive donations from donors. In most cases, the donations are directed further toward specific beneficiaries.</td>
                            </tr>
                            <tr>
                                <td>Use of donations</td>
                                <td>The way a donation is used (e.g., for capital investment, purchase of equipment, provision of services, material, consumable goods etc.).</td>
                            </tr>
                        </tbody>
                    </table>         
                </div>
                <div id="summary"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-transparent">
                <Title title='Metodologija' />
                <p>
                Fondacija Katalist istražuje i analizira dobročinstvo na Zapadnom Balkanu već osam godina. Program smo pokrenuli kako bismo nadomestili nepostojanje zvaničnih podataka o davanjima za opšte dobro. Jedini potpuno pouzdani izvor informacija o davanjima u dobrotvorne svrhe jesu poreske službe, ali ni u jednoj zemlji Zapadnog Balkana poreske vlasti ne prikupljaju i ne objavljuju ove informacije.
                </p>
                <p>
                    Naše istraživanje se oslanja na alternativne izvore:
                    </p>
                    <ul className="methodology-list">
                        <li>podatke iz medijskih objava elektronskih, štampanih i online medija na lokalnom, regionalnom i nacionalnom nivou, i</li>
                        <li>podatke dobijene direktno od najvećih darodavaca i primalaca donacija.</li>
                    </ul>
                
                <p>
                Podaci u ovom izveštaju su prikupljeni kroz praćenje medija na lokalnom, regionalnom i nacionalnom nivou, a bili su uključeni elektronski, štampani i onlajn mediji u periodu od 1. januara do 31. decembra 2020. godine.
                </p>
                <p id="to-understand-report">
                Sve podatke smo obradili i prikazali u okviru platforme Giving Balkans (dostupna na givingbalkans.org i srbijadaruje.org), koja ujedno predstavlja najpouzdaniji uvid u dobrotvorna davanja u celom regionu.
                </p>
                <p>
                Detaljnije informacije o metodologiji pogledajte u poglavlju 9.3.
                </p>
                <h3 className="panel-subtitle">Za lakše razumevanje izveštaja</h3>
                <p>U nastavku dajemo kratka objašnjenja pojmova koji imaju specifičnu upotrebu u našem istraživanju i uopšte pojmova koji se u njemu koriste.</p>
    
                <div>
                    <table className="methodology-table">
                        <tbody>
                            <tr>
                                <td>Akcija</td>
                                <td>Radi klasifikacije i uporedne analize, u našem istraživanju pojam akcije koristimo da opišemo organizovano prikupljanje donacija. To je niz planiranih aktivnosti za darivanje ili prikupljanje donacija. Na primer, akcija je kampanja u kojoj građani masovno prikupljaju novac za nečije lečenje, a akcija je i donacija jednog darodavca jednom primaocu.</td>
                            </tr>
                            <tr>
                                <td>Darodavac</td>
                                <td>Građanin, građanka ili pravno lice koje daruje novac, vreme, usluge, robu. Radi lakšeg praćenja trendova, darodavci su podeljeni na tipove.</td>
                            </tr>
                            <tr>
                                <td>Građani kroz masovna davanja</td>
                                <td>Darodavci su građani u velikom broju i nije ih moguće identifikovati po imenu. Ne uključuje pravna lica.</td>
                            </tr>
                            <tr>
                                <td>Darodavci – mešoviti</td>
                                <td>U akciju je uključeno više tipova darodavaca koje nije moguće pojedinačno identifikovati.</td>
                            </tr>
                            <tr>
                                <td>Darodavci – pojedinci</td>
                                <td>Građani koje je moguće identifikovati.</td>
                            </tr>
                            <tr>
                                <td>Darodavci – poslovni sektor</td>
                                <td>Pored standardnih subjekata kao što su mala i srednja preduzeća (manje od 50 zaposlenih) i kompanije (više od 50 zaposlenih), u poslovni sektor svrstavamo i korporativne fondacije. Iako one po pravnoj formi i načinu upravljanja profitabilnošću ne pripadaju poslovnom sektoru, u našem istraživanju je fokus na poreklu sredstava koja se daruju, pa ih zato pratimo kao deo poslovnog sektora.</td>
                            </tr>
                            <tr>
                                <td>Darodavci – privatne fondacije</td>
                                <td>Fondacije koje su osnovali građani/ke ili kombinacije građana/ki i pravnih lica</td>
                            </tr>
                            <tr>
                                <td>Donacija</td>
                                <td>Pojedinačno davanje, bez nadoknade, određene količine novca, robe, usluge ili vremena.</td>
                            </tr>
                            <tr>
                                <td>Ekstrapolacija</td>
                                <td>Statistički metod kojim se na osnovu poznatog, analiziranog dela područja (manjeg dela neke celine) utvrđuju zakonitosti na celokupnom području. Ovaj metod se oslanja na pretpostavku da zakonitosti utvrđene u analiziranom delu važe na celokupnom području. Ekstrapolacijom se dobijaju verovatne, a ne apsolutne vrednosti.</td>
                            </tr>
                            <tr>
                                <td>Filantropija</td>
                                <td>Davanje za opšte dobro, odnosno dobrovoljno davanje novca, robe, vremena i usluga kako bi se pomoglo nekome ili kako bi se unapredilo stanje u društvu.</td>
                            </tr>
                            <tr>
                                <td>Krajnji korisnici</td>
                                <td>Oni kojima donacija koristi. Na primer, ukoliko je primalac donacije škola, krajnji korisnici su deca koja tu školu pohađaju.</td>
                            </tr>
                            <tr>
                                <td>Oblasti davanja</td>
                                <td>Svrhe za koje se daje (poput zdravstva, obrazovanja ili saniranja posledica pandemije Kovida-19).</td>
                            </tr>
                            <tr>
                                <td>Primaoci donacija</td>
                                <td>Građani/ke ili pravna lica kojima darodavac upućuje donaciju. Najčešće se ta donacija usmerava dalje, ka krajnjim korisnicima.</td>
                            </tr>
                            <tr>
                                <td>Upotreba donacije</td>
                                <td>Način na koji je donacija iskorišćena (npr. za kapitalnu investiciju, kupovinu opreme, pružanje usluga, materijal i potrošnu robu i sl.).</td>
                            </tr>
                        </tbody>
                    </table>         
                </div>
                <div id="summary"></div>
            </div>
        )
    }
}

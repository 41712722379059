import React from 'react';
import { useRouteMatch } from "react-router-dom";
import {Serbia} from './countryReports/Serbia';
import {Croatia} from './countryReports/Croatia';
import { Bosnia } from './countryReports/Bosnia';
import { Albania } from './countryReports/Albania';
import { Kosovo } from './countryReports/Kosovo';
import { Montenegro } from './countryReports/Montenegro';
import { NorthMacedonia } from './countryReports/NorthMacedonia';
import { Error404 } from './sections/Error404';

export const ReportView = () => {
    const { url, path, params } = useRouteMatch();
    /*
        Ovde ide switch (ili f-ja koja ide koristi switch)
        gde se vraca komponenta Serbia/Croatia/...
        Komponenti se prosledjuju parametri
        na osnovu kojih komponente Serbia/Croatia...
        ucitavaju izabrani izvestaj na izabranom jeziku
    */

    switch (params.name) {
        case 'Albania':
            return <Albania />
            break;
        case 'Serbia':
            return <Serbia />
            break;
        case 'Bosnia':
            return <Bosnia />
        case 'Croatia':
            return <Croatia />
            break;
        case 'Kosovo':
            return <Kosovo />
            break;
        case 'Montenegro':
            return <Montenegro />
            break;
        case 'NorthMacedonia':
            return <NorthMacedonia />
            break;
    
        default:
            return <Error404 />
    }


   /*  return (
        <div>
            <h1>{`${params.name} ${params.lang} ${params.type}`}</h1>
        </div>
    ) */
}

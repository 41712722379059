export const SidebarDataEN = [
    {
        title: 'Key points',
        path: '#intro',
        cName: 'nav-text'
    },
    {
        title: 'Giving by theme',
        path: '#area-of-giving',
        cName: 'nav-text'
    },
    {
        title: 'Donors',
        path: '#donors',
        cName: 'nav-text'
    },
    { 
        title: 'Donation recipients',
        path: '#recipients',
        cName: 'nav-text'
    },
    { 
        title: 'Final beneficiaries',
        path: '#end-users',
        cName: 'nav-text'
    },
    { 
        title: 'Media coverage',
        path: '#media',
        cName: 'nav-text'
    },
    { 
        title: 'Examples of philantrophy',
        path: '#examples',
        cName: 'nav-text'
    }
];
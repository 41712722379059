export const ColorLegend = ({
    colorScale,
    tickSpacing = 20,
    tickSize = 10,
    tickTextOffset = 10,
    data,
    xValue,
    categoryValue,
    proportionValue,
    formatNumber,
    summary,
    amtFormated
  }) => {
    let measurementUnit = '%';
    if(summary == 'amount') {
      measurementUnit = " €";
    } else if (summary == 'actions') {
      measurementUnit = "";
    }
   
    return (
      <>
        {
        
        data.map((theme, i) => (
          <g key={i} transform={`translate(170, ${i * tickSpacing})`}>
            <text
              className="legend-text"
              x={tickTextOffset}
              dy=".32em"
            >
              <tspan textAnchor="end">{categoryValue(theme)}</tspan>
            </text>
            <text
            className="legend-number"
              x={tickTextOffset}
              dx="0.5em"
              dy=".32em"
              fill={colorScale(categoryValue(theme))}
            >                     
                {amtFormated == null ? formatNumber(proportionValue(theme), 'sr') : amtFormated(theme)}{measurementUnit}
            </text>
          </g>
        ))}
      </>
    );
  };
export const ColorLegend = ({
    colorScale,
    tickSpacing = 20,
    tickSize = 10,
    tickTextOffset = 10,
    data,
    xValue,
    categoryValue,
    proportionValue,
    formatNumber,
    lang
  }) => {
  
    return (
      <>
       {/* <text className="themes-legend-title" dx="-150" dy="-60">Koje teme su važne građanima Srbije?</text>*/}
        {data.map((theme, i) => (
          <g key={i} transform={`translate(150, ${i * tickSpacing})`}>
            <text
              className="legend-text"
              x={tickTextOffset}
              dy=".32em"
            >
              <tspan textAnchor="end">{categoryValue(theme)}</tspan>
            </text>
            <text
            className="legend-number"
              x={tickTextOffset}
              dx="0.5em"
              dy=".32em"
              fill={colorScale(categoryValue(theme))}
            >        
              {formatNumber(proportionValue(theme), lang)}%
            </text>
          </g>
        ))}
      </>
    );
  };
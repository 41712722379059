import React from 'react'
import {Title} from './../../../Title';
import { useRouteMatch } from "react-router-dom";

export const AboutUs = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-transparent">
                <h3 className="panel-subtitle"><span className="subtitle-number">9.4</span>About us</h3>
                <p><strong>Catalyst Balkans</strong></p>
                <p>Data and technology for the development of philanthropy</p>
                <p>Catalyst Balkans helps non-profit organizations, donors and communities to connect and make use of their resources and strengths to achieve positive changes in society.</p>
                <p>Our work is based on four pillars:</p>
                <ul className="about-us-list">
                    <li>Philanthropy-related research and data collection</li>
                    <li>Developing technology for philanthropy</li>
                    <li>Advising and developing the capacities of fundraising organizations and donors</li>
                    <li>Advocating publicly for legal and fiscal changes to enable citizens and companies to donate more and more easily</li>
                </ul>
                <p>Our tools are:</p>
                <ul className="about-us-list">
                    <li>Giving Balkans – a platform with visualized data on philanthropy</li>
                    <li>CiviCatalyst – a community management platform</li>
                    <li>Donacije.rs – a crowdfunding platform for non-profit organizations</li>
                    <li>Neprofitne.rs – a platform to assess the transparency of non-profit organizations</li>
                </ul>
                <p>
                Catalyst Balkans is a regional foundation with headquarters in Belgrade. In Serbia, we are one of the founders of the Serbian Philanthropy Forum and members of the Coalition for Giving and the CSO Resource Centre. We are also members of the regional SIGN Network. Our partners are local organizations in the Western Balkans and organizations involved or interested in giving around the world.
                </p>
                <p><strong>Coalition for Giving and ‘Framework for Giving’ project</strong></p>
                <p>The Coalition for Giving was formed in late 2017 to develop and strengthen the philanthropic infrastructure, encourage the sustainable growth of giving for social good and help establishment inter-sector partnerships. It is headed by the Ana and Vlade Divac Foundation, while other members include the Trag Foundation, Serbian Philanthropy Forum, Responsible Business Forum, the Serbian Chamber of Commerce and Industry and Catalyst Balkans.</p>
                <p>In the context of the ‘Framework for Giving’ project, financially supported by the United States Agency for International Development (USAID), the Coalition works to improve the legal framework for giving by legal and natural persons and promote philanthropy and the culture of giving among legal and natural persons in the Republic of Serbia and diaspora.</p>
                <p>Within the project, the Coalition conducts various forms of research on philanthropy and regularly publishes data to promote the topic. The Coalition also supports networking among actors involved in or benefitting from philanthropy by organizing events, round tables and conferences, as well as campaigns. To date it has launched three campaigns:</p>
                <ul className="about-us-list">
                    <li>the National Day of Giving, which focuses on a different cause or issue each year through the joint action of fundraising;</li>
                    <li>the Race of Goodness, in cooperation with the Belgrade Marathon, in which runners help non-profit organizations raise funds for their causes; and</li>
                    <li>DobroČiniTim, which links individuals who want to make a contribution to a community.</li>
                </ul>
                <p>On the initiative of the Coalition for Giving, the Prime Minister of the Republic of Serbia established a Philanthropy Development Council within her Office. During its existence (2018 to mid-2020), the Council helped increase the tax-exempt component of donations for scholarships and adopted special guidelines on the eligibility of legal entities for tax relief for donations to social good.</p>
                <p><strong>Sources:</strong></p>
                <ul className="about-us-list">
                    <li>givingbalkans.org</li>
                    <li>catalystbalkans.org</li>
                </ul>
            </div>
        )
    } else {
        return (
            <div className="panel-transparent">
                <h3 className="panel-subtitle"><span className="subtitle-number">9.4</span>O nama</h3>
                <p><strong>Catalyst Balkans</strong></p>
                <p>Podaci i tehnologija za razvoj dobročinstva</p>
                <p>Mi pomažemo neprofitnim organizacijama, donatorima i zajednicama da se povežu i sopstvenim snagama naprave pozitivnu promenu u društvu.</p>
                <p>Naš rad se zasniva na četiri stuba:</p>
                <ul className="about-us-list">
                    <li>Istraživanje i prikupljanje podataka o dobročinstvu,</li>
                    <li>Razvoj tehnologije za dobročinstvo,</li>
                    <li>Razvoj kapaciteta i savetovanje organizacija koje prikupljaju sredstva, kao i donatora,</li>
                    <li>Javno zagovaranje pravnih i fiskalnih promena kako bismo građanima i kompanijama omogućili da lakše i više doniraju za opšte dobro.</li>
                </ul>
                <p>Naši alati su:</p>
                <ul className="about-us-list">
                    <li>Giving Balkans – platforma sa vizualizovanim podacima o dobročinstvu,</li>
                    <li>CiviCatalyst – platforma za upravljanje zajednicom,</li>
                    <li>Donacije.rs – platforma za grupno finansiranje (crowdfunding) neprofitnih organizacija</li>
                    <li>Neprofitne.rs – platforma za ocenu transparentnosti rada neprofitnih organizacija.</li>
                </ul>
                <p>
                Catalyst Balkans je regionalna fondacija sa sedištem u Beogradu. U Srbiji smo jedan od osnivača Srpskog filantropskog foruma, deo Koalicije za dobročinstvo i Resurs centra za OCD. Deo smo i regionalne mreže SIGN. Naši partneri su lokalne organizacije sa Zapadnog Balkana, ali i širom sveta.
                </p>
                <p><strong>Koalicija za dobročinstvo i Projekat za unapređenje okvira za davanje</strong></p>
                <p>Koalicija za dobročinstvo je formirana krajem 2017. godine sa ciljem da razvije i ojača filantropsku infrastrukturu, podstakne održivi rast davanja za opšte dobro i pomogne u razvoju međusektorskih partnerstava. Predvodi je Fondacija „Ana i Vlade Divac”, a ostali članovi koalicije su Trag fondacija, Smart kolektiv, Srpski filantropski forum, Forum za odgovorno poslovanje, Privredna komora Srbije i Catalyst Balkans.</p>
                <p>U okviru „Projekta za unapređenje okvira za davanje”, koji finansijski podržava Američka agencija za međunarodni razvoj (USAID), Koalicija svojim delovanjem radi na unapređenju pravnog okvira za razvoj podsticajnog i transparentnog davanja pravnih i fizičkih lica i promoviše dobročinstvo i kulturu davanja među pravnim i fizičkim licima u Srbiji i dijaspori.</p>
                <p>Koalicija sprovodi istraživanja o dobročinstvu i transparentno objavljuje podatke, a takođe je omogućila umrežavanje aktera kroz organizaciju događaja, okruglih stolova i konferencija, kao i kroz tri kampanje:</p>
                <ul className="about-us-list">
                    <li>Nacionalni dan davanja, čiji je cilj da stvori promenu u određenoj oblasti kroz zajedničku akciju prikupljanja sredstava;</li>
                    <li>Trka dobrote, u saradnji sa Beogradskim maratonom, u okviru koje trkači pomažu neprofitnim organizacijama da prikupe sredstva za svoje ciljeve; i na kraju</li>
                    <li>DobroČiniTim, koji okuplja pojedince koji žele da daju svoj doprinos zajednici.</li>
                </ul>
                <p>Na inicijativu Koalicije za dobročinstvo predsednica Vlade Republike Srbije je, pri svom kabinetu, osnovala Savet za filantropiju, koji je funkcionisao do sredine 2020. godine. Savet je za vreme svog mandata omogućio povećanje neoporezive stope za stipendije i donošenje jedinstvenog uputstva za pravna lica o dobijanju poreskih olakšica povodom doniranja za opšte dobro.</p>
                <p><strong>Izvori</strong></p>
                <ul className="about-us-list">
                    <li>givingbalkans.org</li>
                    <li>catalystbalkans.org</li>
                </ul>
            </div>
        )
    }
}

import React from 'react'

export const AppTitle = ({appTitle}) => {
    return (
        <div className="app-title">
            {
                appTitle.map((d, i) => <h1 key={i}>{d}</h1>)
            }
        </div>
    )
}


import React from 'react';
import logotipi from '../../../../../src/images/logotipi.png';
import { useRouteMatch } from "react-router-dom";

export const Branding = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <p>
                Research Giving Serbia 2020 – Annual Report on the State of Philanthropy is part of the project ‘Framework for Giving in Serbia’ financed by USAID and implemented by the Coalition for Giving led by Ana and Vlade Divac Foundation. Other members of the Coalition are Trag Foundation, Catalyst Balkans, Smart Kolektiv, Serbian Philanthropy Forum, Responsible Business Forum and the Serbian Chamber of Commerce and Industry. The research was carried out by Catalyst Balkans in partnership with Trag Foundation and with additional support of the Charles Stewart Mott Foundation and the Rockefeller Brothers Fund.
                </p>
                <p>
                The content of the report is the sole responsibility of Catalyst Balkans, Trag Foundation and Ana and Vlade Divac Foundation, and it does not necessarily reflect the views of the USAID, the United States Government, the Charles Stewart Mott Foundation and the Rockefeller Brothers Fund.
                </p>   
                <p><img src={logotipi} alt="branding" className="branding" /></p>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <p>
                Istraživanje Srbija daruje 2020 – godišnji izveštaj o dobročinstvu jeste deo Projekta za unapređenje okvira za davanje, koji finansira USAID, a sprovodi Koalicija za dobročinstvo, predvođena Fondacijom „Ana i Vlade Divac“. Ostali članovi Koalicije su Trag fondacija, Catalyst Balkans, Smart kolektiv, Srpski filantropski forum, Forum za odgovorno poslovanje i Privredna komora Srbije. Istraživanje je sproveo Catalyst Balkans u partnerstvu sa Trag fondacijom, uz dodatnu podršku Fondacije „Čarls Stjuart Mot“ i Fonda braće Rokfeler.
                </p>
                <p>
                Sadržaj izveštaja je isključiva odgovornost organizacije Catalyst Balkans, Trag fondacije i Fondacije „Ana i Vlade Divac“, i ne odražava nužno stavove USAID-a, Vlade Sjedinjenih Američkih Država, Fondacije „Čarls Stjuart Mot“ i Fonda braće Rokfeler.
                </p>   
                <p><img src={logotipi} alt="branding" className="branding" /></p>
            </div>
        )
    }
}

import React from 'react';
import { useRouteMatch } from "react-router-dom";
import { Error404 } from '../Error404';

export const ReportNMK = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div>
                <h1>Report North Macedonia</h1>
            </div>
        )
    } else if (params.lang == 'mk') {
        return (
            <div>
                <h1>Izvestaj Severna Makedonija</h1>
            </div>
        )
    } else {
        return <Error404 />
    }
}
import React from 'react';
import {TextBox} from '../../../TextBox';
import logotipi from '../../../../../src/images/logotipi.png'

export const Footnote = ({textData}) => {
    return (
        <>
        <div id="footnote"></div>
        <div className="panel-regular" >
            <TextBox textData={textData} />
            <p><img src={logotipi} alt="branding" className="branding" /></p>
            <div id="intro"></div>
        </div>
        </>
    )
}



import React from 'react';

export const TableSimple = ({ data }) => {
  const getKeys = () => Object.keys(data[0]);

  const getHeader = () => {
    const keys = getKeys();
    return keys.map((key) => (
      <th key={key}>{key.toUpperCase()}</th>
    ));
  };

  const getRowsData = function () {
    var items = data;
    var keys = getKeys();
    return items.map((row, index) => {
      return (
        <tr key={index}>
          <RenderRow key={index} data={row} keys={keys} />
        </tr>
      );
    });
  };

  return (
    <div className="pie-table-box">
      <table>
        <tbody>{getRowsData()}</tbody>
      </table>
    </div>
  );
};

const RenderRow = ({ keys, data }) => {
  return keys.map((key) => {
    return <td key={data[key]}>{data[key]}</td>;
  });
};
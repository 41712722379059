import React, {
    useState,
    useEffect,
  } from 'react';
  import { csv } from 'd3';
  import { keyThemes } from './keyThemes';
  
  export const useData = (csvUrl) => {
    const [data, setData] = useState(null);
  
    const row = (d) => {
      d.Proportion = Math.round(+d.Proportion * 100) / 100;
      d.Proportion = +d.Proportion.toFixed(1)
      return d;
    };
  
    useEffect(() => {
      csv(csvUrl, row).then((data) => {
        setData(data);
      });
    }, []);
    
    return data;
  };
import React, {
    useState,
    useCallback,
    useEffect,
  } from 'react';
  import ReactDOM from 'react-dom';
  import {
    csv,
    arc,
    scaleBand,
    scaleLinear,
    max,
    format,
    scaleOrdinal,
  } from 'd3';
  import { useData } from './useData';
  import { AxisLeft } from './AxisLeft';
  import { Marks } from './Marks';
  import { ColorLegend } from './ColorLegend';
  import { PlotMenu } from './PlotMenu';
  import { formatNumber } from '../../formatNumber';
  import { menuLabels } from '../menuLabels';
  import { menuItemsWidth } from '../menuItemsWidth';

  const width = 960;
  const height = 500;
  const margin = {
    top: 40,
    right: 70,
    bottom: 105,
    left: 328
  };

  
  const xAxisLabelOffset = 50;
  
  //const menuLabels = ['Ukupno', 'COVID-19', 'Ostale svrhe'];
  /*const menuLabels = [
    ['Ukupna', 'davanja'], 
    ['Davanja usmerena na', 'posledice pandemije'], 
    ['Davanja koja nisu', 'povezana sa pandemijom']
  ];*/
  
  const menuItems = ['Total', 'Covid', 'Other'];
  const gID = ['TotalActions', 'CovidOnlyActions', 'OtherOnlyActions']; // xValue, xFirstRectValue, xSecondRectValue

  export const SimplerJIBar = ({csvUrl, lang, strippedRectClass, patternStripe, maskStripe, actions}) => {
    const data = useData(csvUrl);

  /* SVG Height calculation start */
  let numberOfRects = 1;
  if (data) {
    numberOfRects = data.length;
  }
  

  
  const rectHeight = 36;
  const rectPadding = 1.5;
  
  let colorLegendHeight = 42;
  const colorLegendPadding = 40;
  colorLegendHeight += colorLegendPadding
  
  let plotMenuHeight = 22;
  const plotMenuPaddingTop = 60;
  plotMenuHeight += plotMenuPaddingTop
  
  
  let allRectsHeight = rectHeight * numberOfRects * rectPadding;
  
  let svgHeight = allRectsHeight + colorLegendHeight + plotMenuHeight ;
  
 
  /* SVG Height calculation end */
  
    const initialXAttribute =  'TotalActions';
    const [xAttribute, setXAttribute] = useState(
      initialXAttribute
    );
    const xValue = (d) => d[xAttribute];
  
    if (!data) {
      return <pre>Loading...</pre>;
    }
  
    const innerHeight = height - margin.top - margin.bottom;
    const innerWidth = width - margin.left - margin.right;
  
    const xAxisLabel = '';
  
    const yValue = (d) => d.Category;
  
    const xFirstRectValue =
      initialXAttribute == xAttribute
        ? (d) => d.TotalActionsCovid
        : (d) => d.Zero;
  
    const xSecondRectValue =
      initialXAttribute == xAttribute
        ? (d) => d.TotalActionsOther
        : (d) => d.Hundred;
  
    const yScale = scaleBand()
      .domain(data.map(yValue))
      .range([0, allRectsHeight])
      .padding(0.15);
  
    const xScale = scaleLinear()
      .domain([0, max(data, xValue)])
      .range([0, innerWidth]);
  
    return (
      <svg viewBox={`0 0 ${width} ${svgHeight}`} >
        <g
          transform={`translate(${margin.left}, ${margin.top})`}
        >
          <AxisLeft yScale={yScale} />
          <text
            className="axis-label"
            x={innerWidth / 2}
            y={innerHeight + xAxisLabelOffset}
            textAnchor={'middle'}
          >
            {xAxisLabel}
          </text>
          <Marks
            data={data}
            yScale={yScale}
            xScale={xScale}
            xValue={xValue}
            xAttribute={xAttribute}
            yValue={yValue}
            xFirstRectValue={xFirstRectValue}
            xSecondRectValue={xSecondRectValue}
            rectHeight={rectHeight}
            strippedRectClass={strippedRectClass}
            patternStripe={patternStripe}
            maskStripe={maskStripe}
            formatNumber={formatNumber}
            lang={lang}
          />
        </g>
  
        <g
        className="color-legend"
          transform={`translate(${251}, ${
            allRectsHeight + colorLegendPadding
          })`}
        >
          <ColorLegend
            xAttribute={xAttribute}
            lang={lang}
            menuTextClass={gID}
            strippedRectClass={strippedRectClass}
            actions={actions}
          />
        </g>
        <g
          textAnchor="middle"
          transform={`translate(${252} ${
            svgHeight - 40
          })`}
        >
          <PlotMenu
            updateXValue={setXAttribute}
            lang={lang}
            menuLabels={menuLabels}
            xAttribute={xAttribute}
            menuLineClass={menuItems}
            menuTextClass={gID}
            //menuTextID={textID}
            menuItemsWidth={menuItemsWidth}
          />
        </g>
      </svg>
    );
  };
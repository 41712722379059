import React, { useState } from 'react';
import { pie, arc, interpolateRgb, scaleOrdinal } from 'd3';
import { Slice } from './Slice';
import { useData } from './useData';
import { keyThemes } from './keyThemes';
import { ColorLegend } from './ColorLegend';
import { transformData } from './transformData';
import { TableSimple } from './TableSimple';
import { formatNumber } from '../../formatNumber';
import { otherTranslate} from '../otherTranslate';

const height = 370;
const width = 960;

//const innerRadius = 180;
//const outerRadius = height / 2;

const innerRadius = 120;
const outerRadius = 180;



export const PieWithTable = ({csvUrl, lang='en'}) => {
  let data = useData(csvUrl);
  console.log('Izabran jezik je: ' + lang)
  
  const plotVariable = ['#13a9c6', 'rgba(255, 117, 0, 1)', 'rgba(255, 117, 0, 0.8)',  'rgba(255, 117, 0, 0.6)', 'rgba(255, 117, 0, 0.4)', '#74b4c7'];

  const initialProportionAttribute =  'Proportion';
  const [proportionAttribute, setProportionAttribute] = useState(
    initialProportionAttribute
  );
  const proportionValue = (d) => d[proportionAttribute];
  
  if (!data) {
    return <pre>Loading...</pre>;
  }

  const categoryValue = d => d.Theme;
  
  let pieGnerator = pie().sort(null)

  const dataPie = data.filter(d => keyThemes[lang].includes(categoryValue(d)));
  let sumKeyThemes = 0;
  for(let i = 0; i < dataPie.length; i++) {
  	sumKeyThemes += dataPie[i].Proportion;
  }
  const otherProportion = +(100 - sumKeyThemes).toFixed(3);
  dataPie.push({Theme: otherTranslate(lang), Proportion: otherProportion});
  let pieGen = pieGnerator(dataPie.map(d => proportionValue(d)));
  
  pieGen.map((d, i) => {
      d.theme = dataPie[i].Theme;
    })

  const colorScale = scaleOrdinal()
    .domain(keyThemes[lang])
  	.range(plotVariable);
  
  return (
    <>
    <svg viewBox={`0 0 ${width} ${height}`}>
      <g
        transform={`translate(${width * 7 / 10},${outerRadius})`}
      >
        <Slice
          pie={pieGen}
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          colorScale={colorScale}
        />
      </g>
      <g transform={`translate(200,${50})`}>
        	<ColorLegend 
            colorScale={colorScale}
            lang={lang}
            tickSpacing={50} 
  					tickSize={50} 
  					tickTextOffset={10}
            data={dataPie}
            xValue={proportionValue}
            categoryValue={categoryValue}
            proportionValue={proportionValue}
            formatNumber={formatNumber}
          />
      </g>
    </svg>
    <TableSimple data={transformData(data, categoryValue, proportionAttribute, lang)} />
    </>
  );
};
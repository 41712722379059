import React from 'react';
import {Methodology} from './pannels/Methodology';
import {AboutUs} from './pannels/AboutUs';
import {Branding} from './pannels/Branding';
import {Enclosure92} from './pannels/Enclosure92';
import {ResearchMethodology} from './pannels/ResearchMethodology';
import {Summary} from './pannels/Summary';
import {Solidarity} from './pannels/Solidarity';
import {PhilanthropyTime} from './pannels/PhilanthropyTime';
import {Geography} from './pannels/Geography';
import {WhoAreDonors} from './pannels/WhoAreDonors';
import {WhatIsGiven} from './pannels/WhatIsGiven';
import {WhoIsSupported} from './pannels/WhoIsSupported';
import {EndUsers} from './pannels/EndUsers';
import {HowIsGiven} from './pannels/HowIsGiven';
import {Media} from './pannels/Media';
import {Enclosure9} from './pannels/Enclosure9';
import { Navbar } from './Navbar';
import { NavbarEN } from './NavbarEN';
import { useRouteMatch } from "react-router-dom";
import { Error404 } from '../Error404';




export const FullReport = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div>
                <NavbarEN />
                <Solidarity />
                <Methodology />
                <Summary />
                <PhilanthropyTime />
                <Geography />
                <WhoAreDonors />
                <WhatIsGiven />
                <WhoIsSupported />
                <EndUsers />
                <HowIsGiven />
                <Media />

                <Enclosure9 />
                <Enclosure92 />
                <ResearchMethodology />
                <AboutUs />
                <Branding />  
            </div>
        )
    } else if (params.lang == 'rs') {
        return (
            <div>
                <Navbar />
                <Solidarity />
                <Methodology />
                <Summary />
                <PhilanthropyTime />
                <Geography />
                <WhoAreDonors />
                <WhatIsGiven />
                <WhoIsSupported />
                <EndUsers />
                <HowIsGiven />
                <Media />
    
                
                <Enclosure9 />
                <Enclosure92 />
                <ResearchMethodology />
                <AboutUs /> 
                <Branding />       
            </div>
        )
    } else {
        return <Error404 />
    }
}

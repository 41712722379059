export const LegendTotal = ({
    tickSpacing,
    tickSize,
    tickTextOffset,
    //xAttribute,
    labels,
    strippedRectClass,
    actions
  }) => (
    <>
      <g>
        <rect
          className="mark-2"
          width={tickSize}
          height={tickSize}
        />
        <text x={tickTextOffset} dy="1em">
          {actions ? labels[0] : labels[1]}
        </text>
      </g>
      <g transform={`translate(0, ${1 * tickSpacing})`}>
        <rect
          className="mark-2"
          width={tickSize}
          height={tickSize}
        />
        <rect
          className={strippedRectClass}
          width={tickSize}
          height={tickSize}
        />
        <text x={tickTextOffset} dy="1em">
          {labels[2]}
        </text>
      </g>
    </>
  );
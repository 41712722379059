export const formatNumber = (num, lang) => {
    let numStr = num.toString();
    let numStrLen = numStr.length;

    if (numStr[numStrLen - 2] != '.') {
        numStr += '.0'
    }

    if (lang != 'en') {
        numStr = numStr.replace('.', ',')
    }

    return numStr;
}

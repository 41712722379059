import './App.css';
/*import {
  scaleLinear,
  scaleBand,
  min,
  max,
  format,
  timeFormat
} from 'd3'; */

/*import {TextBox} from './components/TextBox';
import {useData} from './components/useData';
import {useTextBoxData} from './components/useTextBoxData';
import {AxisBottom} from './components/AxisBottom';
import {AxisLeft} from './components/AxisLeft';
import {Marks} from './components/Marks';
import {Title} from './components/Title';
import {Table} from './components/Table';
import { Donnors } from './components/panels/Donnors'
import {TableSimple} from './components/tables/TableSimple'
import DonAreasTbl from './components/tables/DonAreasTbl';
import {JIBar} from './components/charts/JIBar/JIBar';
import {PieWithTable} from './components/charts/PieWithTable/PieWithTable';
import {SimplerJIBar} from './components/charts/SimplerJIBar/SimplerJIBar';
import { InfoBoxTable } from './components/charts/InfoBoxTable/InfoBoxTable';

import { AreaChart } from './components/charts/AreaChart/AreaChart';

import { TypeOfDonors } from './components/panels/TypeOfDonors';
import { GivingThemes } from './components/panels/GivingThemes';
import { SimplerJIBarWithTable} from './components/charts/SimplerJIBarWithTable/SimplerJIBarWithTable';

import { WaysTypesEffects } from './components/panels/WaysTypesEffects';*/

import { Link, Route, Switch } from "react-router-dom";
import {Lang} from "./components/Lang";
import {Countries} from "./components/Countries";
import {ChooseReport} from "./components/ChooseReport";
import {ReportView} from "./components/ReportView";
import GBlogo from './images/GBLogo.svg';

//import {QuickFacts} from './components/sections/QuickFacts/QuickFacts';

const width = 960;
const height = 500;

const margin = {
  top: 60,
  right: 30,
  bottom: 80,
  left: 200,
};

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

/*const innerWidth = width - margin.left - margin.right;
const innerHeight = height - margin.top - margin.bottom;

const xAxisLabelOffset = 50;
const yAxisLabelOffset = 55;*/

const Home = (props) => (
  <div>
        <div className="home-container">
            
                <p><img src={GBlogo} /></p>
                <h1 className="year-home">2020</h1>
                <h1>Annual Report on the</h1>
                <h1 className="state-home">State of Philanthropy</h1>
                <Link to={'/country'}>
                  <h2 id="enter-app">Enter</h2>
                </Link>
        </div>
       
  </div>
);

function App() {

  /*const fruit = [
    {'fruit': 'Apple', 'cost': 100},
    {'fruit': 'Orange', 'cost': 50},
    {'fruit': 'Banana', 'cost': 35},
    {'fruit': 'Mango', 'cost': 70},
    {'fruit': 'Pineapple', 'cost': 45},
    {'fruit': 'Papaya', 'cost': 40},
    {'fruit': 'Watermelon', 'cost': 35}
];

const smallerBinds = ['5-10 %', '3-5 %' , '1-3 %', '0-1 %'];

const actions = [
  {lbl: 'Broj akcija u %'},
  {lbl: '5%-10%'},
  {lbl: '3%–5%'},
  {lbl: '1%–3%'},
  {lbl: '0%–1%'}
];*/

/*const themes = [
  {type: 'sezonska davanja', proportion: '5-10 %'},
  {type: 'sport', proportion: '1-3 %'},
  {type: 'kultura', proportion: '1-3 %'},
  {type: 'umetnost', proportion: '1-3 %'},
  {type: 'zastita zivotne sredine', proportion: '1-3 %'},
  {type: 'javna infrastrukutura', proportion: '0-1 %'},
  {type: 'briga o zivotinjama', proportion: '0-1 %'},
  {type: 'urgentna pomoc', proportion: '0-1 %'},
  {type: 'nauka', proportion: '0-1 %'},
  {type: 'istorijsko nasledje', proportion: '0-1 %'},
  {type: 'nezavisni mediji', proportion: '0-1 %'},
  {type: 'socijalno preduzetnistvo', proportion: '0-1 %'},
  {type: 'ekonosmki razvoj', proportion: '0-1 %'},
  {type: 'ostalo', proportion: '0-1 %'},
  {type: 'religijske aktivnosti', proportion: '0-1 %'}
];*/
/* const themes = [
  {type: 'sezonska davanja', proportion: '5-10 %'},
  {type: 'sport', proportion: '1-3 %'},
  {type: 'kultura', proportion: '1-3 %'},
  {type: 'umetnost', proportion: '1-3 %'},
  {type: 'zastita zivotne sredine', proportion: '1-3 %'},
  {type: 'javna infrastrukutura', proportion: '0-1 %'},
  {type: 'briga o zivotinjama', proportion: '0-1 %'},
  {type: 'urgentna pomoc', proportion: '0-1 %'},
  {type: 'nauka', proportion: '0-1 %'},
  {type: 'istorijsko nasledje', proportion: '0-1 %'},
  {type: 'nezavisni mediji', proportion: '0-1 %'},
  {type: 'socijalno preduzetnistvo', proportion: '0-1 %'},
  {type: 'ekonosmki razvoj', proportion: '0-1 %'},
  {type: 'ostalo', proportion: '0-1 %'},
  {type: 'religijske aktivnosti', proportion: '0-1 %'}
];
const csvUrl = 'https://gist.githubusercontent.com/beauMarko/e6babd8463261cd99843d7dca522dc3b/raw/c6f79bfc00aeae43151118367081f4bcdb9ebaa0/donationActivityLevel';
const csvUrlF2 = 'https://gist.githubusercontent.com/kragovic/2d070e0d05d88339061fdfe86e7fe242/raw/f7bc3d2166a203acd8aa3b35025d2fda45557c1d/file2Text.csv';


  const data = useData();
  const textData = useTextBoxData(csvUrl);
  const textDataF2 = useTextBoxData(csvUrlF2);

  if (!(data && textData && textDataF2)) {
    return <pre>Loading...</pre>;
  }

  const xValue = (d) => d.month;
  const xAxisLabel = 'Month';

  const yValue = (d) => d.action;
  const yAxisLabel = 'Actions';

  const xAxisTickFormat = timeFormat('%Y');
  const numberFormat = (d) =>
    format('.2s')(d)
  		.replace(/^/, '$ ')
  		.replace(' ', '');

  const xScale = scaleBand()
    .domain(data.map(xValue))
    .range([0, innerWidth]);

  const yScale = scaleLinear()
  	.domain([min(data, yValue)*0.9, max(data, yValue)])
    .range([innerHeight, 0])
 		.nice(); */

  
  return (
    <div className="App">
      <Switch>
        
        {/* <Route path="/category"><Category /></Route>
        <Route path="/products"><Products /></Route> */}
        <Route path="/country/:name/:lang/:type"><ReportView /></Route>
        <Route path="/country/:name/:lang"><ChooseReport /></Route>
        <Route path="/country/:name"><Lang /></Route>
        <Route path={`/country`}><Countries /></Route>
        <Route exact path="/"><Home /></Route>
      </Switch>
      
      {/* <QuickFacts /> */}
      
    </div>
  );
}

export default App;

import React from 'react'
import {Title} from './../../../Title';
import {SimplerJIBar} from '../../../charts/SimplerJIBar/SimplerJIBar'
import {TextBox} from '../../../TextBox';
import { useRouteMatch } from "react-router-dom";

export const Recipients = ({textData}) => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <Title title='Donation recipients' />
                <h3 className="panel-subtitle">Number of recorded instances</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/b8af23979c0cac2a2c040b3a80b550e5/raw/7cb1be763d57df17cbc90e5a02d14c5ab94a1106/recipientsActionsENG.csv'
                    lang={params.lang} 
                    strippedRectClass='mark-sji-bar3'
                    patternStripe='pattern-stripe-4'
                    maskStripe='mask-stripe-4' 
                    actions='true'
                />
                <h3 className="panel-subtitle">Recorded value of donations</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/734064274b8558f43b7d6807a8c8f03f/raw/8ed71ce714014e222e56be98e2667062ab8788c1/recipientsMoneyENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar4'
                    patternStripe='pattern-stripe-5'
                    maskStripe='mask-stripe-5' 
                />
                  
                <TextBox textData={textData} />
                <div id="end-users"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <Title title='Primaoci donacija' />
                <h3 className="panel-subtitle">Broj zabeleženih akcija</h3>
                <SimplerJIBar 
                csvUrl='https://gist.githubusercontent.com/kragovic/c1885597e4808fa3c646241e61e24af5/raw/949004a353eda83b1c50a76d8b29716ce86d00ef/recipientsActions.csv'
                lang={params.lang} 
                strippedRectClass='mark-sji-bar3'
                patternStripe='pattern-stripe-4'
                maskStripe='mask-stripe-4' 
                actions='true'
                />
                <h3 className="panel-subtitle">Poznata darovana suma</h3>
                <SimplerJIBar 
                csvUrl='https://gist.githubusercontent.com/kragovic/1cc5b036f6c4c9d8a3f07574e3162582/raw/14d6fb55caa58b0c2efbc7ee0cdc9d3df6e66cc6/recipientsMoney.csv'
                lang={params.lang} 
                strippedRectClass='mark-sji-bar4'
                patternStripe='pattern-stripe-5'
                maskStripe='mask-stripe-5' 
                />
                
                <TextBox textData={textData} />
                <div id="end-users"></div>
            </div>
        )
    }
}

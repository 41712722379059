/*export const keyThemes = [
    'COVID-19',
    'Zdravstvo',
    'Podrška marginalizovanim grupama',
    'Smanjenje siromaštva',
    'Obrazovanje'
];*/
export const keyThemes = {
    'rs': [
        'COVID-19',
        'Zdravstvo',
        'Podrška marginalizovanim grupama',
        'Smanjenje siromaštva',
        'Obrazovanje'
    ],
    'en': [
        'COVID-19',
        'Healthcare',
        'Support to marginalized groups',
        'Poverty relief',
        'Education'
    ]
}
import React, {
    useState,
    useEffect,
  } from 'react';
  import { csv } from 'd3';
  
  export const useData = (csvUrl) => {
    const [data, setData] = useState(null);
  
    const row = (d) => {
      d.Total = +d.Total;
      d.CovidTotal = +d.CovidTotal;
      d.Covid = +d.Covid;
      d.Other = +d.Other;
      d.Month = new Date(d.Month + 2020);
      d.Helper1 = 0;
      d.Helper2 = 0;
      return d;
    };
  
    useEffect(() => {
      csv(csvUrl, row).then(setData);
    }, []);
    return data;
  };
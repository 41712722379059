export const PlotMenu = ({
    updateXValue,
    lang,
    menuLabels,
    menuItemsWidth = [84, 226, 270],
    xAttribute,
    menuLineClass,
    menuTextClass,
    //menuTextID
  }) =>
    menuLineClass.map((menuItem, i) => {
      const func1 = (x) =>
        updateXValue(x.target.className.baseVal);
      //const func2 = (x) => updateX2Value(x.target.id);
      const func2 = (x) => updateXValue(x.target.id);
      return (
        <g
          className="plot-menu"
          /*transform={`translate(${130 * i + i * Math.sqrt(i) * 90})`}*/
          transform={`translate(${225 * i + Math.sqrt(i) * i*45})`}
          key={i}
        >
          <text
            className={menuTextClass[i]}
            fontWeight={xAttribute == menuTextClass[i] ? 700 : 300}
           // id={menuTextID[i]}
            onClick={(event) => {
              func2(event);
              func1(event);
            }}
          >
            {menuLabels[lang][i].map((labelArr, index) => 
              <tspan x='0' y={index * 20} className={menuTextClass[i]} key={index}>
                {labelArr}
              </tspan>
            )}
          </text>
          <line
            //opacity={xAttribute == menuTextClass[i] & xAttribute != menuTextClass[0] ? 0 : 1}
            opacity={xAttribute == menuTextClass[i] ? 1 : 0}
            className={menuLineClass[i]}
            x1={-menuItemsWidth[lang][i] / 2}
            x2={menuItemsWidth[lang][i] / 2}
            y1="30"
            y2="30"
          />
        </g>
      );
    });
  
import React from 'react';
import { useRouteMatch } from "react-router-dom";

export const Solidarity = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular" id="solidarity">
                <h3 className="panel-title">Solidarity during the pandemic</h3>
                <p>The year 2020 was an unusual one and cannot easily be compared with other years. The COVID-19 pandemic resulted in a State of Emergency and completely altered our way of life.</p>
                <p>The State of Emergency also inspired different ways of caring, expressing empathy and giving. Previous trends came to an end, the image of philanthropy changed, not drastically, but enough to respond to new needs. Although not all changes were positive, it seems that both donors and recipients generally were rapid and efficient in understanding and responding to the new situation.</p>
                <p>As a consequence, the report before you differs from previous ones. We felt that describing the trends and comparing them with previous years would provide an inaccurate picture of giving. Instead, we have chosen to focus our report on the donations related and unrelated to the pandemic and highlight the shifts in giving brought about by the pandemic.</p>
                <p>The content is not the only thing that has changed. For the first time, we present you with a new format – an interactive web report. The new normal, that shifted all events to Zoom and Teams, was not the only reason we chose to publish an online version of our report. This format also gives you the opportunity to choose the type of data that interests you and obtain relevant information more easily. You also have the possibility to download the report in PDF form just as before.</p>
                <p>None of this would be possible without the support of our partners from the Coalition for Giving and our donors – USAID, the Charles Stewart Mott Foundation and the Rockefeller Brothers Fund. We thank them for their ongoing support.</p>
                <p>In 2020, our data collection and analysis team found it more challenging than usual to monitor instances of giving and communicate with socially responsible companies and non-profit organizations, in part due to the sheer number of instances. Companies and non-profit organizations selflessly came to the aid of those in need and demonstrated solidarity when no one had it easy – from the corporate sector that bore the largest burden in purchasing medical equipment, to non-profit organizations that went to work with people even in the most difficult conditions, and citizens who donated and volunteered to help their neighbors and communities.</p>
                <p>The data we collected speaks to the quantity and quality of philanthropy, but also points to the need for cooperation between the state, corporate sector and non-profit organizations, as well as to the need to approach philanthropy in a smart and strategic way so as to the make most effective use of every form of help.</p>
                <p>We hope that you find the information useful, and that it will help build an open philanthropic community that is ready to offer both emergency assistance and long-term, sustainable solutions to the social challenges of the 21<sup>st</sup> century.</p>
                <p>We continue to do good together.</p>
                <p>Catalyst Balkans and the Trag Foundation</p>
                <div id="methodology"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular" id="solidarity">
                <h3 className="panel-title">Solidarnost u doba pandemije</h3>
                <p>Godina za nama, 2020. neosporno je bila posebna i teško porediva sa prethodnim godinama. Pandemija Kovida-19 donela je sa sobom vanredno stanje i potpuno izmenjen način života – ništa više nije isto.</p>
                <p>Vanredno stanje je inspirisalo i drugačije načine da brinemo, pokažemo empatiju i darujemo. Trendovi ustaljeni poslednjih nekoliko godina su prekinuti, slika dobročinstva se promenila, ne drastično, ali dovoljno da odgovori na nove i drugačije potrebe. Iako sve promene nisu bile pozitivne, gledano u celini, čini se da su i darodavci i primaoci bili brzi i efikasni u sagledavanju i odgovaranju na situaciju.</p>
                <p>Stoga je i izveštaj koji je pred vama drugačiji nego prethodnih godina. Smatrali smo da bi praćenje trendova i poređenje sa prethodnim godinama dalo pogrešnu sliku davanja. Umesto toga, usmerili smo izveštaj na prikaz odnosa davanja povezanih i nepovezanih sa pandemijom i ukazivanje na promene u davanju koje je pandemija izazvala.</p>
                <p>Sadržaj nije jedino što je drugačije: po prvi put predstavljamo novi format – interaktivni veb izveštaj. Nova normalnost koja je sve događaje preselila na Zum i Tims nije jedini razlog zašto smo se opredelili za online verziju. Ovaj format vam daje mogućnost da izaberete vrstu podataka koja vas interesuje i lakše dođete do informacija koje su vama relevantne. Ostavili smo vam mogućnost da izveštaj, ukoliko želite, preuzmete u pdf formatu kao i ranijih godina.</p>
                <p>Ništa od ovoga ne bismo uspeli bez podrške naših partnera iz Koalicije za dobročinstvo i donatora – USAID-a, Fondacije Čarls Stjuart Mot i Fonda braće Rokfeler, kojima se zahvaljujemo na kontinuiranoj podršci.</p>
                <p>Naš tim koji radi na prikupljanju i analizi podataka je ove godine imao posebno težak posao, da bi pratio sve akcije i blagovremeno komunicirao sa sa društveno odgovornim kompanijama i neprofitnim organizacijama. Te kompanije i neprofitne organizacije su nesebično priskočile u pomoć i pokazale solidarnost u vreme kada nikome od nas nije bilo lako. Od poslovnog sektora koji je u velikoj meri poneo teret nabavljanja potrebne medicinske opreme, preko neprofitnih organizacija koje su i u najtežim uslovima izlazile na teren, do građana, koji su i darivali i volontirali kako bi pomogli svojim komšijama i zajednicama.</p>
                <p>Podaci koje smo prikupili govore i o kvantitetu i o kvalitetu dobročinstva, ali i ukazuju na potrebu saradnje države, poslovnog sektora i neprofitnih organizacija, kao i da je neophodno da dobročinstvu pristupamo na pametan, strateški način, kako bismo što bolje iskoristili svaki vid pomoći.</p>
                <p>Nadamo se da će vam ovi podaci biti od koristi i da će nam svima služiti kao osnova za građenje otvorene filantropske zajednice spremne da pruži i urgentnu pomoć ali i ponudi dugoročna i održiva rešenja za društvene izazove 21. veka.</p>
                <p>Nastavljamo da stvaramo dobro zajedno.</p>
                <p>Fondacija Katalist i Trag fondacija</p>
                <div id="methodology"></div>
            </div>
        )
    }
    
}

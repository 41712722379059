export const Marks = ({
    data,
    yScale,
    xScale,
    xValue,
    xAttribute,
    yValue,
    tooltipFormat,
    xFirstRectValue,
    xSecondRectValue,
    rectHeight,
    formatNumber,
    strippedRectClass="mark-sji-bar",
    patternStripe='pattern-stripe-2',
    maskStripe='mask-stripe-2',
    lang
  }) => {
    let patternStripeURL = '#' + patternStripe;
    return data.map((d, i) => (
      <g
        className={xAttribute}
        transform={`translate(0, ${-rectHeight / 2})`}
        key={'a' + i}
      >
        {/* Background color rectangle */}
        <rect
          className="mark56"
          key={'b' + i}
          x={0}
          y={yScale(yValue(d))}
          width={xScale(
            (xValue(d) * xFirstRectValue(d)) / 100
          )}
          height={rectHeight}
          fill="#fa7500"
        />
        <defs>
          <pattern
            id={patternStripe}
            width="6"
            height="4"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <rect
              width="3.5"
              height="4"
              transform="translate(0,0)"
              fill="white"
            ></rect>
          </pattern>
          <mask id={maskStripe}>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill={`url(${patternStripeURL})`}
             
            />
          </mask>
        </defs>
        <rect
    className={strippedRectClass}
          key={'c' + i}
          x={0}
          y={yScale(yValue(d))}
          width={xScale(
            (xValue(d) * xFirstRectValue(d)) / 100
          )}
          height={rectHeight}
        >
          <title>{formatNumber(xFirstRectValue(d), lang) + '%'}</title>
        </rect>
        <rect
          className="mark-2-sji-bar"
          key={'d' + i}
          x={xScale((xValue(d) * xFirstRectValue(d)) / 100)}
          y={yScale(yValue(d))}
          width={xScale(
            (xValue(d) * xSecondRectValue(d)) / 100
          )}
          height={rectHeight}
        >
          <title>{formatNumber(xSecondRectValue(d), lang) + '%'}</title>
        </rect>
        <g key={'e' + i}>
          <text
            className='bar-value'
            key={i}
            style={{ textAnchor: 'end' }}
            x={xScale(xValue(d)) + 3}
            y={yScale(yValue(d)) + rectHeight / 2}
            dy=".32em"
            style={{ textAnchor: 'start' }}
          >
            {`${formatNumber(xValue(d), lang)}`}
          </text>
        </g>
      </g>
    ));
  };  
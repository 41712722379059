import React from 'react';
import {Accordion} from '../../../Accordion';
import {SimplerJIBar} from '../../../charts/SimplerJIBar/SimplerJIBar';
import { useRouteMatch } from "react-router-dom";

export const WhoAreDonors = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">3</span>Who are the donors??</h2>
                <p>The State of Emergency in Serbia and restrictions on work and movement on the one hand, and different need (e.g., greater cooperation with institutions and larger sums for healthcare devices and materials) on the other, produced a change in a long-standing trend.</p>
                <p>In terms of overall giving, in 2020, donors from the corporate sector engaged in more instances of giving and gave more money than individuals donated through mass giving. Although it may have been somewhat easier for the corporate sector to organize donations in the emergency conditions, there is no doubt that it provided a particularly swift and significantly larger amount of assistance to Serbian citizens than in previous years. If we observe the sums that we recorded and were able to verify, the corporate sector donated slightly more than EUR 22.1 million in 2020, compared to the EUR 18.8 million that all donors combined donated in 2019. Given that this figure only reflects the 2020 data we were able to obtain, the total for 2020 is certainly significantly larger.</p>
                <p>Donations from individuals through forms of mass giving (recorded and verified at EUR 15.4 million) approached the total sum donated by all types of donors combined in 2019. The total recorded amount donated by individuals through mass giving, despite all the obstacles, was more than twice as large as in the previous year.</p>
                <p>A different picture emerges if we examine separately the instances that were related to the pandemic and those that were not. Individuals involved in mass giving provided the largest share (37.4%) of instances unrelated to the pandemic and donated the largest total recorded amount (59%). Individuals thus expressed particular concern for the needs and aspects of life not addressed in the pandemic response.</p>
                <p>Regardless of the changed circumstances, the key types of donors remained the following: individuals through mass giving, the corporate sector and individuals. These three groups organized 86.3% of all instances and they donated 90.4% of the total recorded sum. We offer more details about these donors in the paragraphs that follow.</p>
                <h3 className="panel-subtitle">Number of recorded instances</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/cc7988ac85a8981d7fb348fff9c3c2cb/raw/09b595803219b6b64a814e0f4f527dd912a0596f/donorsENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-1' 
                    patternStripe='pattern-stripe-sr-1'
                    maskStripe='mask-stripe-sr-1'
                    actions='true'
                />
                <h3 className="panel-subtitle">Recorded value of donations</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/e75e06c74de453f17dfc4dea4e53bd50/raw/18a1d9232edbf0acc972742821af8421693a6eae/donorAmountENG.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-2' 
                    patternStripe='pattern-stripe-sr-2'
                    maskStripe='mask-stripe-sr-2'
                />
                <h3 className="example-subtitle">Corporate sector donations</h3>
                <p>In 2020, the corporate sector directed approximately 45% of its instances of giving to institutions. This figure did not differ much when compared with previous years. The only more notable changes were increased instances of support for local and national governments (which increased from 8.9% in 2019 to 13.6%) and reduced support to non-profit organizations (though only in their donations related to COVID-19). These changes can be attributed to the role of the public sector in situations involving emergency measures.</p>
                <p>One indication of the impact of the crisis on trends in corporate sector giving was the fact that people with disabilities were no longer in the group of beneficiaries most frequently targeted by corporate donors. People at risk of poverty took their place in the ranking of top beneficiaries.</p>
                <p>It is also noteworthy that the pandemic did not significantly alter the corporate sector’s choice of key themes: healthcare (in 2020 this includes corporate donations to address effects of the pandemic), followed by education and support to marginalized groups. However, the impact of the pandemic was evident in the redistribution of the number of instances aimed at specific themes – education, which had traditionally been in the first place, fell to the second place, while healthcare understandably claimed first place.</p>
                <Accordion  
                    csvUrl='https://gist.githubusercontent.com/kragovic/03bf5d18278519a7de9a72bc4a5205c2/raw/cdab946dd1e607fbbb6c14368b196c0b9a65089b/businessGivingExamplesENG.csv'
                />
                <h3 className="example-subtitle">Individual donations through mass giving</h3>
                <p>In previous years, donations generated through mass giving (e.g., campaigns, calls, and events) has generally been directed to individuals and families, often for medical treatment. Both our experience and polls suggest that this donor group feels this is the safest way to assist people in need.</p>
                <p>The year 2020 saw an increase in mass giving to non-profit organizations, which, as a result, replaced individuals and families to become the largest recipient of mass giving forms of support. Most of this giving was for purposes unrelated to the pandemic. As their support for non-profit organizations also rose by 6.7% in 2019, we can now speak of this donor group’s interest in working through non-profit organizations as a multi-year phenomenon. It is nevertheless important to avoid reaching the tempting conclusion that support to the non-profit sector is broadly on the rise because much of the support in 2020 was provided to large, well-established organizations rather than being spread across many organizations. For example, EUR 18.9 million of the total of EUR 21.5 million donated by all donors was given to the ‘Budi Human – Aleksandar Šapić’ humanitarian foundation, NALED and UNICEF.</p>
                <p>The list of final beneficiaries of individual donations through mass giving was affected by the COVID-19 pandemic. This donor type focused particular attention on people with disabilities, probably because their specific needs were not addressed in the pandemic-response measures. In addition, 2020 was the first time this donor group directed a large share of its instances of giving to local communities for activities benefiting the entire community and provided less support to people at risk of poverty. Mass giving also singled out the elderly population as an important beneficiary group, particularly in those instances related to the pandemic, perhaps in response to the greater media attention given to the elderly.</p>
                <p>The themes for which individuals engaged in mass giving remained the same - healthcare, support to marginalized groups and poverty relief - albeit with a decline in instances in some cases due to the attention given to the COVID-19 response.</p>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/eb61f90a6d86a5eebc0273486ce5ffa0/raw/e772e18d0755c00818f572ea2ce267cb8c6f55c5/massIndividualExamplesENG.csv'
                />
                <h3 className="example-subtitle">Donations by individuals</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/bd3585ffab92c314bc7ccd59dc21252e/raw/332563d15640d0bd9fa1adfac2ae891ace77a4d5/individualExamplesENG.csv'
                />
                <h3 className="example-subtitle">Donations from diaspora</h3>
                <p>The Serbian diaspora continued to provide significant donations in 2020. Donors from the diaspora contributed a 16% share of the total number of philanthropic instances.</p>
                <Accordion
                    csvUrl='https://gist.githubusercontent.com/kragovic/9562bb8498a4e5b9c3ed6c9a8a9cd587/raw/73d6431879af8eefa48791797f16fbc6a80d3c40/diasportExamplesENG.csv'
                />
                <div id="themes"></div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">3</span>Ko su darodavci?</h2>
                <p>Vanredno stanje u Republici Srbiji i ograničenja uslova rada i kretanja sa jedne strane, te veće potrebe za specifičnim donacijama (saradnja sa institucijama i veliki iznosi potrebni za uređaje i materijale za zdravstvenu zaštitu) sa druge strane, uslovili su promenu višegodišnjeg trenda.</p>
                <p>Kada posmatramo ukupno davanje, ove godine su darodavci iz poslovnog sektora učestvovali i sa više akcija i sa više novca nego građani kroz masovna davanja. Iako je poslovnom sektoru bilo lakše nego drugim darodavcima da se organizuje u vanrednim uslovima, to ne umanjuje značaj činjenice da je on brzo i u znatno većim iznosima nego prethodnih godina pomogao građanima i građankama Srbije. Ako uporedimo sume koje smo zabeležili i bili u mogućnosti da proverimo, poslovni sektor u 2020. godini darovao je nešto više od 22,1 milion evra, naspram ukupno 18,8 miliona evra koje su darovali svi darodavci zajedno u 2019. godini. Imajući u vidu da su ovo samo podaci do kojih smo uspeli da dođemo, velika je verovatnoća da je suma i znatno veća.</p>
                <p>Građani su se kroz masovna davanja u 2020. godini (zabeleženih i proverenih 15,4 miliona evra) i sami približili ukupnoj sumi koju su darovali svi darodavci u 2019. godini. Ukupna zabeležena količina davanja građana u grupnim akcijama, bez obzira na sve prepreke, više je nego dva puta veća u odnosu na prethodnu godinu.</p>
                <p>Međutim, ako posmatramo odvojeno akcije koje jesu i koje nisu povezane sa pandemijom, dobijamo nešto drugačiju sliku. Građani su u akcijama koje nisu vezane za pandemiju učestvovali u najvećem procentu (37,4%) i darovali najveću količinu zabeleženih sredstava (59%). Građani su time posebno pokazali brigu za ostale, donekle zaboravljene potrebe i aspekte života.</p>
                <p>Bez obzira na promenu okolnosti, ključni tipovi darodavaca su i dalje: građani kroz masovna davanja, poslovni sektor i pojedinci. Ove tri grupe su organizovale 86,3% svih akcija darivanja, odnosno donirale su 90,4% ukupnih zabeleženih sredstava. Zato u nastavku ovog poglavlja dajemo detaljnije napomene o ovim darodavcima.</p>
                <h3 className="panel-subtitle">Broj zabeleženih akcija</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/1c1b6d3b2b8bff7896159a1df794cd38/raw/499047fac66ffbd4789e8d16fbbc208d6ca01daa/donors.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-1' 
                    patternStripe='pattern-stripe-sr-1'
                    maskStripe='mask-stripe-sr-1'
                    actions='true'
                />
                <h3 className="panel-subtitle">Poznata darovana suma</h3>
                <SimplerJIBar 
                    csvUrl='https://gist.githubusercontent.com/kragovic/3ceec9abff9c26e987c9c80e958e42fd/raw/84c11589e399c234dda56c2735a4857d5bc1e30a/donorAmount.csv'
                    lang={params.lang}
                    strippedRectClass='mark-sji-bar-sr-2' 
                    patternStripe='pattern-stripe-sr-2'
                    maskStripe='mask-stripe-sr-2'
                />
                <h3 className="example-subtitle">Davanja poslovnog sektora</h3>
                <p>Poslovni sektor je i u 2020. godini oko 45% svojih akcija davanja usmerio ka institucijama, bez velikih odstupanja i u odnosu na pandemiju Kovida-19 i u odnosu na prethodne godine. Jedina primetnija pomeranja su veća podrška lokalnim i republičkim organima vlasti (sa 8,9% u 2019. godini na 13,6%) i manja podrška neprofitnim organizacijama (samo u delu davanja koja su vezana za Kovid-19), što je očekivano obzirom na ingerencije javnog sektora u sprovođenju epidemioloških mera.</p>
                <p>Jedan od pokazatelja uticaja krizne situacije na dugoročne trendove darivanja poslovnog sektora jeste „ispadanje” osoba sa invaliditetom iz grupe najčešće ciljanih korisnika, usled vrlo malog broja akcija za posledice pandemije. Umesto njih, u grupu najviše podržanih korisničkih grupa ušle su osobe u riziku od siromaštva.</p>
                <p>Sa druge strane, pandemija Kovida-19 nije bitno poremetila skupinu ključnih tema u koje je poslovni sektor ulagao – pre svega u zdravstvenu zaštitu (ove godine u kombinaciji sa davanjem za posledice pandemije), zatim obrazovanje i konačno podršku marginalizovanim grupama. Uticaj pandemije se, međutim, vidi u preraspodeli broja akcija usmerenih na specifične teme – obrazovanje, koje je tradicionalno na prvom mestu, palo je na drugo mesto, dok je zdravstvo, razumljivo, zauzelo prvo mesto.</p>
                <Accordion  
                    csvUrl='https://gist.githubusercontent.com/kragovic/a61f3ed996a33fc14edbc0e53838a51f/raw/7a35a7c3137f5b0d899c5db910d8633a037926bc/businessGivingExamples.csv'
                />
                <h3 className="example-subtitle">Davanja građana kroz masovne akcije</h3>
                <p>U prethodnim godinama građani su se najviše usmeravali na pojedince i porodice kroz kampanje, apele, organizovanje događaja, često za lečenje. Najsigurnije su se osećali kada novac ide direktno onome kome je potreban.</p>
                <p>U 2020. godini građani su povećali davanja neprofitnim organizacijama, koje su tako postale najveći primalac njihove podrške umesto pojedinaca i porodica. Najviše su im darivali kroz akcije koje nisu povezane sa pandemijom. Ako se podsetimo da je podrška neprofitnim organizacijama u 2019. godini porasla za 6,7%, sada možemo govoriti o višegodišnjem trendu. Sa druge strane, važno je izbeći zavodljiv zaključak da podrška neprofitnom sektoru generalno raste, jer je ona centralizovana i usmerena na velike, skoro institucionalizovane organizacije, pa je tako 18,9 miliona evra od ukupnih 21,5 miliona dato humanitarnoj fondaciji „Budi human – Aleksandar Šapić”, NALED-u i UNICEF-u.</p>
                <p>Krajnji korisnici davanja građana kroz masovne akcije su primetnije izmenjeni pod uticajem pandemije Kovida-19. Građani su se posebno usmerili na osobe sa invaliditetom, verovatno odgovarajući na potrebe koje su bile zanemarene tokom sprovođenja epidemioloških mera. Takođe su, po prvi put, veći procenat akcija usmerili na populaciju lokalnih zajednica, a nešto manju podršku pružili osobama u riziku od siromaštva. Građani su izdvojili stariju populaciju kao važnu korisničku grupu, posebno kada se radi o akcijama usmerenim na posledice pandemije. To je odgovor na povećanu pažnju koja je starijima bila posvećena u medijima.</p>
                <p>Oblasti za koje građani daju su ostale iste – zdravlje, podrška marginalizovanim grupama i smanjenje siromaštva, uz očekivana umanjenja za sve oblasti.</p>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/aad54cef11fcb4cea5cd4c459d7985db/raw/2f035423d42fd96f278c87fbbb8ac548e7fdf9fe/massIndividualExamples.csv'
                />
                <h3 className="example-subtitle">Davanja pojedinaca</h3>
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/90ebbbdfbb755c2aa384aa3eeeadfe90/raw/4782760e1a240728e05cf575dcbd74838f71fbc9/individualExamples.csv'
                />
                <h3 className="example-subtitle">Davanja dijaspore</h3>
                <p>I ove godine su značajne donacije došle od dijaspore. Darodavci iz dijaspore su učestvovali sa 16% u ukupnom broju akcija dobročinstva.</p>
                <Accordion
                    csvUrl='https://gist.githubusercontent.com/kragovic/02cdc389f5113818490ccef19df5cbbb/raw/e04641f9803c891230cce363f4c0d380750ed143/diasportExamples.csv'
                />
                <div id="themes"></div>
            </div>
        )
    }
    
}

export const SidebarData = [
    {
        title: 'Solidarnost u doba pandemije',
        path: '#root',
        cName: 'nav-text'
    },
    
    {
        title: 'Metodologija',
        path: '#methodology',
        cName: 'nav-text'
    },

    {
        title: 'Za lakše razumevanje izveštaja',
        path: '#to-understand-report',
        cName: 'nav-text'
        
    },  

    {
        title: 'Rezime – dobročinstvo u 2020. godini',
        path: '#summary',
        cName: 'nav-text'
    },

    {
        title: '1 Dobročinstvo tokom godine',
        path: '#philanthropy2020',
        cName: 'nav-text'
    },
 
   {
        title: '2 Geografski pregled davanja',
        path: '#geography',
        cName: 'nav-text'
    },

    {
        title: '3 Ko su darodavci?',
        path: '#donors',
        cName: 'nav-text'
    },

    {
        title: '4  Za šta se daruje u Srbiji?',
        path: '#themes',
        cName: 'nav-text'
    },

    {
        title: '5 Koga podržavaju građani Srbije?',
        path: '#recipients',
        cName: 'nav-text'
    },

    {
        title: '6 Ko su krajnji korisnici donacija?',
        path: '#users',
        cName: 'nav-text'
    },

    {
        title: '7 Kako se daruje u Srbiji?',
        path: '#use',
        cName: 'nav-text'
    },

    {
        title: '8 Medijska pokrivenost',
        path: '#medias',
        cName: 'nav-text'
    },

    {
        title: '9 Prilozi',
        path: '#end',
        cName: 'nav-text'
    }
];
import React from 'react';
import { csv } from 'd3';





export const TextBox = ({textData}) => {
    
    return (
        <div className="text-box">
            {/*<h4>{textData.title}</h4>*/}
            {       
                textData.text.map((paragraph, i) => <p key={i} >{paragraph}</p>)
            }
        </div>
    )
}

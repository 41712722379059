const menuItems = ['Total', 'Covid', 'Other'];
const menuItems2 = ['CovidTotal', 'Helper1', 'Helper2'];

export const PlotMenu = ({ 
  updateYValue, 
  updateY2Value, 
  yAttribute, 
  menuLabels, 
  menuItemsWidth = [84, 226, 270],
  menuTextClass,
  lang
 }) =>
  menuItems.map((menuItem, i) => {
    //const func1 = (x) => updateYValue(x.target.id);
    const func1 = (x) =>
        updateYValue(x.target.className.baseVal);
    /* const func2 = (x) =>
      updateY2Value(x.target.className.baseVal); */
      const func2 = (x) => updateY2Value(x.target.id);

    return (
      <g
        className="plot-menu"
        /* transform={`translate(${130 * i + i * Math.sqrt(i) * 90})`} */
        transform={`translate(${225 * i + Math.sqrt(i) * i*45})`}
        key={i}
      >
        <text
          className={menuItems2[i]}
          //id={menuItems[i]}
          onClick={(event) => {  
            func1(event);   
            func2(event);
            
          }}
          fontWeight={yAttribute == menuTextClass[i] ? 700 : 300}
          dx={0}
        >
          {/* {menuLabels[i]} */}
          {menuLabels[lang][i].map((labelArr, index) => 
          	<tspan id={menuItems2[i]} x='0' y={index * 20} className={menuTextClass[i]} key={'a' + index}>
              {labelArr}
            </tspan>
          )}
        </text>
        <line
          //opacity={yAttribute == menuItems[i] & yAttribute != 'Total' ? 0 : 1}
          opacity={yAttribute == menuTextClass[i] ? 1 : 0}
          className={menuItems[i]}
          x1={-menuItemsWidth[lang][i] / 2}
          x2={menuItemsWidth[lang][i] / 2}
          y1="30"
          y2="30"         
          
        />
      </g>
    );
  });

  /* 
  export const PlotMenu = ({
    updateXValue,
    //updateX2Value,
    menuLabels,
    menuItemsWidth = [84, 226, 270],
    xAttribute,
    menuLineClass,
    menuTextClass,
    //menuTextID
  }) =>
    menuLineClass.map((menuItem, i) => {
      const func1 = (x) =>
        updateXValue(x.target.className.baseVal);
      //const func2 = (x) => updateX2Value(x.target.id);
      const func2 = (x) => updateXValue(x.target.id);
      return (
        <g
          className="plot-menu"
          transform={`translate(${130 * i + i * Math.sqrt(i) * 90})`}
          key={i}
        >
          <text
            className={menuTextClass[i]}
           // id={menuTextID[i]}
            onClick={(event) => {
              func2(event);
              func1(event);
            }}
            fontWeight={xAttribute == menuTextClass[i] ? 700 : 300}
          >
            {menuLabels[i].map((labelArr, index) => 
          	<tspan x='0' y={index * 20} className={menuTextClass[i]} key={'a' + index}>
              {labelArr}
            </tspan>
          )}
          </text>
          <line
            //opacity={xAttribute == menuTextClass[i] & xAttribute != menuTextClass[0] ? 0 : 1}
            opacity={xAttribute == menuTextClass[i] ? 1 : 0}
            className={menuLineClass[i]}
            x1={-menuItemsWidth[i] / 2}
            x2={menuItemsWidth[i] / 2}
            y1="30"
            y2="30"
          />
        </g>
      );
    });
  
  
  */
import { LegendTotal } from './LegendTotal';
import { LegendCovid } from './LegendCovid';
import { LegendOther } from './LegendOther';
import { legendLabels } from '../legendLabels';

export const ColorLegend = ({
  tickSpacing = 20,
  tickSize = 20,
  tickTextOffset = 30,
  xAttribute,
  menuTextClass,
  strippedRectClass,
  lang
}) => {
  
  if (xAttribute == menuTextClass[0]) {
  
    return (
      <LegendTotal
        xAttribute={xAttribute}
        labels={legendLabels[lang]}
        tickSpacing={tickSpacing}
        tickSize={tickSize = 20}
        tickTextOffset={tickTextOffset}
        strippedRectClass={strippedRectClass}
      />
    );
  } else if (xAttribute == menuTextClass[1]) {
    return (
      <LegendCovid
        xAttribute={xAttribute}
        labels={legendLabels[lang]}
        tickSpacing={tickSpacing}
        tickSize={tickSize = 20}
        tickTextOffset={tickTextOffset}
      />
    );
  } else {
    return (
      <LegendOther
        xAttribute={xAttribute}
        labels={legendLabels[lang]}
        tickSpacing={tickSpacing}
        tickSize={tickSize = 20}
        tickTextOffset={tickTextOffset}
      />
    );
  }
};

import React from 'react'
import {Accordion} from '../../../Accordion';
import {PieWithTable} from '../../../charts/PieWithTable/PieWithTable'
import {Title} from './../../../Title';
import { useRouteMatch } from "react-router-dom";

export const WhatIsGiven = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">4</span>What is Serbia giving to?</h2>
                <p>If we discount the fact that addressing the effects of the pandemic represented the largest share in the number of instances in 2020, the key themes for 2020 remained the same and retained the same order as in 2019 – healthcare, support to marginalized groups, poverty relief and education. They only had a smaller share of the total due to the giving in direct response to the pandemic.</p>
                <p>The total value of donations to address effects of the pandemic considerably exceeded the value of donations for any other individual theme, comprising 42.1% of the total recorded value of donations in 2020.</p>
                <p>Other themes, including sport, economic development, environmental protection, culture and art, religious communities, public infrastructure, animal welfare, human rights, emergency assistance, science, social entrepreneurship, heritage and independent media, each received a less than 1% share of the total.</p>
                <p>The largest decline in the total value of donations was recorded for social entrepreneurship, sport and support to independent media. However, following a pause in occurrences, donations for the theme of Human Rights reappeared.</p>
                <h2 className='panel-subtitle' >Themes of giving</h2>
                <PieWithTable 
                    csvUrl='https://gist.githubusercontent.com/kragovic/dd47ae9517da5a2d388fdc941160b6aa/raw/09eb9db06796dbb9569affeb88f56bc7eec08b01/Key_themes_eng.csv' 
                    lang={params.lang}
                    />
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/5c7034f38d123ade67ed6384ea1ffdf0/raw/a57291b5ce2c1784806ffec03011a195e4f528d3/AreasOfGivingExamplesENG.csv'
                />  
                <div id="recipients"></div>       
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">4</span>Za šta se daruje u Srbiji?</h2>
                <p>Ako izdvojimo činjenicu da je saniranje posledica pandemije u 2020. godini bilo zastupljeno u najvećem procentu akcija, najvažnije teme su ostale iste kao u 2019. godini, i to u istom redosledu – zdravlje, podrška marginalizovanim grupama, smanjenje siromaštva i obrazovanje, uz, naravno, manju zastupljenost svih ovih tema nego prethodne godine, nauštrb davanja za posledice pandemije.</p>
                <p>Ukupna novčana vrednost filantropskih akcija za posledice pandemije višestruko nadilazi novčanu vrednost davanja za svaku drugu pojedinačnu temu i čini 42,1% ukupne zabeležene darovane sume u 2020. godini.</p>
                <p>Ostale teme, od sporta preko ekonomskog razvoja, zaštite životne sredine, kulture i umetnosti, religijskih zajednica, javne infrastrukture, brige o životinjama, ljudskih prava, urgentne pomoći, nauke, socijalnog preduzetništva, nasleđa i nezavisnih medija, zastupljene su sa po manje od 1% akcija.</p>
                <p>Najprimetniji pad u vrednosti davanja zabeležen je kod društvenog preduzetništva, davanja za sport i podrške nezavisnim medijima. Sa druge strane, nakon nekoliko godina pauze pojavila su se davanja u oblasti ljudskih prava.</p>
                <h2 className='panel-subtitle' >Oblasti davanja</h2>
                <PieWithTable 
                    csvUrl='https://gist.githubusercontent.com/kragovic/8561ae65c561f0fd83cb24df65f93c0a/raw/8ccb20d384c59b2b8d5cb7ca2ef9dab8ae4d965c/Key_themes.csv' 
                    lang={params.lang}    
                />
                <Accordion 
                    csvUrl='https://gist.githubusercontent.com/kragovic/09660a94a60b675da11e48547e8840da/raw/48c39bc0de7038ac4d58eb2992ae22bb425c99d8/AreasOfGivingExamples.csv'
                />  
                <div id="recipients"></div>       
            </div>
        )
    }
}

export const AxisLeft = ({yScale}) =>
  yScale.domain().map((tickValue) => (
    <g className="tick" key={tickValue}>
    <text
      style={{ textAnchor: 'end' }}
      dy=".32em"
      x="-15"
      y={yScale(tickValue)}
    >
      {tickValue}
    </text>
    </g>
  ));
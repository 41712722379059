import React, {useState} from 'react'
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import menuBars from '../../../images/menuBars.svg'
import menuClose from '../../../images/menuClose.svg'
import Srbijadaruje from '../../../images/Srbijadaruje.svg'
import twitter from '../../../images/twitter.svg'
import face from '../../../images/face.svg'
import instagram from '../../../images/instagram.svg'
import linkedin from '../../../images/linkedin.svg'
import {SidebarData} from './SidebarData'
//import './Navbar.css';
import {ContentMenu} from './ContentMenu'
import { AboutUs } from './AboutUs';

export const Navbar = () => {
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    const [isActiveContent, setIsActiveContent] = useState(false);
    const [isActiveAboutUs, setIsActiveAboutUs] = useState(false);
    return (
        <>
            <div className='navbar'>
            
            <span className='menu-bars'>
                <div className="navbar-cont">
                    <div>
                        <img src={menuBars} onClick={showSidebar} />
                       
                        <img src={Srbijadaruje} className="giving-Logo"  />
                    </div>
               </div>
            </span>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'} >
                <ul className='nav-menu-items'>
                    <li className="navbar-toggle">
                        <span className='menu-bars'>
                            <img src={menuClose} onClick={showSidebar} />
                        </span>
                    </li>
                    <li className='parent-menu-link'>
                    <Link to="/">
                            <div>
                                <div>Početna</div>
                            </div>
                            
                        </Link>
                    </li>
                    <li className='parent-menu-link' onClick={() => setIsActiveContent(!isActiveContent)}>
                        <span>
                            <div>
                                <div>Sadržaj</div>
                                <div>{isActiveContent ? '-' : '+'}</div>
                            </div>
                            
                        </span>
                    </li>
                    {isActiveContent && <ContentMenu SidebarData={SidebarData} /> }
                    <li className='regular-menu-link' id="previous-reports-link">
                        <a href="https://catalystbalkans.org/sr/naslovna/resursi" target="_blakn">Prethodna izdanja izveštaja</a>
                    </li>
                    <li className='regular-menu-link' id="newsletter-link">
                        <a href="https://civicatalyst.org/givingbalkans_newsletter" target="_blakn">Prijava na newsletter</a>
                    </li>
                    <li className='regular-menu-link' onClick={() => setIsActiveAboutUs(!isActiveAboutUs)}>
                        <span>
                            <div>
                                <div>O nama</div>
                                <div>{isActiveAboutUs ? '-' : '+'}</div>
                            </div>
                            
                        </span>
                    </li>
                    {isActiveAboutUs && <AboutUs />}
                    <li className='regular-menu-link' id="pdf-link">
                        <a href="https://givingbalkans.org/sites/default/files/SerbiaFullPhilReport.pdf" target="_blank">Pdf verzija za štampu</a>
                    </li>
                    <div className="logo-container">
                        <a href="https://www.facebook.com/CatalystBalkans/" target="_blank"><img src={face} /></a>
                        <a href="https://twitter.com/catalystbalkans?lang=en" target="_blank"><img src={twitter} /></a>
                        <a href="https://rs.linkedin.com/company/catalystbalkans" target="_blank"><img src={linkedin} /></a>
                        <a href="https://www.instagram.com/catalystbalkans/?hl=en" target="_blank"><img src={instagram} /></a>
                    </div>
                </ul>
                
            </nav>
        </>
    )
}

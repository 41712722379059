import React from 'react'
import up from '../../../../images/up.svg'
import down from '../../../../images/down.svg'
import similar from '../../../../images/similar.svg'
import same from '../../../../images/same.svg'
import {TrendTable} from '../../../charts/TrendTable'
import { useRouteMatch } from "react-router-dom";



export const Enclosure9 = () => {
    const { params } = useRouteMatch();
    if (params.lang == 'en') {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">9</span>Annexes</h2>
                <h3 className="panel-subtitle"><span className="subtitle-number">9.1</span>Trends in giving for other purposes (excluding data on giving related to the COVID-19 pandemic)</h3>
                <div>
                    <table className="trend-table-signs">
                        <thead>
                            <tr>
                                <th>Symbol</th>
                                <th>Meaning</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src={up} /></td>
                                <td>increase compared to the previous year</td>
                            </tr>
                            <tr>
                                <td><img src={down} /></td>
                                <td>decline compared to the previous year</td>
                            </tr>
                            <tr>
                                <td><img src={same} /></td>
                                <td>no changes compared to the previous year</td>
                            </tr>
                            <tr>
                                <td><img src={similar} /></td>
                                <td>change compared to the previous year is 1% or less and therefore statistically insignificant</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table className="yearly-raised-amt">
                        <thead>
                            <tr>
                                <th></th>
                                <th>2016.</th>
                                <th>2017.</th>
                                <th>2018.</th>
                                <th>2019.</th>
                                <th>2020.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Recorded <abbr title="In 2020 it was impossible to present the estimated value of donations unrelated to the COVID-19 pandemic. The high level of giving motivated by the pandemic made it impossible to extrapolate from data unrelated to the pandemic.">value of donations</abbr></td>
                                <td>9.683.092,0</td>
                                <td>15.188.215,0</td>
                                <td>15.850.146,4</td>
                                <td>18.811.016,0</td>
                                <td>29.523.536,0</td>
                            </tr>
                            <tr>
                                <td>Number of instances</td>
                                <td>3.270</td>
                                <td>3.042</td>
                                <td>2.773</td>
                                <td>3.037</td>
                                <td>2.865</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                <div className="trends-container">
                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.1</span>Geographic distribution</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/42f792a3a35ead6aba58070039708b11/raw/a677a8cbabd5881489abcdeb36f939269229c803/geogDistTrendsENG.csv' 
                        category='Region/year' 
                    />
    
                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.2</span>Share of key donor types</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/a4453708b8c21c2f4d7776e479e56617/raw/83725b5eb5822b8e868cc963eb6467f12bcd6c71/keyDonorsTrendENG.js' 
                        category='Number of instances in %' 
                    />
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/f3044d859fa5bb5e5bf3f6bf1f3fe325/raw/d354f64e9d5d8ead887f49e03de710f313ece635/keyDonorsAmtTrendsENG.csv'
                        category='Recorded value of donations (%)'
                    />
    
    
                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.3</span>Key supported themes</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/69a43e065c9959ae4ce3093a9ef613cf/raw/ac4cd585c7c5040916a7c8402fe827ff2e0d0790/keyThemesTrendsENG.csv' 
                        category='Number of instances in %' 
                    />
                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.4</span>Key donation recipients</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/a935f1b80b6175d7d49da37814a6e46a/raw/f708484a19743c5e0582e91d12445b01379dee99/keyRecepientsActTrendsENG.csv' 
                        category='Number of instances in %' 
                    />
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/691cc7df97cc0f9d4c01a92df5999bc7/raw/4ce7854cddb5dc46abff21c81e9c88d06a7f68fb/keyRecepientsAmtTrendsENG.csv' 
                        category='Recorded value of donations (%)' 
                    />
    
                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.5</span>Key beneficiaries</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/52bb616db20f7b8d41a75d6c4be294da/raw/4c7779fa816366560af2a1d3832312a92ad2e760/keyUsersTrendsENG.csv' 
                        category='Number of instances in %'
                    />
    
                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.6</span>Types of donations</h3>
                    <h4 className="panel-sub-subtitle"><span className="subtitle-number">9.1.6.1</span>Effects of giving</h4>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/284fb6b37ba8a2acfe63ceee372cf58f/raw/27ce6855bfe3d8e891094ebecb48a943ec8dd915/effectsTrendsENG.csv' 
                        category='Number of instances in %'
                    />
    
                    <h4 className="panel-sub-subtitle"><span className="subtitle-number">9.1.6.2</span>What is donated?</h4>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/bc3202342371501649d6e4014a08bd2f/raw/4538d3139e3f63047a079a2e8653cb7ed65522be/whatIsGivenTrendsENG.csv' 
                        category='Number of instances in %'
                    />
    
                    <h4 className="panel-sub-subtitle"><span className="subtitle-number">9.1.6.3</span>Ways of giving</h4>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/c69c4d9855d2198227d76b7e152b5bab/raw/60f1fe30203436579c8c72665ad88326dbfbb6fb/wayOfGivingTrendsENG.csv' 
                        category='Number of instances in %'
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div className="panel-regular">
                <h2 className="panel-title"><span className="title-number">9</span>Prilozi</h2>
                <h3 className="panel-subtitle"><span className="subtitle-number">9.1</span>Trendovi dobročinstva za ostale svrhe (ne uključuje podatke o davanju u slučaju vanrednih okolnosti pandemije Kovida-19)</h3>
                <div>
                    <table className="trend-table-signs">
                        <thead>
                            <tr>
                                <th>Simbol</th>
                                <th>Značenje</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src={up} /></td>
                                <td>povećanje u odnosu na prethodnu godinu</td>
                            </tr>
                            <tr>
                                <td><img src={down} /></td>
                                <td>smanjenje u odnosu na prethodnu godinu</td>
                            </tr>
                            <tr>
                                <td><img src={same} /></td>
                                <td>nema promene u odnosu na prethodnu godinu</td>
                            </tr>
                            <tr>
                                <td><img src={similar} /></td>
                                <td>promena u odnosu na prethodnu godinu je 1% ili manja, te u tom smislu statistički zanemarljiva</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table className="yearly-raised-amt">
                        <thead>
                            <tr>
                                <th></th>
                                <th>2016.</th>
                                <th>2017.</th>
                                <th>2018.</th>
                                <th>2019.</th>
                                <th>2020.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Zabeležena darovana <abbr title="U 2020. godini nije bilo moguće predstaviti procenjenu sumu davanja koja nisu povezana sa pandemijom Kovida-19. Visok nivo davanja motivisanih pandemijom onemogućio je da se proces ekstrapolacije primeni na isključivo određeni deo podataka koji se ne odnosi na davanja tokom pandemije.">suma</abbr></td>
                                <td>9.683.092,0</td>
                                <td>15.188.215,0</td>
                                <td>15.850.146,4</td>
                                <td>18.811.016,0</td>
                                <td>29.523.536,0</td>
                            </tr>
                            <tr>
                                <td>Broj akcija</td>
                                <td>3.270</td>
                                <td>3.042</td>
                                <td>2.773</td>
                                <td>3.037</td>
                                <td>2.865</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                <div className="trends-container">
                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.1</span>Geografska raspodela</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/da508a4648ce530e783647e640a0bfbe/raw/e315a346faad3eb20b3a487f30e57c21e529fe42/geogDistTrends.csv' 
                        category='Region/godina' 
                    />

                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.2</span>Učešće ključnih tipova darodavaca</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/8f519f72171f0913789e6efa4d9afdcd/raw/2452ea19c776c219aac4acd25097d55739958bb8/keyDonorsTrend.js' 
                        category='Broj akcija u %' 
                    />
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/00b7f1959cb2f90a9ea12e1515622463/raw/de9468014c6c5a22a5e12cf6359b8ac84e5442ab/keyDonorsAmtTrends.csv'
                        category='Poznata darovana suma (u %)'
                    />


                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.3</span>Podržane ključne teme</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/f583c76ee8127a3a64de997712f91309/raw/7dd716f3f41fe29ac90e76b84a238299e2d8505c/keyThemesTrends.csv' 
                        category='Broj akcija u %' 
                    />
                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.4</span>Ključni primaoci donacija – kome se daruje</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/8e428622fd989ab1ab2ffdd7ab4458fa/raw/954597dfd6b98b5b5a5b7d8f4198085f2a2c357c/keyRecepientsActTrends.csv' 
                        category='Broj akcija u %' 
                    />
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/bd3b3b7947b0db80e34ab770596893e3/raw/67a945df17f0bd0f97b78555b1c85dbacd14e74b/keyRecepientsAmtTrends.csv' 
                        category='Poznata darovana suma (u %)' 
                    />

                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.5</span>Ključni korisnici donacija</h3>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/d106270cdecd1b288221ae17e5459713/raw/7ccbf01bc566a27844db20c2f9221f94f17102a9/keyUsersTrends.csv' 
                        category='Broj akcija u %'
                    />

                    <h3 className="panel-subtitle"><span className="subtitle-number">9.1.6</span>Kako se daruje?</h3>
                    <h4 className="panel-sub-subtitle"><span className="subtitle-number">9.1.6.1</span>Efekti davanja</h4>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/92e2807534c7cebb352250ccfc49c060/raw/3738811ad26736062ada3bad767469c4e907f721/effectsTrends.csv' 
                        category='Broj akcija u %'
                    />

                    <h4 className="panel-sub-subtitle"><span className="subtitle-number">9.1.6.2</span>Šta se daruje?</h4>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/3f942435d2334b8d5828a1d0e909332f/raw/e5c58c80a7bb45474a21b6096526555a8bd8ed1b/whatIsGivenTrends.csv' 
                        category='Broj akcija u %'
                    />

                    <h4 className="panel-sub-subtitle"><span className="subtitle-number">9.1.6.3</span>Način davanja</h4>
                    <TrendTable 
                        csvUrl='https://gist.githubusercontent.com/kragovic/8ec2fe40a5c71a29ca3e764fac7552b5/raw/5981370551c12d155e44650a8df3f58e94706a84/wayOfGivingTrends.csv' 
                        category='Broj akcija u %'
                    />
                </div>
            </div>
        )
    }
}
